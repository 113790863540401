import React from "react";
import PropTypes from "prop-types";
import Patients from "../../components/Nodes/Patients";
import Operations from "../../components/Nodes/Operations";
import Diagnoses from "../../components/Nodes/Diagnoses";
import Scores from "../../components/Nodes/ScoresExplorer";
import Complications from "../../components/Nodes/Complications";
import Visits from "../../components/Nodes/Visits";
import GroupInfo from "../../components/Groups/GroupInfo";

class GroupsSingleView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // hardcodded variable value
    let createdAt = "01-12-2018";
    return (
      <div className="groups-single-view">
        <div className="groups-single-view-header">
          <GroupInfo
            episodeTitle={this.props.results[0].title}
            episodeCreatedAt={createdAt}
            episodeCount={this.props.results[0].episodeCount}
            operationCount={
              this.props.results[0].episodes.filter(
                e => e.operations && e.operations.count > 0
              ).length
            }
            complicationCount={
              this.props.results[0].episodes.filter(
                e =>
                  e.complications &&
                  e.complications.complicationItems.length > 0
              ).length
            }
            groupId={this.props.results[0]._id}
          />
          <Patients results={this.props.results[0].episodes} />
        </div>
        <div className="groups-single-view-content">
          <Scores
            results={this.props.results[0].scoreTypes}
            groupId={this.props.results[0]._id}
          />
          <Operations
            results={this.props.results[0].episodes}
            addToTempGroup={this.props.addToTempGroup}
          />
          <Diagnoses
            results={this.props.results[0].episodes}
            addToTempGroup={this.props.addToTempGroup}
          />
          <div className="splits">
            <Complications
              results={this.props.results[0].episodes}
              addToTempGroup={this.props.addToTempGroup}
            />
            <Visits results={this.props.results[0].episodes} />            
          </div>
        </div>
      </div>
    );
  }
}

GroupsSingleView.propTypes = {
  results: PropTypes.array.isRequired,
  addToTempGroup: PropTypes.func,
  tempGroup: PropTypes.array,
  createGroup: PropTypes.func
};

export default GroupsSingleView;
