// Random number generator used for keys on react elements
function generateId() {
  return Math.floor(Math.random() * 100000000000 + 1);
}

// Util Function to decamelize strings
function deCamelise(str) {
  return str
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase();
}

module.exports = {
  generateId,
  deCamelise
};
