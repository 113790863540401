import React, { Component } from "react";
import PropTypes from "prop-types";
import { COMPARE_GROUPS_QUERY } from "../../gql/GroupGQL";
import { client } from "../../store/configureClient";
import Patients from "../../components/Nodes/Patients";
import Scores from "../../components/Nodes/ScoresExplorer";
import Loading from "../../components/Loading/Loading";

class GroupsCompareView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compareGroups: [],
      loadingOverlayActive: false
    };
  }

  componentDidMount() {
    this.getCompareGroups();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.compareGroupsIds !== this.props.compareGroupsIds) {
      this.getCompareGroups();
    }
  }

  // Toggle loading overlay screen
  toggleLoadingOverlay = bool => {
    this.setState({ loadingOverlayActive: bool });
  };

  getCompareGroups = () => {
    // toggle overlay loading component
    this.toggleLoadingOverlay(true);

    let groupIds = [
      ...this.props.compareGroupsIds.map(x => ({
        _id: x
      }))
    ];
    client
      .query(
        {
          query: COMPARE_GROUPS_QUERY,
          variables: {
            compareGroupsIds: groupIds
          }
        },
        { options: { fetchPolicy: "cache-and-network" } }
      )
      .then(({ data, networkStatus }) => {
        if (networkStatus === 7) {
          this.setState({ compareGroups: data.compareGroups });
        }
        this.toggleLoadingOverlay(false);
      })
      .catch(error => {
        console.log(error);
        this.toggleLoadingOverlay(false);
      });
  };

  renderPatients = () => {
    let patients = [];
    this.state.compareGroups.map((x, i) => {
      patients.push(
        <div key={x + i} className="patients-node-holder">
          <h3 className="group-section-title">
            {this.state.compareGroups[i].title}
          </h3>
          <Patients results={this.state.compareGroups[i].episodes} />
        </div>
      );
    });
    return patients;
  };

  renderScores = () => {
    let scores = [];
    this.state.compareGroups.map((x, i) => {
      scores.push(
        <div key={x + i} className="scores-node-holder">
          <h3 className="group-section-title">
            {this.state.compareGroups[i].title}
          </h3>
          <Scores
            results={this.state.compareGroups[i].scoreTypes}
            groupId={this.state.compareGroups[i]._id}
          />
        </div>
      );
    });
    return scores;
  };

  render() {
    return (
      <div className="groups-compare-view">
        <Loading loadingOverlayActive={this.state.loadingOverlayActive} />
        <div className="groups-compare-view-content">
          <div className="nodes-wrapper scores">
            {this.state.compareGroups.length > 0 && this.renderScores()}
          </div>
          <div className="nodes-wrapper patients">
            {this.state.compareGroups.length > 0 && this.renderPatients()}
          </div>
        </div>
      </div>
    );
  }
}

GroupsCompareView.propTypes = {
  compareGroupsIds: PropTypes.array
};

export default GroupsCompareView;
