import React, { useState } from "react";
import PropTypes from "prop-types";
import SvgIcons from "../../components/Misc/SvgIcons";
import Login from "../Login/Login";

function Welcome(props) {
  // How to create state in hooks create a new const for each piece of state
  const [instructions, setInstructions] = useState("");

  // How to set state
  function showInstructions(os) {
    setInstructions(os);
  }

  return (
    <div className="welcome">
      <div className="welcome-inner">
        <div className="login-section">
          <Login
            checkAuthStatusAndGetCurrentUser={
              props.checkAuthStatusAndGetCurrentUser
            }
            renderMessages={props.renderMessages}
          />
        </div>
        <div className="about-section">
          <div className="logo-holder">
            <h1 className="login-title">SUMOCO</h1>
          </div>
          <div className="about-text-holder">
            <p>The <strong>Simple Unified Model for Orthopaedics</strong> is a framework for organising clinical data and <strong>connecting outcomes.</strong>.
            For account information or enquiries about the SUMO API please visit our <a href="https://fortius-digital.github.io/sumo/" className="link-text"> GitHub </a> page. SUMO is a Fortius Digital project, part of Fortius Group Ltd.
            </p>
            <br/>
            <p>
            NB: Due to security & compatibility issues we do not support Internet Explorer. If you do not have access to another browser please download the Windows application.
            </p>
          </div>
        </div>
        <div className="downloads-section">
          <h3 className="downloads-title"> Downloads</h3>
          <div className="download-links-holder">
            <div className="link-details-holder">
              {/* TODO fix links */}
              <a href="https://github.com/fortius-digital/sumo/releases/download/v0.1.0/SUMOCO.Setup.0.1.0.exe" className="download-link windows-link">
                <SvgIcons icon={"windows"} />
                <span className="link-text">Windows 10</span>
              </a>
              <span
                className="instructions-link"
                onClick={() => showInstructions("win")}
              >
                <SvgIcons icon={"info"} /> Installation instructions
              </span>
            </div>
            <div className="link-details-holder">
              <a href="https://github.com/fortius-digital/sumo/releases/download/v0.1.0/SUMOCO-0.1.0.dmg" className="download-link osx-link">
                <SvgIcons icon={"apple"} />
                <span className="link-text">OSX 10 +</span>
              </a>
              <span
                className="instructions-link"
                onClick={() => showInstructions("osx")}
              >
                <SvgIcons icon={"info"} /> Installation instructions
              </span>
            </div>
            <div className="link-details-holder">
              <a href="https://github.com/fortius-digital/sumo/releases/download/v0.1.0/SUMOCO.0.1.0.AppImage" className="download-link linux-link">
                <SvgIcons icon={"linux"} />
                <span className="link-text">linux appImage</span>
              </a>
              <span
                className="instructions-link"
                onClick={() => showInstructions("linux")}
              >
                <SvgIcons icon={"info"} /> Installation instructions
              </span>
            </div>
            <div className="installation-instructions-holder">
              {instructions === "win" && (
                <p className="installation-instructions-text">
                  Windows Installation instructions
                </p>
              )}
              {instructions === "osx" && (
                <p className="installation-instructions-text">
                  OSX Installation instructions
                </p>
              )}
              {instructions === "linux" && (
                <p className="installation-instructions-text">
                  Linux Installation instructions
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Welcome.propTypes = {
  checkAuthStatusAndGetCurrentUser: PropTypes.func.isRequired,
  renderMessages: PropTypes.func.isRequired
};

export default Welcome;
