import gql from "graphql-tag";

//Gets a list of all groups using Logged in Consultant as entry point
export const ALL_GROUPS_QUERY = gql`
  query Consultant($scoresName: String) {
    Consultant(scoresName: $scoresName) {
      _id
      groups {
        _id
        title
        episodeCount
        patientCount
        operationCount
        scoreTypes {
          key
        }
      }
    }
  }
`;

// Single group query pulls back everything
export const SINGLE_GROUP_QUERY = gql`
  query Group($_id: String) {
    Group(_id: $_id) {
      title
      episodeCount
      pathwayTypes
      scoreTypes {
        key
      }
      _id
      episodes {
        _id
        externalId
        startDate
        patient {
          key
          gender
          dob
        }
        operations {
          keyWords
          count
          operationItems {
            side
            title
            hospital
            additionalProcedures
            date
            repeat
            primaryOrRevision
          }
        }
        diagnoses {
          sides
          diagnosisItems {
            _id
            isMain
            side
            status
            condition
            diagnosis
            ordinal
            date
          }
        }
        plans {
          sides
          planItems {
            date
            plan
            consultantIntervention
          }
        }
        complications {
          sides
          complicationItems {
            title
            side
            date
          }
        }
        visits {
          followUps
          postOp
          visitItems {
            date
            type
          }
        }
      }
    }
  }
`;

// Get a lighter version of data from Single Group
export const SINGLE_GROUP_EPISODES_QUERY = gql`
  query Group($_id: String) {
    Group(_id: $_id) {
      title
      episodeCount
      pathwayTypes
      scoreTypes {
        key
      }
      _id
      episodes {
        _id
        externalId
        owner
        type
        startDate
        patient {
          gender
          key
          dob
        }
        diagnoses {
          primaryBilateral
          primaryLeft
          primaryRight
          secondaryBilateral
          secondaryLeft
          secondaryRight
          sides
          diagnosisItems {
            _id
            isMain
            side
            status
            condition
            diagnosis
            ordinal
            date
          }
        }
        plans {
          sides
          planItems {
            date
            plan
            consultantIntervention
          }
        }
        complications {
          sides
          complicationItems {
            title
            side
            date
          }
        }
        visits {
          followUps
          postOp
          visitItems {
            date
            type
          }
        }
        operations {
          keyWords
          count
          operationItems {
            side
            title
            hospital
            additionalProcedures
            date
            repeat
            primaryOrRevision
          }
        }
        scores {
          scoreSet {
            scoreType
          }
        }
      }
    }
  }
`;

//Gets a scores object, given a scoreType and group ID
export const GROUP_SCORES = gql`
  query scoreGroups($scoreType: String, $id: String) {
    scoreGroups(scoreType: $scoreType, id: $id) {
      type
      dimension
      scoreCount {
        low
      }
      period
      mean
      median
      stdev
    }
  }
`;

// TODO
// Get total episodes available to consultant,
// Could probably extend currentUser obj for this kind of stuff
export const TOTAL_EPISODE_COUNT = gql`
  query totalEpisodesCount($scoresName: String) {
    totalEpisodesCount(scoresName: $scoresName) {
      scoresName
      totalEpisodes
    }
  }
`;
export const COMPARE_GROUPS_QUERY = gql`
  query CompareGroups($compareGroupsIds: [CompareGroupsIds]) {
    compareGroups(compareGroupsIds: $compareGroupsIds) {
      title
      episodeCount
      pathwayTypes
      scoreTypes {
        key
      }
      _id
      episodes {
        _id
        externalId
        startDate
        patient {
          key
          gender
          dob
        }
        operations {
          keyWords
          count
          operationItems {
            side
            title
            hospital
            additionalProcedures
            date
            repeat
            primaryOrRevision
          }
        }
        diagnoses {
          sides
          diagnosisItems {
            _id
            isMain
            side
            status
            condition
            diagnosis
            ordinal
            date
          }
        }
        plans {
          sides
          planItems {
            date
            plan
            consultantIntervention
          }
        }
        complications {
          sides
          complicationItems {
            title
            side
            date
          }
        }
        visits {
          followUps
          postOp
          visitItems {
            date
            type
          }
        }
      }
    }
  }
`;

// Regenerate Dynamic Groups
export const UPDATE_YEAR_GROUPS = gql`
  query generateYearCluster($owner: String) {
    generateYearClusters(owner: $owner)
  }
`;

export const UPDATE_DIAGNOSIS_GROUPS = gql`
  query generateDiagnosisClusters($owner: String) {
    generateDiagnosisClusters(owner: $owner)
  }
`;

export const UPDATE_OPERATION_GROUPS = gql`
  query generateOperationsClusters($owner: String) {
    generateOperationsClusters(owner: $owner)
  }
`;
