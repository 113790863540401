import React, { Component } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { AppContext } from "../../contexts/Contexts";

class ThemeToggle extends Component {
  state = {
    dark: false,
    label: "Dark",
    toggleFunc: null
  };

  componentDidMount() {
    let value = this.context;
    this.setState({ toggleFunc: value.toggleTheme });
  }

  handleChange = name => event => {
    let label = this.state.label === "Dark" ? "Light" : "Dark";
    this.setState(
      { [name]: event.target.checked, label: label },
      this.state.toggleFunc
    );
  };

  render() {
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.dark}
              onChange={this.handleChange("dark")}
              value="dark"
            />
          }
          label={this.state.label}
          style={{ margin: 0 }}
        />
      </FormGroup>
    );
  }
}

ThemeToggle.propTypes = {};

ThemeToggle.contextType = AppContext;
export default ThemeToggle;
