import React from "react";
import PropTypes from "prop-types";
import SvgIcons from "../../Misc/SvgIcons";

class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "password",
      labelActive: false
    };
  }

  // Function to toggle input type to allow password to be viewed
  togglePasswordView = () => {
    if (this.state.type === "password") {
      this.setState({ type: "text" });
    } else {
      this.setState({ type: "password" });
    }
  };

  // Checkes if Input has focus and sets/removes labelActive state
  toggleLabelActive = () => {
    if (this.state.labelActive === true) {
      this.setState({ labelActive: false });
    } else {
      this.setState({ labelActive: true });
    }
  };

  // RenderFunction to allow different input Style for password inputs
  renderInputField = () => {
    if (this.props.type === "password") {
      return (
        <React.Fragment>
          <input
            type={this.state.type}
            placeholder={this.props.placeholder}
            name={this.props.name}
            onChange={this.props.onChangeFunc}
            className="text-input"
            value={this.props.value || ""}
            onFocus={this.toggleLabelActive}
            onBlur={this.toggleLabelActive}
            disabled={this.props.disabled}
          />
          {/* prettier-ignore */}
          <span
            className="password-view-btn"
            onClick={this.togglePasswordView}
          ></span>
        </React.Fragment>
      );
    } else {
      return (
        <input
          type={this.props.type}
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={this.props.onChangeFunc}
          className="text-input"
          value={this.props.value || ""}
          onFocus={this.toggleLabelActive}
          onBlur={this.toggleLabelActive}
          disabled={this.props.disabled}
        />
      );
    }
  };

  render() {
    return (
      <div
        className={
          this.props.isValid !== null
            ? this.props.isValid
              ? `input-holder ${this.props.name}-holder valid`
              : `input-holder ${this.props.name}-holder invalid`
            : `input-holder ${this.props.name}-holder`
        }
      >
        {(this.props.iconName !== "" || this.props.iconName !== undefined) && (
          <SvgIcons icon={this.props.iconName} />
        )}

        <label
          htmlFor={`${this.props.name}`}
          className={this.state.labelActive ? "label-active" : ""}
        >
          {this.props.label}
        </label>
        {this.renderInputField()}
      </div>
    );
  }
}

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeFunc: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  disabled: PropTypes.bool
};

export default InputField;
