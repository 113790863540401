import React, { Component } from "react";
import PropTypes from "prop-types";
import AnimatedNumber from "react-animated-number";
import PieChart from "../Charts/PieChart";

class DoughnutKpi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      compareValue: 0,
      label: this.props.label,
      colour: this.props.colour
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.update();
    }
  }

  update = () => {
    setTimeout(() => {
      this.setState({
        value: this.props.value        
      });
    }, 1500);
    this.setState({
      // compareValue: this.props.compareValue - this.props.value,
      // label: this.props.label,
      // colour: this.props.colour
    })
  };

  render() {
    return (
      <div className="doughnut-kpi">
        <div className="big-value">
          <AnimatedNumber
            stepPrecision={0}
            value={this.state.value}
            formatValue={n => ` ${n} `}
          />
        </div>
        <div className="doughnut-chart">
          <PieChart
            dataSet={
              [{
                data:[this.state.value, this.state.compareValue],
                backgroundColor:[this.state.colour, "rgba(66, 67, 74, 1"],
                borderWidth: 0
              }]
            }
            labels={[""]}
            options={{
              title: {
                display: false
              },
              cutoutPercentage: 80,
              legend: {
                display: false
              }
            }}
            type={"doughnut"}
            refName={"gendersDoughnut"}
            defaultStyles={false}
          />
        </div>
        <div className="floor-label"><h3>{this.state.label}</h3></div>
      </div>
    );
  }
}

DoughnutKpi.propTypes = {
  value: PropTypes.number,
  compareValue: PropTypes.number,
  label: PropTypes.string,
  colour: PropTypes.string,
};

export default DoughnutKpi;
