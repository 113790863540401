var ExcelJS = require("exceljs/dist/es5/exceljs.browser");
var saveAs = require("file-saver");

function excel(json) {
  var options = {
    creator: "sumoco",
    useStyles: true,
    useSharedStrings: true
  };
  var workbook = new ExcelJS.Workbook(options);
  // SET UP WORKSHEETS
  var index = workbook.addWorksheet("Group", {
    properties: { showGridLines: false }
  });
  index.columns = [
    { header: "Pathway Id", key: "id", width: 10 },
    { header: "Start Date", key: "start", width: 10 },
    { header: "Pathway Type", key: "type", width: 32 },
    { header: "Patient", key: "patientKey", width: 8 },
    { header: "Gender", key: "patientGender", width: 4 },
    { header: "DOB", key: "patientDob", width: 8 },
    { header: "Primary Right Diagnosis", key: "primaryRight", width: 22 },
    { header: "Primary Left Diagnosis", key: "primaryLeft", width: 22 },
    {
      header: "Primary Bilateral Diagnosis",
      key: "primaryBilateral",
      width: 22
    },
    { header: "Operations", key: "operations", width: 22 },
    { header: "Follow Ups", key: "followUps", width: 15 },
    { header: "Post Ops", key: "postOps", width: 15 },
    { header: "Plans", key: "plans", width: 22 }
  ];
  index.views = [{ state: "frozen", xSplit: 1, ySplit: 1 }];
  var operations = workbook.addWorksheet("Operations");
  operations.columns = [
    { header: "Pathway Id", key: "key", width: 10 },
    { header: "Side", key: "side", width: 10 },
    { header: "Title", key: "title", width: 32 },
    { header: "Additional Procedures", key: "additionalProcedures", width: 32 },
    { header: "Hospital", key: "hospital", width: 22 },
    { header: "Repeat", key: "repeat", width: 22 },
    { header: "Primary or Revision", key: "revision", width: 22 },
    { header: "Date", key: "date", width: 22 }
  ];
  var diagnoses = workbook.addWorksheet("Diagnoses");
  diagnoses.columns = [
    { header: "Pathway Id", key: "key", width: 10 },
    { header: "Side", key: "side", width: 18 },
    { header: "Condition", key: "condition", width: 32 },
    { header: "Diagnosis", key: "diagnosis", width: 32 },
    { header: "Location", key: "location", width: 32 },
    { header: "Degree", key: "degree", width: 32 },
    { header: "Ordinal", key: "ordinal", width: 18 },
    { header: "Status", key: "status", width: 18 },
    { header: "isMain", key: "isMain", width: 10 },
    { header: "Date", key: "date", width: 22 }
  ];
  var plans = workbook.addWorksheet("Plans");
  plans.columns = [
    { header: "Pathway Id", key: "key", width: 10 },
    { header: "Side", key: "side", width: 18 },
    { header: "Plan", key: "plan", width: 32 },
    { header: "Consultant Intervention", key: "intervention", width: 32 },
    { header: "Date", key: "date", width: 22 }
  ];
  var complications = workbook.addWorksheet("Complications");
  complications.columns = [
    { header: "Pathway Id", key: "key", width: 10 },
    { header: "Date", key: "date", width: 10 },
    { header: "Side", key: "side", width: 18 },
    { header: "Complication", key: "title", width: 32 },
    { header: "Description", key: "description", width: 32 }
  ];

  // SET UP DYNAMIC SCORE WORKSHEETS
  let data = json.Group[0];
  for (let s of data.scoreTypes) {
    var sheet = workbook.addWorksheet(s.key);
    sheet.columns = [
      ...[
        { header: "Key", key: "key", width: 10 },
        { header: "Completed Date", key: "completedDate", width: 10 },
        { header: "Period", key: "period", width: 10 },
        { header: "Side", key: "side", width: 10 }
      ],
      ...s.value.map(e => ({
        header: e.toString(),
        key: e.toString(),
        width: 20
      }))
    ];
  }

  let episodes = data.episodes;
  for (let row of episodes) {
    let key = row.externalId;
    let finalPlans = [];
    if (row.plans) {
      let rp = row.plans.rightPlans ? row.plans.rightPlans.split(",") : [];
      let lp = row.plans.leftPlans ? row.plans.leftPlans.split(",") : [];
      let bp = row.plans.bilateralPlans
        ? row.plans.bilateralPlans.split(",")
        : [];
      finalPlans = Array.from(new Set([...rp, ...lp, ...bp]));
    } else {
      finalPlans = [];
    }
    index
      .addRow({
        id: key,
        start: row.startDate.split("T")[0],
        type: row.type,
        patientKey: row.patient.key,
        patientGender: row.patient.gender,
        patientDob: row.patient.dob.split("T")[0],
        primaryRight: row.diagnoses ? row.diagnoses.primaryRight : "",
        primaryLeft: row.diagnoses ? row.diagnoses.primaryLeft : "",
        primaryBilateral: row.diagnoses ? row.diagnoses.primaryBilateral : "",
        operations: row.operations ? row.operations.keyWords : "",
        followUps: row.visits ? row.visits.followUps : 0,
        postOps: row.visits ? row.visits.postOp : 0,
        plans: finalPlans.join(",")
      })
      .commit();

    if (row.operations && row.operations.operationItems.length > 0) {
      for (let op of row.operations.operationItems) {
        operations
          .addRow({
            key: key,
            date: op.date ? op.date.split("T")[0] : "-",
            side: op.side,
            title: op.title,
            additionalProcedures: op.additionalProcedures,
            repeat: op.repeat,
            revision: op.primaryOrRevision,
            hospital: op.hospital
          })
          .commit();
      }
    }
    if (row.diagnoses && row.diagnoses.diagnosisItems.length > 0) {
      for (let dia of row.diagnoses.diagnosisItems) {
        diagnoses
          .addRow({
            key: key,
            side: dia.side,
            condition: dia.condition,
            diagnosis: dia.diagnosis,
            location: dia.location,
            degree: dia.degree,
            ordinal: dia.ordinal,
            status: dia.status,
            isMain: dia.isMain,
            date: dia.date ? dia.date.split("T")[0] : "-"
          })
          .commit();
      }
    }
    if (row.plans) {
      if (row.plans.planItems.length > 0) {
        for (let pl of row.plans.planItems) {
          plans
            .addRow({
              key: key,
              side: pl.side,
              plan: pl.plan,
              intervention: pl.consultantIntervention,
              date: pl.date ? pl.date.split("T")[0] : "-"
            })
            .commit();
        }
      }
    }
    if (row.complications) {
      if (row.complications.complicationItems.length > 0) {
        for (let cmp of row.complications.complicationItems) {
          complications
            .addRow({
              key: key,
              side: cmp.side,
              title: cmp.title,
              description: cmp.description,
              date: cmp.date ? cmp.date.split("T")[0] : "-"
            })
            .commit();
        }
      }
    }
    if (row.scores && row.scores.scoreSet.length > 0) {
      for (let ss of row.scores.scoreSet) {
        var ssheet = workbook.getWorksheet(ss.scoreType);
        if (ss.scores.length > 0) {
          for (let scr in ss.scores) {
            let score = ss.scores[scr];
            let sObj = {
              key: key,
              side: score.side,
              period: score.period,
              completedDate: score.completedDate ? score.completedDate.split("T")[0] : "-"
            };
            let dimension = data.scoreTypes.find(e => e.key === ss.scoreType);
            if (dimension) {
              for (let dim of dimension.value) {
                let scoreValue = score.dimensions.find(
                  e => e.dimension === dim
                );
                sObj[dim] = scoreValue ? scoreValue.value : "-";
              }
            }
            if (sObj) {
              ssheet.addRow(Object.assign({}, sObj)).commit();
            }
          }
        }
      }
    }
  }
  return workbook.xlsx.writeBuffer().then(function(buffer) {
    saveAs(
      new Blob([buffer], {
        type: "application/octet-stream"
      }),
      `${data.title}.xlsx`
    );
  });
}

export { excel };
