import React from "react";
import PropTypes from "prop-types";

const Loading = props => {
  return (
    <div className={props.loadingOverlayActive ? "loading open" : "loading"}>
      <div className="loading-inner">
        <div className="loading-anim-wrapper">
          <div className="loading-bar" />
          <div className="loading-bar" />
          <div className="loading-bar" />
        </div>
        <div className="loading-text-holder">
          <div className="loading-text-holder-inner">
            <span className="loading-letter">l</span>
            <span className="loading-letter">o</span>
            <span className="loading-letter">a</span>
            <span className="loading-letter">d</span>
            <span className="loading-letter">i</span>
            <span className="loading-letter">n</span>
            <span className="loading-letter">g</span>
          </div>
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  loadingOverlayActive: PropTypes.bool.isRequired
};

export default Loading;
