import React, { Component } from "react";
import PropTypes from "prop-types";
import InputField from "../../components/Forms/components/InputField";
import SelectionSwitcher from "../../components/SelectionSwitcher/SelectionSwitcher";

class GroupsListMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredGroupList: [],
      filterText: ""
    };
  }

  componentDidMount() {
    this.setState({ filteredGroupList: this.props.results[0].groups });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.results !== this.props.results) {      
      this.setState({ filteredGroupList: this.props.results[0].groups });
    }
  }

  renderGroupsListMenu = () => {
    let results = [];

    this.state.filteredGroupList.map((x, i) => {
      results.push(
        <li className="groups-list-item" key={i}>
          <div className="item-title">
            <span
              className="group-title"
              onClick={() => {
                this.props.selectGroup(x._id, x.title);
              }}
            >
              {x.title}
            </span>
            <div className="bar-actions">
              <span className="add-to-compare">
                <SelectionSwitcher
                  selectIcon={"sendTo"}
                  deselectIcon={"cross"}
                  selection={() => {
                    this.props.selectCompareGroup(x.title, x._id);
                  }}
                  deselection={() => {
                    this.props.removeCompareGroup(x.title, x._id);
                  }}
                />
              </span>
            </div>
          </div>
          <div className="counts-holder">
            <ul className="counts-list">
              <li className="counts-list-item">
                <span className="count-title episodes">Episodes</span>
                <span className="counts-total">{x.episodeCount}</span>
              </li>
              <li className="counts-list-item">
                <span className="count-title operations">Operations</span>
                <span className="counts-total">{x.operationCount}</span>
              </li>
              <li className="counts-list-item">
                <span className="count-title patients">Patients</span>
                <span className="counts-total">{x.patientCount}</span>
              </li>
            </ul>
          </div>
        </li>
      );
    });
    return results;
  };

  filter = e => {
    let text = e.target.value;
    this.setState({ filterText: e.target.value });
    if (text !== "") {
      this.setState({
        filteredGroupList: this.props.results[0].groups.filter(e =>
          e.title.toLowerCase().includes(text.toLowerCase())
        )
      });
    } else {
      this.setState({ filteredGroupList: this.props.results[0].groups });
    }
  };

  render() {
    return (
      <div className="groups-list-menu">
        <div className="search-filter-wrapper">
          <InputField
            className="filter-input"
            name={"filter-menu"}
            type={"text"}
            onChangeFunc={e => this.filter(e)}
            placeholder={"Filter groups"}
            value={this.state.filterText}
            isValid={null}
            label={"Filter groups"}
            disabled={false}
          />
        </div>
        {this.props.results.length !== 0 ? (
          <ul className="groups-list">{this.renderGroupsListMenu()}</ul>
        ) : (
          <p> you have no clusters</p>
        )}
      </div>
    );
  }
}

GroupsListMenu.propTypes = {
  results: PropTypes.array.isRequired,
  selectGroup: PropTypes.func.isRequired,
  totalEpisodes: PropTypes.number,
  population: PropTypes.number,
  selectCompareGroup: PropTypes.func.isRequired,
  removeCompareGroup: PropTypes.func.isRequired
};

export default GroupsListMenu;
