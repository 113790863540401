import React from "react";
import PropTypes from "prop-types";

const ProfileDemographics = props => {
  return (
    <div className="profile-demographics grid-box">
      <h3 className="profile-box-title">Demographics</h3>
      <ul className="profile-details-list">
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">title:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.title}
          </span>
        </li>
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">forename:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.forename}
          </span>
        </li>
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">surname:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.surname}
          </span>
        </li>
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">suffix:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.suffix}
          </span>
        </li>
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">Username:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.username}
          </span>
        </li>
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">Email:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.email}
          </span>
        </li>
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">Mobile:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.mobile}
          </span>
        </li>
      </ul>
    </div>
  );
};

ProfileDemographics.propTypes = {
  currentUser: PropTypes.shape({
    title: PropTypes.string.isRequired,
    forename: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    suffix: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,

    specialism: PropTypes.string
  })
};

export default ProfileDemographics;
