import React, { Component } from "react";
import PropTypes from "prop-types";
import ProfileAvatar from "../../components/Profile/ProfileAvatar";
import ProfileDemographics from "../../components/Profile/ProfileDemographics";
import ProfileExtraDetails from "../../components/Profile/ProfileExtraDetails";
import ProfileColleagues from "../../components/Profile/ProfileColleagues";
import PasswordCheck from "../../components/Profile/PasswordCheck";

import { CHECK_USER, UPDATE_PASSWORD } from "../../gql/ProfileGQL";
import { client } from "../../store/configureClient";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "unauthorised"
    };
  }

  // Checks current users password
  checkUser = obj => {
    client
      .mutate({
        mutation: CHECK_USER,
        variables: {
          email: this.props.currentUser.email,
          password: obj.password
        }
      })
      .then(({ data }) => {
        this.setState({ message: data.loginUser.message });
        this.props.renderMessages("valid", "password correct", true);
      })
      .catch(error => {
        this.props.renderMessages("invalid", "password incorrect", true);
      });
  };

  // Updates current user password
  updatePassword = obj => {
    client
      .mutate({
        mutation: UPDATE_PASSWORD,
        variables: {
          email: this.props.currentUser.email,
          password: obj.newPassword
        }
      })
      .then(({ data }) => {
        this.props.renderMessages("valid", "password changed", true);
      })
      .catch(error => {
        this.props.renderMessages("invalid", "password incorrect", true);
      });
  };

  render() {
    return (
      <div className="profile">
        <div className="profile-inner">
          <ProfileAvatar currentUser={this.props.currentUser} />
          <ProfileDemographics currentUser={this.props.currentUser} />
          <ProfileExtraDetails currentUser={this.props.currentUser} />
          <ProfileColleagues currentUser={this.props.currentUser} />
          <PasswordCheck
            checkUser={this.checkUser}
            message={this.state.message}
            updatePassword={this.updatePassword}
          />
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  currentUser: PropTypes.object.isRequired,
  renderMessages: PropTypes.func.isRequired
};

export default Profile;
