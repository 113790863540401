import React, { Component } from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";
import { withTheme } from "@material-ui/core/styles";

class LineChart extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      borderSettings: {
        borderWidth: 2,
        fill: false
      },
      borderColors: this.props.theme.palette.chartBorderColors,
      backgroundColors: this.props.theme.palette.chartBgColors,
      legend: {
        labels: {
          fontColor: this.props.theme.typography.color
        }
      }
    };
    // Ref used to get node
    this["lineChartRef" + this.props.refName] = React.createRef();
  }

  componentDidMount() {
    const node = this["lineChartRef" + this.props.refName].current;
    this.chart = this.renderLineChart(node);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataSet !== this.props.dataSet) {
      this.updateChart(
        this.props.dataSet,
        this.props.options,
        this.props.labels
      );
    }

    if (
      prevProps.theme.palette.chartBgColors !==
      this.props.theme.palette.chartBgColors
    ) {
      this.setState(
        {
          borderColors: this.props.theme.palette.chartBorderColors,
          backgroundColors: this.props.theme.palette.chartBgColors,
          legend: {
            labels: {
              fontColor: this.props.theme.typography.color
            }
          }
        },
        () => {
          this.updateChart(
            this.props.dataSet,
            this.props.options,
            this.props.labels
          );
        }
      );
    }
  }

  // // Updates charts with new data and options
  updateChart = (newData, options, labels) => {
    this.chart.data.datasets = [];
    // Adds color defaults if none set
    let data = this.props.dataSet.map((x, i) => {
      if (this.props.defaultStyles) {
        return (x = {
          ...x,
          ...this.state.borderSettings,
          borderColor: this.state.borderColors[i],
          backgroundColor: this.state.backgroundColors[i]
        });
      } else {
        return x;
      }
    });

    let muiOptions = {
      ...this.props.options,
      legend: {
        ...this.state.legend,
        ...(this.props.options !== undefined && this.props.options)
      }
    };

    this.chart.options = muiOptions;
    this.chart.data.datasets = data;
    this.chart.data.labels = labels;
    this.chart.update();
  };

  // Creates first instantiation of chart
  renderLineChart = ctx => {
    // Adds color defaults if none set
    let data = this.props.dataSet.map((x, i) => {
      if (this.props.defaultStyles) {
        return (x = {
          ...x,
          ...this.state.borderSettings,
          borderColor: this.state.borderColors[i],
          backgroundColor: this.state.backgroundColors[i]
        });
      } else {
        return x;
      }
    });

    let muiOptions = {
      ...this.props.options,
      legend: {
        ...this.state.legend,
        ...(this.props.options !== undefined && this.props.options)
      }
    };

    return new Chart(ctx, {
      type: this.props.type, // "line"
      data: {
        labels: this.props.labels,
        datasets: [...data]
      },
      // Adds default options if none set
      options: muiOptions
    });
  };

  render() {
    return (
      <div className="line-chart chart">
        <canvas
          className="chart-canvas line-chart-canvas"
          ref={this["lineChartRef" + this.props.refName]}
          width={this.props.width ? `${this.props.width}px` : "400px"}
          height={this.props.height ? `${this.props.height}px` : "400px"}
          id={this.props.refName}
        />
      </div>
    );
  }
}

LineChart.propTypes = {
  dataSet: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  type: PropTypes.string,
  labels: PropTypes.array.isRequired,
  refName: PropTypes.string.isRequired,
  options: PropTypes.object,
  defaultStyles: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      chartBgColors: PropTypes.array.isRequired,
      chartBorderColors: PropTypes.array.isRequired
    }),
    typography: PropTypes.shape({
      color: PropTypes.string.isRequired
    })
  })
};

export default withTheme()(LineChart);
