import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter, HashRouter } from "react-router-dom";

/***********************************************************************************************
Imports apollo client from store/configureClient 
Imports retreiveToken function used to get jwt token from login response then return it to 
request headers in apollo client requests 
***********************************************************************************************/
import { client, retreiveToken } from "./store/configureClient";

// Toggles router mode based upon dev environment
// Replace with correct choice on compile
let routerMode = () => {
  if (typeof window.require === "function") {
    return (
      <HashRouter>
        <ApolloProvider client={client}>
          <App retreiveToken={retreiveToken} />
        </ApolloProvider>
      </HashRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <ApolloProvider client={client}>
          <App retreiveToken={retreiveToken} />
        </ApolloProvider>
      </BrowserRouter>
    );
  }
};

ReactDOM.render(routerMode(), document.getElementById("root"));
