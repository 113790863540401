import React from "react";
import PropTypes from "prop-types";

class Toggles extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      toggled: this.props.options[0]
    };
  }

  // Toggles btn positions
  toggleBtnClass = (e, result) => {
    let switchVal = result === "true" ? "false" : "true";
    this.setState({ toggled: switchVal });
  };

  // N.B 🤔 the values of the toggles are reversed as you need to click the false btn to set true

  renderToggles = () => {
    let togglesList = [];
    this.props.options.map((toggle, i) => {
      togglesList.push(
        <React.Fragment key={toggle + i}>
          <input
            type="radio"
            name={this.props.name}
            value={toggle === "true" ? "false" : "true"}
            onChange={this.props.onChangeFunc}
            disabled={this.props.disabled}
            id={`${this.props.name}${i}`}
            className="toggle-input"
          />
          <label
            className={
              toggle === "true"
                ? "toggle-label toggled"
                : "toggle-label untoggled"
            }
            htmlFor={`${this.props.name}${i}`}
            onClick={e => this.toggleBtnClass(e, toggle)}
          >
            {this.props.toggleSwitchLabels[i]}
          </label>
        </React.Fragment>
      );
    });
    return togglesList;
  };

  render() {
    return (
      <div
        className={`input-holder toggle-holder ${this.props.name}-holder valid`}
      >
        <label>{this.props.label}</label>
        <div className="items-container">
          <div
            className={
              this.props.options[0] === "true"
                ? "toggle-switches-holder"
                : "toggle-switches-holder reversed"
            }
          >
            <div
              className={
                this.state.toggled === "true"
                  ? "toggle-switches-holder-inner toggle-on"
                  : "toggle-switches-holder-inner"
              }
            >
              {this.renderToggles()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Toggles.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeFunc: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  toggleSwitchLabels: PropTypes.array
};

export default Toggles;
