import React, { Component } from "react";
import PropTypes from "prop-types";
import { generateId } from "../../lib/utils";
import ChartViewSwitcher from "../Charts/ChartViewSwitcher";
import BarChart from "../Charts/BarChart";
import SelectionSwitcher from "../SelectionSwitcher/SelectionSwitcher";
// import { makeHeightFlexible } from "react-vis/dist/make-vis-flexible";

// TODO need to coordinate selection behaviours across components
// i.e clicking an episode list and adding to group makeHeightFlexible,
// needs to toggle from either component

/*eslint-disable */

class Diagnoses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMain: true,
      primary: true,
      confirmed: true,
      viewState: true,
      viewChart: true,
      viewTable: false,
      barOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "white"
              },
              maxBarThickness: 40
            }
          ]
        },
        legend: {
          labels: {
            fontColor: "white"
          },
          display: false
        },
        // Passes group creator func into chart so groups can be made of bar clicks
        onClick: (evt, element) => {
          if (element.length > 0) {
            // todo fix this func so whole bar is available on click
            this.getEpisodeIds(element[0]._model.label);
          }
        }
      }
    };
  }

  aggregate = () => {
    // HOW MANY OF EACH Diagnosis
    // SPLIT BETWEEN LEFT AND RIGHT
    // HOW MANY HAVE A BILATERAL condition
    // HOW MANY HAVE CO-MORBIDITY
    if (this.props.results.diagnoses !== null) {
      // let diagnosisItems = [];
      let res = this.props.results
        .filter(x => x.diagnoses !== null && x)
        .filter(x => x.diagnoses.diagnosisItems.length > 0 && x)
        .map(x => x.diagnoses)
        .map(x => {
          let { diagnosisItems } = x;

          return diagnosisItems;
        })
        .reduce((acc, val) => acc.concat(val), []);
      if (this.state.isMain) {
        res = res.filter(e => e.isMain && e.isMain === "true");
      }
      if (this.state.primary) {
        res = res.filter(
          e => e.ordinal && e.ordinal.toLowerCase() === "primary"
        );
      }
      if (this.state.confirmed) {
        res = res.filter(
          e => e.status && e.status.toLowerCase() === "confirmed"
        );
      }

      const accumulatedTotals = res.reduce(
        (totals, d) => ({
          ...totals,
          [d.diagnosis]: (totals[d.diagnosis] || 0) + 1
        }),
        {}
      );

      let finals = [];
      for (let key of Object.keys(accumulatedTotals)) {
        let result = Object.assign(
          {},
          {
            diagnosis: key,
            total: accumulatedTotals[key],
            primary: 0,
            secondary: 0,
            left: 0,
            right: 0,
            bilateral: 0,
            main: 0,
            comorbidity: 0
          }
        );
        let test = this.props.results.filter(
          e => e.diagnoses && e.diagnoses.diagnosisItems.length > 0
        );
        let approp = test.filter(e =>
          e.diagnoses.diagnosisItems.some(e => e.diagnosis === key)
        );
        for (let e of approp) {
          for (let d of e.diagnoses.diagnosisItems.filter(
            e => e.diagnosis === key
          )) {
            if (res.find(x => x._id === d._id)) {
              d.ordinal.toLowerCase() === "primary"
                ? (result.primary += 1)
                : "";
              d.ordinal.toLowerCase() === "secondary"
                ? (result.secondary += 1)
                : "";
              d.side.toLowerCase() === "left" ? (result.left += 1) : "";
              d.side.toLowerCase() === "right" ? (result.right += 1) : "";
              d.side.toLowerCase() === "bilateral"
                ? (result.bilateral += 1)
                : "";
              d.isMain.toLowerCase() === "true" ? (result.main += 1) : "";
              if (
                d.isMain.toLowerCase() === "true" &&
                d.ordinal.toLowerCase() === "primary"
              ) {
                if (
                  e.diagnoses.diagnosisItems.filter(
                    x => x.isMain === "true" && x.side == d.side
                  ).length > 1
                ) {
                  result.comorbidity += 1;
                }
              }
            }
          }
        }

        finals.push(result);
      }
      let items = [];

      for (let row of finals.sort((a, b) => a.total - b.total).reverse()) {
        items.push(
          <tr key={generateId()}>
            <td key={generateId()}>{row.diagnosis}</td>
            <td key={generateId()}>{row.total}</td>
            {/* <td key={generateId()}>{row.primary > 0 ? row.primary : ""}</td> */}
            {/* <td key={generateId()}>{row.secondary > 0 ? row.secondary : ""}</td> */}
            <td key={generateId()}>{row.left > 0 ? row.left : ""}</td>
            <td key={generateId()}>{row.right > 0 ? row.right : ""}</td>
            <td key={generateId()}>{row.bilateral > 0 ? row.bilateral : ""}</td>
            <td key={generateId()}>
              {row.comorbidity > 0 ? row.comorbidity : ""}
            </td>
            {/* <td key={generateId()}>{row.main > 0 ? row.main : ""}</td> */}
            <td className="add-episodes">
              <SelectionSwitcher
                selectIcon={"branch"}
                deselectIcon={"branch"}
                selection={() => {
                  this.getEpisodeIds(row.diagnosis);
                }}
              />
            </td>
          </tr>
        );
      }
      return items;
    }
  };

  performFiltering = () => {
    // console.log(this.state)
  };

  filter = controlName => {
    switch (controlName) {
      case "isMain":
        this.setState({ isMain: !this.state.isMain }, this.performFiltering);
        break;
      case "primary":
        this.setState({ primary: !this.state.primary }, this.performFiltering);
        break;
      case "confirmed":
        this.setState(
          { confirmed: !this.state.confirmed },
          this.performFiltering
        );
        break;
    }
  };

  // Creates temp group based
  getEpisodeIds = label => {
    // Merge externalId into diagnosis obj then
    // filter ep id by passed in label
    let episodeIdList = this.props.results
      .filter(x => x.diagnoses !== null && x)
      .filter(x => x.diagnoses.diagnosisItems.length > 0 && x)
      .map(x => {
        return {
          externalId: x.externalId,
          diagnosisItems: x.diagnoses.diagnosisItems
        };
      })
      .map(x => {
        return [
          ...x.diagnosisItems.map(y => {
            return { externalId: x.externalId, ...y };
          })
        ];
      })
      .reduce((acc, val) => acc.concat(val), [])
      .filter(
        x =>
          x.diagnosis === label &&
          x.isMain === "true" &&
          x.status.toLowerCase() === "confirmed" &&
          x.ordinal.toLowerCase() === "primary"
      )
      .map(x => {
        return x.externalId; // just returns externalIds
      });

    this.props.addToTempGroup(episodeIdList);
  };

  setBarData = () => {
    if (this.props.results.diagnoses !== null) {
      // let diagnosisItems = [];
      let res = this.props.results
        .map(x => (x.diagnoses ? x.diagnoses : { diagnosisItems: [] }))
        .map(x => {
          let { diagnosisItems } = x;

          return diagnosisItems;
        })
        .reduce((acc, val) => acc.concat(val), []);
      if (this.state.isMain) {
        res = res.filter(e => e.isMain && e.isMain === "true");
      }
      if (this.state.primary) {
        res = res.filter(
          e => e.ordinal && e.ordinal.toLowerCase() === "primary"
        );
      }
      if (this.state.confirmed) {
        res = res.filter(
          e => e.status && e.status.toLowerCase() === "confirmed"
        );
      }

      const accumulatedTotals = res.reduce(
        (totals, d) => ({
          ...totals,
          [d.diagnosis]: (totals[d.diagnosis] || 0) + 1
        }),
        {}
      );

      let finals = [];
      for (let key of Object.keys(accumulatedTotals)) {
        let result = Object.assign(
          {},
          {
            diagnosis: key,
            total: accumulatedTotals[key],
            primary: 0,
            secondary: 0,
            left: 0,
            right: 0,
            bilateral: 0,
            main: 0,
            comorbidity: 0
          }
        );
        let test = this.props.results.filter(
          e => e.diagnoses && e.diagnoses.diagnosisItems.length > 0
        );
        let approp = test.filter(e =>
          e.diagnoses.diagnosisItems.some(e => e.diagnosis === key)
        );
        for (let e of approp) {
          for (let d of e.diagnoses.diagnosisItems.filter(
            e => e.diagnosis === key
          )) {
            if (res.find(x => x._id === d._id)) {
              d.ordinal.toLowerCase() === "primary"
                ? (result.primary += 1)
                : "";
              d.ordinal.toLowerCase() === "secondary"
                ? (result.secondary += 1)
                : "";
              d.side.toLowerCase() === "left" ? (result.left += 1) : "";
              d.side.toLowerCase() === "right" ? (result.right += 1) : "";
              d.side.toLowerCase() === "bilateral"
                ? (result.bilateral += 1)
                : "";
              d.isMain.toLowerCase() === "true" ? (result.main += 1) : "";
              if (
                d.isMain.toLowerCase() === "true" &&
                d.ordinal.toLowerCase() === "primary"
              ) {
                if (
                  e.diagnoses.diagnosisItems.filter(
                    x => x.isMain === "true" && x.side == d.side
                  ).length > 1
                ) {
                  result.comorbidity += 1;
                }
              }
            }
          }
        }

        finals.push(result);
      }
      return finals.sort((a, b) => a.total - b.total).reverse();
    }
  };

  switchView = () => {
    this.setState({
      viewState: !this.state.viewState,
      viewChart: !this.state.viewChart,
      viewTable: !this.state.viewTable
    });
  };

  tableView = () => {
    return (
      <table className="table-container">
        <tbody>
          <tr className="table-headings">
            <td>diagnosis</td>
            <td>total</td>
            {/* <td>primary</td> */}
            {/* <td>secondary</td> */}
            <td>left</td>
            <td>right</td>
            <td>bilateral</td>
            <td>co-morbidity</td>
            {/* <td>is main diagnosis</td> */}
            <td>select</td>
          </tr>
          {this.aggregate()}
        </tbody>
      </table>
    );
  };

  chartView = () => {
    let data = this.setBarData();
    // Checks data length and adds aspectRatio if less than 18
    // let barOptions =
    //   data.length < 18
    //     ? {
    //         ...this.state.barOptions,
    //         aspectRatio: 1.5,
    //         maintainAspectRatio: false
    //       }
    //     : this.state.barOptions;

    return (
      <div
        className={
          data.length < 18 ? "chart-fixed-wrapper small" : "chart-fixed-wrapper"
        }
      >
        <BarChart
          dataSet={[
            {
              label: "Diagnosis",
              data: data.map(e => e.total),
              backgroundColor: "rgba(34, 220, 198, 1)"
            }
          ]}
          labels={data.map(e => e.diagnosis)}
          type={"horizontalBar"}
          refName={"diagnosesChartList"}
          options={this.state.barOptions}
          defaultStyles={false}
          // height={200}
          // width={600}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="diagnoses grid-box">
        <div className="diagnosis-header">
          <h2>Diagnoses</h2>
          <ChartViewSwitcher
            switchView={this.switchView}
            viewChart={this.state.viewChart}
            viewTable={this.state.viewTable}
          />
        </div>
        <div className="separator-line-muted" />
        <div className="inner">
          <div className="sides">
            {/* <h4>sides</h4> */}
            {/* todo review how to handle */}
            {/* {this.renderSides()} */}
          </div>
          <div>
            {this.state.viewState ? this.chartView() : this.tableView()}
          </div>
        </div>
        <div>
          <br />
          <div className="separator-line-muted" />
          <br />
          <input
            type="checkbox"
            value="main"
            checked={this.state.isMain}
            onChange={() => this.filter("isMain")}
          />
          <span className="checkbox-labels">Main Diagnosis</span>
          <br />
          <input
            type="checkbox"
            value="primary"
            checked={this.state.primary}
            onChange={() => this.filter("primary")}
          />
          <span className="checkbox-labels">Primary</span>
          <br />
          <input
            type="checkbox"
            value="confirmed"
            checked={this.state.confirmed}
            onChange={() => this.filter("confirmed")}
          />
          <span className="checkbox-labels">Confirmed</span>
          <br />
        </div>
      </div>
    );
  }
}

Diagnoses.propTypes = {
  results: PropTypes.array.isRequired
};

export default Diagnoses;
/*eslint-enable */
