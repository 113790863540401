import React, { Component } from "react";
import PropTypes from "prop-types";
import FormContainer from "../../components/Forms/FormContainer";
import { validateLogin } from "../../lib/validation";
import { LOGIN_USER } from "../../gql/LoginGQL";
// import { Link } from "react-router-dom";
import { client } from "../../store/configureClient";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      submission: {},
      fields: [
        {
          domEl: "input",
          name: "email",
          type: "email",
          plaeholder: "",
          label: "username"
        },
        {
          domEl: "input",
          name: "password",
          type: "password",
          placeholder: "",
          label: "password"
        }
      ]
    };
  }

  // Retreives form inputs and adds to state
  addLoginInputsToState = (sectionName, isSuccessful, submissionResults) => {
    if (isSuccessful) {
      this.setState({
        [sectionName]: isSuccessful,
        submission: {
          ...this.state.submission,
          ...submissionResults
        }
      });
    }
  };

  loginUser = obj => {
    client
      .mutate({
        mutation: LOGIN_USER,
        variables: { email: obj.email, password: obj.password }
      })
      .then(({ data }) => {
        this.retreiveTokenAndUser(data.loginUser);
      })
      .catch(error => {
        this.props.renderMessages("invalid", "Login failed", true);
      });
  };

  //  Retreives token and passes it up to app and header request
  // along with logged in User
  //  to unlock dashboard route
  retreiveTokenAndUser = data => {
    this.props.checkAuthStatusAndGetCurrentUser(data);
  };

  render() {
    return (
      <div className="login">
        <div className="top-section">
          <h1 className="login-title">SUMO.CO</h1>
        </div>
        <div className="bottom-section">
          <FormContainer
            fields={this.state.fields} // form fields
            formName={"login"} //form name
            formClass={"login-form"} // form class used for styling
            submitTitle={"enter"} // submit button title
            submissionFunc={this.loginUser} // function used to handle retrieved data
            validation={validateLogin} //validtaion passed in from external lib used to add class to field wrapper div
          />
          {/* <div className="login-forgot-password">
            <Link to="/forgot-password">forgot password</Link>
          </div> */}
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  checkAuthStatusAndGetCurrentUser: PropTypes.func.isRequired,
  renderMessages: PropTypes.func.isRequired
};

export default Login;
