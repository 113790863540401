import React, { useState } from "react";
import PropTypes from "prop-types";
import SvgIcons from "../Misc/SvgIcons";

function TableSorter(props) {
  const [direction, setDirection] = useState("asc");

  function fireFunc() {
    if (direction === "asc") {
      setDirection("des");
    } else {
      setDirection("asc");
    }
    props.toggleFunc(props.node, direction);
  }

  return (
    <div className="table-sorter" onClick={fireFunc}>
      <SvgIcons icon={direction === "asc" ? "arrowUp" : "arrowDown"} />
    </div>
  );
}

TableSorter.propTypes = {
  toggleFunc: PropTypes.func.isRequired,
  node: PropTypes.string.isRequired
};

export default TableSorter;
