import React, { Component } from "react";
import PropTypes from "prop-types";
import SvgIcons from "../../components/Misc/SvgIcons";

// TODO refactor once message handling is decided
class Messages extends Component {
  constructor(props) {
    super(props);
    // Reference to timer
    this.timeOut = null;
    this.state = {
      messageStatus: "",
      messageContent: ""
    };
  }

  componentDidMount() {
    this.setState({
      messageStatus: this.props.messageStatus,
      messageContent: this.props.messageContent
    });
    // calls timer
    this.timeOut = this.timer();
  }

  // Checks Previous state has been reset and updates with new passed in props
  componentDidUpdate(prevProps, prevState) {
    if (prevState.messageStatus !== this.props.messageStatus) {
      this.setState({
        messageStatus: this.props.messageStatus,
        messageContent: this.props.messageContent
      });
      // Clears and then resets timer
      clearTimeout(this.timer);
      this.timeOut = this.timer();
    }
  }

  // Clears timer before unmount
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  // Timer function to auto hide messages
  timer = () =>
    setTimeout(() => {
      this.props.hideMessage();
    }, 4000);

  render() {
    return (
      <div className={"messages"} onClick={this.props.hideMessage}>
        <div
          className={
            this.props.messageStatus
              ? `messages-inner ${this.props.messageStatus}`
              : "message-inner"
          }
        >
          <p className="message">{this.state.messageContent}</p>
          <SvgIcons icon={"cross"} />
        </div>
      </div>
    );
  }
}

Messages.propTypes = {
  messageContent: PropTypes.string.isRequired,
  messageStatus: PropTypes.string.isRequired,
  hideMessage: PropTypes.func.isRequired
};

export default Messages;
