import gql from "graphql-tag";

export const CHECK_USER = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      message
      currentUser {
        email
      }
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($email: String!, $password: String!) {
    updatePassword(email: $email, password: $password) {
      message
    }
  }
`;
