import React, { Component } from "react";
import PropTypes from "prop-types";
import FormContainer from "../Forms/FormContainer";
import {
  validatePassword,
  validateUpdatedPassword
} from "../../lib/validation";

class PasswordCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          domEl: "input",
          name: "password",
          type: "password",
          placeholder: "",
          label: "current password"
        }
      ],
      newPasswordFields: [
        {
          domEl: "input",
          name: "newPassword",
          type: "password",
          placeholder: "",
          label: "new password"
        },
        {
          domEl: "input",
          name: "newPasswordConfirm",
          type: "password",
          placeholder: "",
          label: "confirm new password"
        }
      ]
    };
  }

  render() {
    return (
      <div className="password-check grid-box">
        <h3 className="profile-box-title">change password</h3>
        <div className="password-forms-holder">
          <div className="check-password">
            <FormContainer
              fields={this.state.fields} // form fields
              formName={"passwordCheck"} //form name
              formClass={"password-check-form"} // form class used for styling
              submitTitle={"check"} // submit button title
              submissionFunc={this.props.checkUser} // function used to handle retrieved data
              validation={validatePassword} //validtaion passed in from external lib used to add class to field wrapper div
            />
          </div>
          {this.props.message === "authorised" && (
            <div className="new-password">
              <FormContainer
                fields={this.state.newPasswordFields} // form fields
                formName={"newPassword"} //form name
                formClass={"new-password-form"} // form class used for styling
                submitTitle={"change"} // submit button title
                submissionFunc={this.props.updatePassword} // function used to handle retrieved data
                validation={validateUpdatedPassword} //validtaion passed in from external lib used to add class to field wrapper div
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

PasswordCheck.propTypes = {
  checkUser: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};

export default PasswordCheck;
