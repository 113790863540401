import React from "react";
import PropTypes from "prop-types";
import InputField from "./components/InputField";
import SelectBox from "./components/SelectBox";
import CheckBoxes from "./components/CheckBoxes";
import SubmitButton from "./components/SubmitButton";
import Radios from "./components/Radios";
import DropdownList from "./components/DropdownList";
import Toggles from "./components/Toggles";
import TextareaField from "./components/TextareaField";

class FormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submission: {}, // obj that holds results {name: value}
      sectionComplete: false,
      validations: {}
    };
  }

  componentDidMount() {
    this.setStateValues();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const numOfInputs = Object.keys(this.state.validations).length;
    const numOfInputsNull = Object.keys(this.state.validations).filter(
      x => this.state.validations[x] === null
    );
    // Check validity function fired on update
    if (
      prevState.validations !== this.state.validations &&
      numOfInputsNull.length !== numOfInputs
    ) {
      this.checkAllInputsValid();
    }
  }

  // Function to create form input state values to create controlled components
  setStateValues = () => {
    // Creates a list of input key/value pairs for inputs
    let values = {};
    this.props.fields.map((x, i) => {
      if (x.domEl === "checkbox") {
        values[x.name] = [];
      } else if (x.domEl === "toggle") {
        values[x.name] = x.toggleSwitchLabels[0];
      } else {
        values[x.name] = "";
      }
    });

    // Creates a list of key/values pairs for input validation
    let validations = {};
    this.props.fields.map((x, i) => {
      if (x.domEl === "toggle") {
        validations[x.name + "IsValid"] = true;
      } else {
        validations[x.name + "IsValid"] = null;
      }
    });

    this.setState({
      submission: {
        ...values
      },
      validations: {
        ...validations
      }
    });
  };

  // Function that checks all inputs are valid and changes submit button class
  checkAllInputsValid = () => {
    // Compares number of inputs against number of true occurances
    const numOfInputs = Object.keys(this.state.validations).length;
    const numOfInputsValid = Object.keys(this.state.validations).filter(
      x => this.state.validations[x] === true
    );
    const numOfInputsNull = Object.keys(this.state.validations).filter(
      x => this.state.validations[x] === null
    );

    // If above values match enables submit button
    if (numOfInputs === numOfInputsValid.length) {
      this.setState({ sectionComplete: true }, () => {
        if (this.props.getFormStatus !== undefined) {
          this.props.getFormStatus(
            this.props.formName,
            true,
            this.state.submission
          );
        }
      });
    } else if (numOfInputsNull.length !== numOfInputs) {
      this.setState({ sectionComplete: false });
      // function to lift state out of form container
      if (this.props.getFormStatus) {
        this.props.getFormStatus(this.props.formName, false, {});
      }
    }
  };

  // Renders inputs based upon passed in fields array from parent
  renderInputs = () => {
    // Renders after state values are set
    if (Object.keys(this.state.submission).length !== 0) {
      let fields = [];
      this.props.fields.map((element, i) => {
        fields.push(
          <React.Fragment key={element.name + i}>
            {this.inputElementReturn(element)}
          </React.Fragment>
        );
      });

      return fields;
    }
  };

  // Switch block that controls which input components are rendered uses values in fields array to individualise
  inputElementReturn = el => {
    const name = el.name;
    const type = el.type;
    const element = el.domEl;
    const placeholder = el.placeholder;
    const options = el.options ? el.options : [];
    const label = el.label;
    const iconName = el.iconName ? el.iconName : "";
    const toggleSwitchLabels = el.toggleSwitchLabels;
    const disabled = this.props.disabledInputStatus
      ? this.props.disabledInputStatus
      : false;

    switch (element) {
      case "input":
        return (
          <InputField
            name={name}
            type={type}
            onChangeFunc={this.handleChange}
            placeholder={placeholder}
            value={this.state.submission[name]}
            isValid={this.state.validations[name + "IsValid"]}
            label={label}
            iconName={iconName}
            disabled={disabled}
          />
        );
      case "select":
        return (
          <SelectBox
            name={name}
            type={type}
            onChangeFunc={this.handleChange}
            placeholder={placeholder}
            options={options}
            isValid={this.state.validations[name + "IsValid"]}
            label={label}
            value={this.state.submission[name]}
            disabled={disabled}
          />
        );

      case "checkbox":
        return (
          <CheckBoxes
            name={name}
            type={type}
            onChangeFunc={this.handleCheckboxes}
            placeholder={placeholder}
            options={options}
            isValid={this.state.validations[name + "IsValid"]}
            label={label}
            disabled={disabled}
          />
        );
      case "radio":
        return (
          <Radios
            name={name}
            type={type}
            onChangeFunc={this.handleChange}
            placeholder={placeholder}
            options={options}
            isValid={this.state.validations[name + "IsValid"]}
            label={label}
            disabled={disabled}
          />
        );

      case "dropdown":
        return (
          <DropdownList
            name={name}
            type={type}
            onChangeFunc={this.handleDropdown}
            placeholder={placeholder}
            options={options}
            isValid={this.state.validations[name + "IsValid"]}
            label={label}
          />
        );
      case "toggle":
        return (
          <Toggles
            name={name}
            type={type}
            onChangeFunc={this.handleToggle}
            placeholder={placeholder}
            options={options}
            isValid={this.state.validations[name + "IsValid"]}
            label={label}
            disabled={disabled}
            toggleSwitchLabels={toggleSwitchLabels}
          />
        );
      case "textarea":
        return (
          <TextareaField
            name={name}
            type={type}
            onChangeFunc={this.handleChange}
            placeholder={placeholder}
            value={this.state.submission[name]}
            isValid={this.state.validations[name + "IsValid"]}
            label={label}
            iconName={iconName}
            disabled={disabled}
          />
        );

      default:
        break;
    }
  };

  // Handle change function that takes values and adds to state
  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(
      {
        submission: {
          ...this.state.submission,
          [name]: value
        }
      },
      this.props.validation(this, name, value)
    );
  };

  handleDropdown = (name, value) => {
    this.setState(
      {
        submission: {
          ...this.state.submission,
          [name]: value
        }
      },
      this.props.validation(this, name, value)
    );
  };
  // Function to handle checkboxes
  handleCheckboxes = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let checkboxValues = this.state.submission[name];

    if (target.checked) {
      checkboxValues.push(value);
    } else if (!target.checked) {
      checkboxValues.pop(value);
    }
    this.setState(
      {
        submission: {
          ...this.state.submission,
          [name]: checkboxValues
        }
      },
      this.props.validation(this, name, value)
    );
  };

  handleToggle = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState(
      {
        submission: {
          ...this.state.submission,
          [name]: JSON.parse(value)
        }
      },
      this.props.validation(this, name, value)
    );
  };

  // Submission function that takes passed down prop function to fire result
  handleSubmit = event => {
    event.preventDefault();
    // Clears inputs if prop true
    if (this.props.clearState) {
      // Passed down submit fucntion
      this.props.submissionFunc(this.state.submission);
      this.setStateValues();
    } else {
      this.props.submissionFunc(this.state.submission);
    }
  };

  render() {
    return (
      <form
        name={this.props.formName}
        className={this.props.formClass}
        onSubmit={this.handleSubmit}
      >
        {this.renderInputs()}
        {this.props.withButton !== false && (
          <SubmitButton
            title={this.props.submitTitle}
            buttonActive={this.state.sectionComplete}
          />
        )}
      </form>
    );
  }
}

FormContainer.propTypes = {
  fields: PropTypes.array.isRequired,
  formName: PropTypes.string,
  formClass: PropTypes.string.isRequired,
  submitTitle: PropTypes.string,
  submissionFunc: PropTypes.func,
  validation: PropTypes.func.isRequired,
  withButton: PropTypes.bool,
  getFormStatus: PropTypes.func,
  disabledInputStatus: PropTypes.bool,
  clearState: PropTypes.bool
};

export default FormContainer;
