import React, { Component } from "react";
import PropTypes from "prop-types";
import { generateId } from "../../lib/utils";
import moment from "moment";
import SelectionSwitcher from "../SelectionSwitcher/SelectionSwitcher";

class Operations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nonOpPatients: { count: 0, keys: [] },
      multiOpPatients: { count: 0, keys: [] },
      operationItems: [],
      filteredOperationItems: [],
      highlightEpisode: ""
    };
  }

  componentDidMount() {
    let transitive = this.props.results.map(e =>
      Object.assign(
        {},
        {
          externalId: e.externalId,
          gender: e.patient.gender,
          dob: e.patient.dob,
          complications: e.complications
        },
        e.operations
      )
    );
    let nonOpPatients = { count: 0, keys: new Set() };
    let multiOpPatients = { count: 0, keys: new Set() };
    let opData = [];
    for (let ops of transitive) {
      let complications = "";
      if (ops.complications && ops.complications.complicationItems.length > 0) {
        complications = ops.complications.complicationItems
          .map(e => e.title)
          .join(", ");
      }
      let key = ops.externalId;
      let gender = ops.gender;
      let dob = ops.dob;
      if (ops.operationItems) {
        if (ops.operationItems.length > 1) {
          multiOpPatients.count += 1;
          multiOpPatients.keys.add(key);
        }
        for (let op of ops.operationItems) {
          opData.push(
            Object.assign({}, op, {
              externalId: key,
              gender: gender,
              dob: dob,
              complications: complications
            })
          );
        }
      } else {
        nonOpPatients.count += 1;
        nonOpPatients.keys.add(key);
      }
    }
    this.setState({
      nonOpPatients: {
        count: nonOpPatients.count,
        keys: Array.from(nonOpPatients.keys)
      },
      multiOpPatients: {
        count: multiOpPatients.count,
        keys: Array.from(multiOpPatients.keys)
      },
      operationItems: opData
    });
  }

  filter = (title, keys) => {
    let filteredList = [];
    if (title === "All") {
      filteredList = this.state.operationItems.filter(e =>
        keys.includes(e.externalId)
      );
    } else {
      filteredList = this.state.operationItems.filter(
        e => e.title === title && keys.includes(e.externalId)
      );
    }
    this.setState({ filteredOperationItems: filteredList });
  };

  mouseOver = externalId => {
    this.setState({ highlightEpisode: externalId });
  };

  renderGroupView = () => {
    let opItems = this.state.operationItems;
    let opTitles = Array.from(new Set(opItems.map(e => e.title)));
    let rows = [];
    for (let title of opTitles) {
      let ops = opItems.filter(e => e.title === title);
      let keys = Array.from(new Set(ops.map(e => e.externalId)));
      rows.push({
        keys: keys,
        title: title,
        count: ops.length,
        repeats: ops.filter(
          e => e.repeat && e.repeat.toLowerCase().includes("yes")
        ).length,
        revisions: ops.filter(
          e =>
            e.primaryOrRevision &&
            e.primaryOrRevision.toLowerCase().includes("revision")
        ).length
      });
    }
    let items = [];
    for (let row of rows.sort((a, b) => a.count - b.count).reverse()) {
      let keys = row.keys;
      items.push(
        <tr key={generateId()}>
          <td key={generateId()} onClick={() => this.filter(row.title, keys)}>
            {row.title}
          </td>
          <td key={generateId()}>{row.count}</td>
          <td key={generateId()}>{row.repeats > 0 ? row.repeats : ""}</td>
          <td key={generateId()}>{row.revisions > 0 ? row.revisions : ""}</td>
          <td hidden key={generateId()}>
            {row.keys.join(", ")}
          </td>
          <td className="add-episodes">
            <SelectionSwitcher
              selectIcon={"branch"}
              deselectIcon={"branch"}
              selection={() => {
                this.getEpisodeIds(row.title);
              }}
            />
          </td>
        </tr>
      );
    }
    return items;
  };

  renderOperations = () => {
    // console.log('rendering')
    // console.log(this.state.filteredOperationItems)
    let res = this.state.filteredOperationItems.map(x => {
      let gender = x.gender === "M" ? "Male" : "Female";
      let age = moment().diff(moment(x.dob), "years");
      return (
        <div
          onMouseOver={() => this.mouseOver(x.externalId)}
          style={
            this.state.highlightEpisode === x.externalId
              ? { borderColor: "Green" }
              : {}
          }
          key={generateId()}
          className="op-card"
        >
          <ul>
            <li>
              <h3
                style={
                  this.state.highlightEpisode === x.externalId
                    ? { color: "Green" }
                    : {}
                }
              >
                {age} year old {gender}
              </h3>
            </li>
            <li>
              Title: {x.title} {x.additionalProcedures}
            </li>
            <li>Date: {moment(x.date).format("MMM Do YY")}</li>
            <li>Hospital: {x.hospital}</li>
            <li>Repeat Surgery: {x.repeat}</li>
            <li>Operation Type: {x.primaryOrRevision}</li>
            <li>Side: {x.side}</li>
            <li>Complications: {x.complications}</li>
          </ul>
        </div>
      );
    });
    return res;
  };

  getEpisodeIds = label => {
    let episodeIdList = this.props.results
      .filter(x => x.operations !== null && x)
      .filter(x => x.operations.operationItems.length > 0 && x)
      .map(x => {
        return {
          externalId: x.externalId,
          operationItems: [...x.operations.operationItems.map(y => y.title)]
        };
      })
      .filter(x => {
        return x.operationItems.includes(label) && x;
      })
      .map(x => x.externalId);

    this.props.addToTempGroup(episodeIdList);
  };

  render() {
    return (
      <div className="operations grid-box">
        <h2>Operations</h2>
        <h4 onClick={() => this.filter("All", this.state.multiOpPatients.keys)}>
          Multi Op {this.state.multiOpPatients.count}
        </h4>
        <h4 onClick={() => this.filter("All", this.state.nonOpPatients.keys)}>
          No Op {this.state.nonOpPatients.count}
        </h4>
        <div>
          <table className="table-container">
            <tbody>
              <tr className="table-headings">
                <td>Operation Title</td>
                <td>Count</td>
                <td>Repeat Procedures</td>
                <td>Revisions</td>
                <td>select</td>
              </tr>
              {this.renderGroupView()}
            </tbody>
          </table>
        </div>
        <br />
        <hr />
        <br />
        <div />
        <div className="ops-cards-container">{this.renderOperations()}</div>
      </div>
    );
  }
}

Operations.propTypes = {
  results: PropTypes.array.isRequired,
  addToTempGroup: PropTypes.func.isRequired
};

export default Operations;
