import React, { Component } from "react";
import PropTypes from "prop-types";
import { generateId } from "../../lib/utils"; // deCamelise,
import * as ss from "simple-statistics";
import BarChart from "../Charts/BarChart";

class Visits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visitData: [
        {
          data: [],
          label: "Follow Ups"
        },
        {
          data: [],
          label: "Post Ops"
        }
      ],
      visitLabels: [],
      visitTypes: [{ angle: 0, name: "post op", className: "visit-types" }],
      barOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        },
        legend: { display: true }
      }
    };
  }

  componentDidMount() {
    // this.createVisitTypes();
    // this.renderVisitItemsTable();
    this.readVisitsData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.results !== this.props.results) {
      this.readVisitsData();
    }
  }

  readVisitsData = () => {
    const loop = n => f => {
      let iter = i => {
        if (i === n) return;
        f(i);
        iter(i + 1);
      };
      return iter(0);
    };

    let stats = {
      followUps: {
        mean: 0,
        mode: 0,
        stDev: 0,
        episodesByCount: []
      },
      postOps: {
        mean: 0,
        mode: 0,
        stDev: 0,
        episodesByCount: []
      }
    };

    let data = this.props.results.filter(x => x.visits).map(x => x.visits);

    // Organise episodes by count of follow ups (retaining episode ids)
    // and add stats for group as a whole
    let fMax = ss.max(data.map(e => e.followUps));
    loop(fMax + 1)(i =>
      stats.followUps.episodesByCount.push({
        count: i,
        items: data.filter(e => e.followUps === i)
      })
    );
    stats.followUps.mean = ss.mean(data.map(e => e.followUps));
    stats.followUps.mode = ss.mode(data.map(e => e.followUps));
    stats.followUps.stDev = ss.standardDeviation(data.map(e => e.followUps));

    // Organise episodes by count of post ops (retaining episode ids)
    // and add stats for group as a whole
    let pMax = ss.max(data.map(e => e.postOp));
    loop(pMax + 1)(i =>
      stats.postOps.episodesByCount.push({
        count: i,
        items: data.filter(e => e.postOp === i)
      })
    );
    stats.postOps.mean = ss.mean(data.map(e => e.postOp));
    stats.postOps.mode = ss.mode(data.map(e => e.postOp));
    stats.postOps.stDev = ss.standardDeviation(data.map(e => e.postOp));

    let counts = Array.from(
      new Set(
        [
          ...stats.followUps.episodesByCount.map(x => x.count),
          ...stats.postOps.episodesByCount.map(x => x.count)
        ]
      )
    ).sort();

    let followUps = [];
    let postOps = [];
    for(let c of counts){
      let f = stats.followUps.episodesByCount.filter(x => x.count === c);
      f[0] ? followUps.push(f[0].items.length) : followUps.push(0);

      let p = stats.postOps.episodesByCount.filter(x => x.count === c);
      p[0] ? postOps.push(p[0].items.length) : postOps.push(0);
    }
    let visitData = [
      {
        data: followUps,
        label: "Follow Ups",
        backgroundColor: "rgba(34, 220, 198, 1)"
      },
      {
        data: postOps,
        label: "Post Ops",
        backgroundColor: "rgba(254, 0, 117, 1)"
      }
    ]
    // set the state
    this.setState({ stats: stats, visitLabels: counts, visitData: visitData});
  };

  
  rendersVisitsData = () => {
    let visitsLegend = [];
    this.state.visitTypes.map(x => {
      visitsLegend.push(
        <li key={generateId()}>
          <span className="name">{x.name}:</span>
          <span className="angle">{x.angle}</span>
        </li>
      );
    });
    return visitsLegend;
  };

  render() {
    return (
      <div className="visits grid-box">
        <div className="visit-header">
          <h2>Visits</h2>
        </div>
        <div className="separator-line-muted" />
        <div className="inner">
          <BarChart
            dataSet={this.state.visitData}
            labels={this.state.visitLabels}
            type={"bar"}
            refName={"ageRange"}
            options={this.state.barOptions}
            defaultStyles={false}
            // connectors={connectors}
            // responsive={false}
            height={150}
          />
        </div>
      </div>
    );
  }
}

Visits.propTypes = {
  results: PropTypes.array.isRequired
};

export default Visits;
