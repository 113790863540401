import React from "react";
import PropTypes from "prop-types";

class TextareaField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labelActive: false
    };
  }

  // Checkes if Input has focus and sets/removes labelActive state
  toggleLabelActive = () => {
    if (this.state.labelActive === true) {
      this.setState({ labelActive: false });
    } else {
      this.setState({ labelActive: true });
    }
  };

  // RenderFunction to allow different input Style for password inputs
  renderText = () => {
    return (
      <textarea
        type={this.props.type}
        placeholder={this.props.placeholder}
        name={this.props.name}
        onChange={this.props.onChangeFunc}
        className="text-input"
        value={this.props.value || ""}
        onFocus={this.toggleLabelActive}
        onBlur={this.toggleLabelActive}
        disabled={this.props.disabled}
      />
    );
  };

  render() {
    return (
      <div
        className={
          this.props.isValid !== null
            ? this.props.isValid
              ? `input-holder textarea-holder ${this.props.name}-holder valid`
              : `input-holder textarea-holder ${this.props.name}-holder invalid`
            : `input-holder textarea-holder ${this.props.name}-holder`
        }
      >
        <label
          htmlFor={`${this.props.name}`}
          className={this.state.labelActive ? "label-active" : ""}
        >
          {this.props.label}
        </label>
        {this.renderText()}
      </div>
    );
  }
}

TextareaField.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeFunc: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default TextareaField;
