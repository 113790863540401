import React, { Component } from "react";
import PropTypes from "prop-types";

class GroupsCompareList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderGroupNames = () => {
    let groupNames = [];
    this.props.compareGroupsNames.map((x, i) => {
      groupNames.push(
        <li className="groups-compare-names-list-item" key={x + i}>
          <span className="groups-compare-names-list-item-title">{x}</span>
        </li>
      );
    });

    return groupNames;
  };

  render() {
    return (
      <div className="groups-compare-list">
        <h3 className="groups-compare-list-title">groups to compare</h3>
        <ul className="groups-compare-name-list">{this.renderGroupNames()}</ul>
        <button
          className="compare-groups-list-button"
          onClick={this.props.showCompareGroups}
        >
          Get groups
        </button>
      </div>
    );
  }
}

GroupsCompareList.propTypes = {
  compareGroupsNames: PropTypes.array.isRequired,
  showCompareGroups: PropTypes.func.isRequired
  // removeCompareGroup: PropTypes.func.isRequired
};

export default GroupsCompareList;
