import React, { Component } from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";
import { withTheme } from "@material-ui/core/styles";

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      defaultColors: {
        backgroundColor: this.props.theme.palette.chartBgColors,
        borderColor: this.props.theme.palette.chartBorderColors,
        borderWidth: 1
      },
      legend: {
        labels: {
          fontColor: this.props.theme.typography.color
        }
      }
    };
    // Ref used to get node
    this["pieChartRef" + this.props.refName] = React.createRef();
  }

  componentDidMount() {
    const node = this["pieChartRef" + this.props.refName].current;
    this.chart = this.renderPieChart(node);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataSet !== this.props.dataSet) {
      this.updateChart(
        this.props.dataSet,
        this.props.options,
        this.props.labels
      );
    }

    if (this.props.connectors !== undefined) {
      if (prevProps.connectors !== this.props.connectors) {
        this.connectedChartsToggle(this.props.connectors.index);
      }
    }

    if (
      prevProps.theme.palette.chartBgColors !==
      this.props.theme.palette.chartBgColors
    ) {
      this.setState(
        {
          defaultColors: {
            backgroundColor: this.props.theme.palette.chartBgColors,
            borderColor: this.props.theme.palette.chartBorderColors,
            borderWidth: 1
          },
          legend: {
            labels: {
              fontColor: this.props.theme.typography.color
            }
          }
        },
        () => {
          this.updateChart(
            this.props.dataSet,
            this.props.options,
            this.props.labels
          );
        }
      );
    }
  }

  connectedChartsToggle = labelIndex => {
    let index = labelIndex;
    let ci = this.chart;

    let meta = ci.getDatasetMeta(0);
    let arcPiece = meta.data[index];

    arcPiece.hidden = !arcPiece.hidden ? true : false;

    ci.update();
  };
  // Updates charts with new data and options
  updateChart = (newData, options, labels) => {
    this.chart.data.datasets = [];
    // Adds color defaults if none set
    let data = newData.map(x => {
      if (this.props.defaultStyles) {
        return (x = { ...x, ...this.state.defaultColors });
      } else {
        return x;
      }
    });

    let muiOptions = {
      ...options,
      legend: {
        ...this.state.legend,
        ...(options.legend !== undefined && options.legend)
      }
    };

    this.chart.options = muiOptions;
    this.chart.data.datasets = data;
    this.chart.data.labels = labels;
    this.chart.update();
  };

  // Creates first instantiation of chart
  renderPieChart = ctx => {
    // Adds color defaults if none set
    let data = this.props.dataSet.map(x => {
      if (this.props.defaultStyles) {
        return (x = { ...x, ...this.state.defaultColors });
      } else {
        return x;
      }
    });

    let muiOptions = {
      ...this.props.options,
      legend: {
        ...this.state.legend,
        ...(this.props.options !== undefined && this.props.options)
      }
    };

    return new Chart(ctx, {
      type: this.props.type, // either "pie" or "doughnut"
      data: {
        labels: this.props.labels,
        datasets: [...data]
      },
      // Adds options
      options: muiOptions
    });
  };

  render() {
    return (
      <div className={`pie-chart chart`}>
        <canvas
          className="chart-canvas pie-chart-canvas"
          ref={this["pieChartRef" + this.props.refName]}
          width={this.props.width ? `${this.props.width}px` : "400px"}
          height={this.props.height ? `${this.props.height}px` : "400px"}
          id={this.props.refName}
        />
      </div>
    );
  }
}

PieChart.propTypes = {
  dataSet: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  type: PropTypes.string,
  labels: PropTypes.array.isRequired,
  refName: PropTypes.string.isRequired,
  options: PropTypes.object,
  defaultStyles: PropTypes.bool.isRequired,
  connectors: PropTypes.shape({
    index: PropTypes.number
  }),
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      chartBgColors: PropTypes.array.isRequired,
      chartBorderColors: PropTypes.array.isRequired
    }),
    typography: PropTypes.shape({
      color: PropTypes.string.isRequired
    })
  })
};

export default withTheme()(PieChart);
