import React from "react";
import PropTypes from "prop-types";

const EpisodesGroupTable = props => {
  function renderPathwayTypes() {
    return props.groupQuery[0].pathwayTypes.map(x => <li key={x}>{x}</li>);
  }

  function renderScoresKeys() {
    return props.groupQuery[0].scoreTypes.map((x, i) => (
      <li key={x + i}>{x.key}</li>
    ));
  }

  return (
    <div className="group-table">
      <h3>Group Details</h3>
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Episode Count</th>
            <th>Pathway Types</th>
            <th>Score Types</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.groupQuery[0].title}</td>
            <td>{props.groupQuery[0].episodeCount}</td>
            <td>
              <ul>{renderPathwayTypes()}</ul>
            </td>
            <td>
              <ul>{renderScoresKeys()}</ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

EpisodesGroupTable.propTypes = {
  groupQuery: PropTypes.array.isRequired
};

export default EpisodesGroupTable;
