import React, { Component } from "react";
import PropTypes from "prop-types";

class EpisodesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableFilters: this.props.columnFilters
    };
  }

  listAvailableFilters = () => {
    let available = this.state.availableFilters;
    let results = [];
    available.map(item => {
      results.push(
        <label key={item}>
          <input
            type="checkbox"
            value={item}
            defaultChecked={false}
            onClick={this.props.toggleTableColumns}
          />
          <span>{item}</span>
        </label>
      );
    });
    return results;
  };

  render() {
    return (
      <div className="episode-filters filters-container">
        <div className="filters-title">
          <h3>Episodes Filters</h3>
        </div>
        <div className="filters-categories">
          <div className="filters-columns">
            <h4>Column</h4>
            <ul>{this.listAvailableFilters()}</ul>
          </div>
          <div className="filters-gender">
            <h4>Gender</h4>
            <input
              type="radio"
              value="both"
              name="gender"
              onClick={this.props.toggleGenderFilter}
            />
            <span>Both</span>
            <br />
            <input
              type="radio"
              value="F"
              name="gender"
              onClick={this.props.toggleGenderFilter}
            />
            <span>Female</span>
            <br />
            <input
              type="radio"
              value="M"
              onClick={this.props.toggleGenderFilter}
              name="gender"
            />
            <span>Male</span>
          </div>
        </div>
      </div>
    );
  }
}

EpisodesFilter.propTypes = {
  toggleGenderFilter: PropTypes.func.isRequired,
  toggleTableColumns: PropTypes.func.isRequired,
  columnFilters: PropTypes.array.isRequired
};

export default EpisodesFilter;
