import React, { Component } from "react";
import PropTypes from "prop-types";
import dateFns from "date-fns";
import ChartSvgIcons from "../Misc/ChartSvgIcons";
import TableSorter from "../TableSorter/TableSorter";
import SelectionSwitcher from "../SelectionSwitcher/SelectionSwitcher";

class EpisodesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultEpisodes: [],
      sortedEpisodes: [],
      tableHeader: {
        externalId: "",
        type: "",
        startDate: "",
        patient: {
          gender: "",
          key: "",
          dob: ""
        },
        diagnoses: {
          primaryBilateral: "",
          primaryLeft: "",
          primaryRight: "",
          secondaryBilateral: "",
          secondaryLeft: "",
          secondaryRight: "",
          sides: ""
        },
        scores: "",
        timeline: ""
      },
      filters: [...this.props.columnFilters, ...this.props.dataFilters]
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.episodes !== this.props.episodes) {
      this.setState({
        defaultEpisodes: this.props.episodes,
        sortedEpisodes: this.props.episodes
      });
    }

    if (prevProps.genderFilter !== this.props.genderFilter) {
      this.genderfiltered(this.props.genderFilter);
    }
  }

  // Filters results by gender
  genderfiltered = filter => {
    if (filter === "both") {
      this.setState({ sortedEpisodes: this.state.defaultEpisodes });
    } else {
      let filtered = this.state.defaultEpisodes.filter(
        x => x.patient.gender === filter
      );

      this.setState({ sortedEpisodes: filtered });
    }
  };

  // Sorter func
  sorter = (node, direction) => {
    let sorted = this.state.sortedEpisodes;
    switch (node) {
      case "externalId":
        sorted.sort((a, b) => {
          if (a[node] < b[node]) return -1;
          if (a[node] > b[node]) return 1;
          return 0;
        });
        break;
      case "type":
        sorted.sort((a, b) => {
          if (a[node] < b[node]) return -1;
          if (a[node] > b[node]) return 1;
          return 0;
        });
        break;
      case "startDate":
        sorted.sort((a, b) => {
          if (a[node] < b[node]) return -1;
          if (a[node] > b[node]) return 1;
          return 0;
        });
        break;
      case "scores":
        sorted.sort((a, b) => {
          let scoreSetA = a[node]?.scoreSet?.length > 0 ? true : false;
          let scoreSetB = b[node]?.scoreSet?.length > 0 ? true : false;
          return scoreSetB - scoreSetA;
        });
        break;

      case "timeline":
        sorted.sort((a, b) => {
          let complicationCheckA =
            a.complications?.complicationItems?.length > 0 ? true : false;
          let diagnosesCheckA =
            a.diagnoses?.diagnosisItems?.length > 0 ? true : false;
          let operationCheckA =
            a.operations?.operationItems?.length > 0 ? true : false;
          let planCheckA = a.plans?.planItems?.length > 0 ? true : false;
          let visitCheckA = a.visits?.visitItems?.length > 0 ? true : false;

          let timelineA =
            !complicationCheckA &&
            !diagnosesCheckA &&
            !operationCheckA &&
            !planCheckA &&
            !visitCheckA
              ? false
              : true;

          let complicationCheckB =
            b.complications?.complicationItems?.length > 0 ? true : false;
          let diagnosesCheckB =
            b.diagnoses?.diagnosisItems?.length > 0 ? true : false;
          let operationCheckB =
            b.operations?.operationItems?.length > 0 ? true : false;
          let planCheckB = b.plans?.planItems?.length > 0 ? true : false;
          let visitCheckB = b.visits?.visitItems?.length > 0 ? true : false;

          let timelineB =
            !complicationCheckB &&
            !diagnosesCheckB &&
            !operationCheckB &&
            !planCheckB &&
            !visitCheckB
              ? false
              : true;

          return timelineB - timelineA;
        });
        break;

      default:
        sorted = this.state.defaultEpisodes;
    }

    this.setState({
      sortedEpisodes: direction === "des" ? sorted.reverse() : sorted
    });
  };

  // Render top level header
  renderTableHeader = () => {
    let tableHeader = [];
    Object.keys(this.state.tableHeader).map((x, i) => {
      // Filters columns
      if (!this.props.columnFilters.includes(x)) {
        // Checks if obj
        if (this.state.tableHeader[x].length === undefined) {
          tableHeader.push(
            <th
              key={x + i}
              colSpan={Object.keys(this.state.tableHeader[x]).length}
            >
              {x}
            </th>
          );
        } else {
          tableHeader.push(
            <th key={x + i}>
              <div className="table-header-holder">
                {x}
                <TableSorter toggleFunc={this.sorter} node={x} />
              </div>
            </th>
          );
        }
      }
    });
    return tableHeader;
  };

  // Render Sub header
  renderTableSubHeader = () => {
    let tableSubHeader = [];
    Object.keys(this.state.tableHeader).map((x, i) => {
      // Filters columns
      if (!this.props.columnFilters.includes(x)) {
        // Checks if obj
        if (this.state.tableHeader[x].length !== undefined) {
          tableSubHeader.push(<th key={x + i} />);
        } else {
          Object.keys(this.state.tableHeader[x]).map((y, i) => {
            tableSubHeader.push(<th key={y + i}>{y}</th>);
          });
        }
      }
    });
    return tableSubHeader;
  };

  // Renders rows
  renderTableResults = () => {
    let tableResults = [];
    this.state.sortedEpisodes.map((x, i) => {
      tableResults.push(
        <tr key={x.externalId}>
          {this.renderTableCells(x)}
          {!this.props.columnFilters.includes("timeline") && (
            <td>{this.renderTimelineResult(x)}</td>
          )}
          <td>
            <SelectionSwitcher
              selectIcon={"branch"}
              deselectIcon={"branch"}
              selection={() => {
                this.props.getTheRow(x);
              }}
            />
          </td>
          <td>
            <SelectionSwitcher
              selectIcon={"sendTo"}
              deselectIcon={"sendTo"}
              selection={() => {
                this.props.getEpisodeId(x.externalId);
              }}
              deselection={() => {}}
            />
          </td>
        </tr>
      );
    });
    return tableResults;
  };

  // Takes each row and creates td's from obj
  renderTableCells = row => {
    let tableRow = [];
    // results to filter out
    let filters = [...this.props.columnFilters, ...this.props.dataFilters];

    // Cleans obj null values
    let cleaned = Object.keys(row).reduce((obj, val) => {
      obj[val] = row[val] !== null ? row[val] : "empty";
      return obj;
    }, {});

    // Maps cleaned obj
    Object.keys(cleaned).map((x, i) => {
      if (!filters.includes(x)) {
        if (cleaned[x].length !== undefined && cleaned[x] !== "empty") {
          tableRow.push(
            <td key={x + i}>
              {x === "startDate"
                ? dateFns.format(cleaned[x], "DD-MM-YYYY")
                : cleaned[x]}
            </td>
          );
        } else {
          tableRow.push(this.renderNested(x, cleaned[x], filters));
        }
      }
    });
    return tableRow;
  };

  // Renders nested cells
  renderNested = (key, obj, filters) => {
    let nestedResults = [];
    switch (key) {
      case "scores":
        if (obj !== "empty") {
          nestedResults.push(
            <td key={key + obj}>{this.renderScoreSet(obj)}</td>
          );
        } else {
          nestedResults.push(
            <td key={key + obj}>
              <div className="scores-icons">
                <ChartSvgIcons icon="noscores" />
              </div>
            </td>
          );
        }
        break;
      case "patient":
        Object.keys(obj).map((x, i) => {
          if (!filters.includes(x)) {
            nestedResults.push(
              <td key={x + i}>
                {x === "dob" ? dateFns.format(obj[x], "DD-MM-YYYY") : obj[x]}
              </td>
            );
          }
        });
        break;
      case "diagnoses":
        nestedResults.push(this.renderDiagnoses(obj, key, filters));
        break;
      default:
        break;
    }

    return nestedResults;
  };

  // Renders diagnoses cells removes diagnosisItems
  renderDiagnoses = (diagnoses, key, filters) => {
    let diagnosesResults = [];
    if (diagnoses !== "empty") {
      Object.keys(diagnoses).map((x, i) => {
        if (!filters.includes(x)) {
          if (x !== "diagnosisItems") {
            diagnosesResults.push(<td key={x + i}>{diagnoses[x]}</td>);
          }
        }
      });
    } else {
      Object.keys(this.state.tableHeader[key]).map((x, i) => {
        if (!filters.includes(x)) {
          diagnosesResults.push(<td key={x + i} />);
        }
      });
    }
    return diagnosesResults;
  };

  // Renders Scores icons
  renderScoreSet = scores => {
    if (scores.scoreSet.length > 0) {
      return (
        <div className="scores-icons">
          <ChartSvgIcons icon="lineChart" />
        </div>
      );
    } else {
      return (
        <div className="scores-icons">
          <ChartSvgIcons icon="noscores" />
        </div>
      );
    }
  };

  // Checks to see if timeline exists
  renderTimelineResult = row => {
    let cleaned = Object.keys(row).reduce((obj, val) => {
      obj[val] = row[val] !== null ? row[val] : "empty";
      return obj;
    }, {});

    let complicationCheck =
      cleaned.complications?.complicationItems?.length > 0 ? true : false;
    let diagnosesCheck =
      cleaned.diagnoses?.diagnosisItems?.length > 0 ? true : false;
    let operationCheck =
      cleaned.operations?.operationItems?.length > 0 ? true : false;
    let planCheck = cleaned.plans?.planItems?.length > 0 ? true : false;
    let visitCheck = cleaned.visits?.visitItems?.length > 0 ? true : false;

    if (
      !complicationCheck &&
      !diagnosesCheck &&
      !operationCheck &&
      !planCheck &&
      !visitCheck
    ) {
      return (
        <div className="scores-icons">
          <ChartSvgIcons icon="noscores" />
        </div>
      );
    } else {
      return (
        <div className="scores-icons">
          <ChartSvgIcons icon="timeline" />
        </div>
      );
    }
  };

  render() {
    return (
      <div className="table-holder">
        <h3 className="episodes-table-header">Episodes Table</h3>
        <table>
          <tbody>
            <tr>
              {this.renderTableHeader()}
              <th>actions</th>
              <th>details</th>
            </tr>
            <tr>
              {this.renderTableSubHeader()}
              <th />
              <th />
            </tr>
            {this.renderTableResults()}
          </tbody>
        </table>
      </div>
    );
  }
}

EpisodesTable.propTypes = {
  episodes: PropTypes.array.isRequired,
  columnFilters: PropTypes.array.isRequired,
  dataFilters: PropTypes.array.isRequired,
  getEpisodeId: PropTypes.func.isRequired,
  getTheRow: PropTypes.func.isRequired,
  genderFilter: PropTypes.string.isRequired
};

export default EpisodesTable;
