export const baseThemeLight = {
  typography: {
    useNextVariants: true,
    fontFamily: ["Avenir Light", "arial", "sans-serif"].join(","),
    color: "#fff"
  },
  palette: {
    type: "light",
    primary: {
      light: "#655581",
      main: "#22222c",
      dark: "#14022b",
      contrastText: "#ffffff"
    },
    secondary: {
      light: "#69ddff",
      main: "#00acff",
      dark: "#007dcb",
      contrastText: "#ffffff"
    },
    tertiary: {
      light: "#68fffb",
      main: "#00e4c8",
      dark: "#00b197",
      contrastText: "#ffffff"
    },
    quaternary: {
      light: "#a9fbfb",
      main: "#76c8c8",
      dark: "#439797",
      contrastText: "#ffffff"
    },
    header: {
      main: "#ffffff",
      contrastText: "#3a2c54",
      dropdown: "#3a2c54",
      logo: "#fe0075"
    },
    footer: {
      main: "#3a2c54",
      contrastText: "#ffffff"
    },
    logo: {
      main: "#3a2c54",
      secondary: "#a2adac"
    },
    chartBgColors: [
      "rgb(254, 0, 117, 1)",
      "rgb(51, 219, 198, 1)",
      "rgb(101, 46, 199, 1)",
      "rgb(18, 202, 145, 1)",
      "rgb(253, 242, 0, 1)",
      "rgb(246, 80, 88,1)",
      "rgb(117, 212, 253,1)",
      "rgb(0, 31, 253,1)"
    ],
    chartBorderColors: [
      "rgb(254, 0, 117, 0.5)",
      "rgb(51, 219, 198, 0.5)",
      "rgb(101, 46, 199, 0.5)",
      "rgb(18, 202, 145, 0.5)",
      "rgb(253, 242, 0, 0.5)",
      "rgb(246, 80, 88,0.5)",
      "rgb(117, 212, 253,0.5)",
      "rgb(0, 31, 253,0.5)"
    ],
    chartPointBackgroundColors: [
      "rgba(51, 219, 198,1)",
      "rgba(254, 0, 117,1)",
      "rgba(253, 225, 1,1)",
      "rgba(152, 139, 200,1)",
      "rgba(254, 135, 77,1)",
      "rgba(129, 66, 253,1)",
      "rgba(231, 207, 205,1)",
      "rgba(26,140,255, 1)"
    ],
    chartPointBorderColor: "#ffffff"
  }
};

export const baseThemeDark = {
  typography: {
    useNextVariants: true,
    fontFamily: ["Avenir Light", "arial", "sans-serif"].join(","),
    color: "#ffffff"
  },
  palette: {
    type: "dark",
    primary: {
      light: "#655581",
      main: "#00e6c8",
      dark: "#14022b",
      contrastText: "#3a2b55"
    },
    secondary: {
      light: "#69ddff",
      main: "#00acff",
      dark: "#007dcb",
      contrastText: "#ffffff"
    },
    tertiary: {
      light: "#68fffb",
      main: "#00e4c8",
      dark: "#00b197",
      contrastText: "#ffffff"
    },
    quaternary: {
      light: "#a9fbfb",
      main: "#76c8c8",
      dark: "#439797",
      contrastText: "#ffffff"
    },
    header: {
      main: "#424242",
      contrastText: "#ffffff",
      dropdown: "#303030"
    },
    footer: {
      main: "#303030",
      contrastText: "#ffffff"
    },
    logo: {
      main: "#ffffff",
      secondary: "#a2adac"
    },
    chartBgColors: [
      "rgb(51, 219, 198, 1)",
      "rgb(254, 0, 117, 1)",
      "rgb(101, 46, 199, 1)",
      "rgb(18, 202, 145, 1)",
      "rgb(253, 242, 0, 1)",
      "rgb(246, 80, 88,1)",
      "rgb(117, 212, 253,1)",
      "rgb(0, 31, 253,1)"
    ],
    chartBorderColors: [
      "rgb(254, 0, 117, 0.5)",
      "rgb(51, 219, 198, 0.5)",
      "rgb(101, 46, 199, 0.5)",
      "rgb(18, 202, 145, 0.5)",
      "rgb(253, 242, 0, 0.5)",
      "rgb(246, 80, 88,0.5)",
      "rgb(117, 212, 253,0.5)",
      "rgb(0, 31, 253,0.5)"
    ],
    chartPointBackgroundColors: [
      "rgba(51, 219, 198,1)",
      "rgba(254, 0, 117,1)",
      "rgba(253, 225, 1,1)",
      "rgba(152, 139, 200,1)",
      "rgba(254, 135, 77,1)",
      "rgba(129, 66, 253,1)",
      "rgba(231, 207, 205,1)",
      "rgba(26,140,255, 1)"
    ]
  }
};
