import React, { Component } from "react";
import PropTypes from "prop-types";
import { generateId } from "../../lib/utils";
import SelectionSwitcher from "../SelectionSwitcher/SelectionSwitcher";

class Complications extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderComplicationsItems = () => {
    // CREATE AN EMPTY ARRAY TO HOLD TABLE ROWS FOR RENDERING
    let complicationsItems = [];
    // GET A FLAT ARRAY OF COMPLICATION OBJECTS
    let items = this.props.results
      .filter(x => x.complications !== null && x)
      .filter(x => x.complications.complicationItems.length > 0 && x)
      .map(x => {
        return [...x.complications.complicationItems.map(y => y)];
      })
      .reduce((acc, val) => acc.concat(val), []);

    // REDUCE TO AN OBJECT WITH COUNTS FOR EACH COMPLICATION
    // E.G. { "SWELLING": 10, "INFECTION": 5 }
    let accumulatedTotals = items.reduce(
      (totals, d) => ({
        ...totals,
        [d.title]: (totals[d.title] || 0) + 1
      }),
      {}
    );

    // CONVERT BACK TO AN ARRAY FOR EASIER SORTING
    // E.G. [["SWELLING",10],["INFECTION",5]]
    let sortable = [];
    for (var complication in accumulatedTotals) {
      sortable.push([complication, accumulatedTotals[complication]]);
    }
    // SORT ASCENDING
    sortable.sort(function(a, b) {
      return a[1] - b[1];
    });
    // REVERSE SO MOST COMMON IS FIRST & MAP OUT THE TABLE ROW
    sortable.reverse().map(x => {
      complicationsItems.push(
        <tr className="table-results-row" key={generateId()}>
          <td>{x[0]}</td>
          <td>{x[1]}</td>
          <td className="add-episodes">
            <SelectionSwitcher
              selectIcon={"branch"}
              deselectIcon={"branch"}
              selection={() => {
                this.getEpisodeIds(x[0]);
              }}
            />
          </td>
        </tr>
      );
    });
    return complicationsItems;
  };

  // Creates temp group based
  getEpisodeIds = label => {
    // FILTER THE PASSED IN _PROPS_ DATASET TO LEAVE ONLY
    // EPISODES THAT CONTAIN A COMPLICATION MATCHING *label*
    // AND MAP THE EPISODES' EXTERNAL ID
    let episodeIdList = this.props.results
      .filter(x => x.complications !== null && x)
      .filter(x => x.complications.complicationItems.length > 0 && x)
      .map(x => {
        return {
          externalId: x.externalId,
          complicationItems: [
            ...x.complications.complicationItems.map(y => y.title)
          ]
        };
      })
      .filter(x => {
        return x.complicationItems.includes(label) && x;
      })
      .map(x => x.externalId);

    this.props.addToTempGroup(episodeIdList);
  };

  render() {
    return (
      <div className="complications grid-box">
        <h2>Complications</h2>
        <div className="inner">
          <div className="sides" />
          <div className="complications-table">
            <table className="table-container">
              <tbody>
                <tr className="table-headings">
                  <td>Complication</td>
                  <td>Count</td>
                  <td>Select</td>
                </tr>
                {this.renderComplicationsItems()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

Complications.propTypes = {
  results: PropTypes.array.isRequired,
  addToTempGroup: PropTypes.func.isRequired
};

export default Complications;
