import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

class Diagnosis extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = this.props.data;
    return (
      <div className="diagnosis-timeline-item">
        <h3 className="timeline-item-title">
          <span className="pre-title">Diagnosis:</span> {data.condition}
        </h3>
        {data.condition !== data.diagnosis ? (
          <span className="lede">Detail: {data.diagnosis}</span>
        ) : (
          false
        )}
        {data.side ? <p>({data.side})</p> : false}
        {data.location ? <p>Location {data.location}</p> : false}
        {data.degree ? <p>Location {data.degree}</p> : false}
        {data.status ? <p>Status: {data.status}</p> : false}
        {data.ordinal ? <p>{data.ordinal} Diagnosis</p> : false}
        {data.date ? (
          <h4 className="content-date">
            {moment(data.date).format("dddd, MMMM Do YYYY")}
          </h4>
        ) : null}
      </div>
    );
  }
}

Diagnosis.propTypes = {
  data: PropTypes.object.isRequired
};

export default Diagnosis;
