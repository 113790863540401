import React, { Component } from "react";
import FormContainer from "../../components/Forms/FormContainer";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      fields: [
        {
          domEl: "input",
          name: "username",
          type: "email",
          plaeholder: "",
          label: "username",
          iconName: "user"
        }
      ]
    };
  }

  // Submits entered details
  // TODO hook up to api
  submitResetRequest = event => {
    console.log(event);
  };

  render() {
    return (
      <div className="forgot-password">
        <FormContainer
          fields={this.state.fields} // form fields
          formName={"forgotPassword"} //form name
          formClass={"forgot-password-form"} // form class used for styling
          submitTitle={"submit"} // submit button title
          submissionFunc={this.submitResetRequest} // function used to handle retrieved data
          validation={() => {
            console.log("hhh");
          }} //validtaion passed in from external lib used to add class to field wrapper div
        />
      </div>
    );
  }
}

export default ForgotPassword;
