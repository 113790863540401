import { setContext } from "apollo-link-context";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

// Imports for local cache querying
import { ApolloLink } from "apollo-link";
import { withClientState } from "apollo-link-state";

import { onError } from "apollo-link-error";

// Var that holds token received by login request
let userToken = "";

// Function used to update userToken var
// retrieved in App container
export function retreiveToken(authToken) {
  userToken = authToken;
}

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) graphQLErrors.map(({ message }) => console.log(message));
});

//Cache function used to create local Store
// using query type name now to create local store objects
// Can be tweaked alot to create a different store than response
// You can throw a switch statement in and tweak values
// https://www.apollographql.com/docs/react/advanced/caching.html
const cache = new InMemoryCache();

//Local cache query setup
// This behaves like a graphQl endpoint but adds local cache to query option
// Both endpoint and local cache can be queried similtanously
// Can have client side resolvers and initial data values
// To query cache append @client to value to retreive from cache
// https://www.apollographql.com/docs/link/links/state.html#start

const stateLink = withClientState({
  cache,
  defaults: {}, // default cache objects
  resolvers: {} //client resolvers used to query cache
});

// Graphql endpoint uri
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI
});

// Sets header context
const authLink = setContext((_, { headers }) => {
  // get the authentication token from login reponse
  const token = userToken;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// Link query that tests cache then endpoint
const link = ApolloLink.from([errorLink, stateLink, authLink.concat(httpLink)]);

export const client = new ApolloClient({
  link,
  cache: cache
});
