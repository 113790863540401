import React, { Component } from "react";
import PropTypes from "prop-types";
import { CREATE_NEW_EPISODES_GROUP } from "../../gql/EpisodeGQL";
import { SINGLE_GROUP_EPISODES_QUERY } from "../../gql/GroupGQL";
import { client } from "../../store/configureClient";
import EpisodesGroupTable from "../../components/Episodes/EpisodesGroupTable";
import EpisodesFilter from "../../components/Episodes/EpisodesFilter";
import EpisodesTable from "../../components/Episodes/EpisodesTable";
import GroupsMaker from "../../containers/GroupsMaker/GroupsMaker";
import Loading from "../../components/Loading/Loading";
import BackLink from "../../components/BackLink/BackLink";

class Episodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: this.props.currentUser,
      results: [
        {
          title: "",
          episodeCount: null,
          pathwayTypes: [],
          scoreTypes: [],
          episodes: []
        }
      ],
      columnFilters: [
        "externalId",
        "diagnoses",
        "scores",
        "timeline",
        "patient"
      ],
      genderFilter: "",
      dataFilters: ["__typename", "owner", "_id"],
      tempGroup: [],
      loadingOverlayActive: false
    };
  }

  // Get group data query
  componentDidMount() {
    this.getGroup();
  }

  /*************************************************
   * GQL QUERIES
   **************************************************/
  getGroup = () => {
    // toggle overlay loading component
    this.toggleLoadingOverlay(true);
    client
      .query({
        query: SINGLE_GROUP_EPISODES_QUERY,
        variables: {
          _id: this.props.groupInView
        }
      })
      .then(({ data }) => {
        this.setState({
          results: [data.Group[0]]
        });
        // toggle overlay loading component
        this.toggleLoadingOverlay(false);
      })
      .catch(error => {
        this.props.renderMessages("invalid", "Couldn't get episodes", true);
        this.toggleLoadingOverlay(false);
      });
  };

  // Creates a new group based upon passed in episode ids
  //  Fired from GroupMaker
  createGroup = submission => {
    client
      .mutate({
        mutation: CREATE_NEW_EPISODES_GROUP,
        variables: {
          title: submission.newGroupMakerName,
          owner: this.props.currentUser.scoresName,
          episodeGroupIds: [
            ...this.state.tempGroup.map(x => {
              return {
                externalId: x,
                owner: this.props.currentUser.scoresName
              };
            })
          ]
        }
      })
      .then(({ data }) => {
        this.props.renderMessages("valid", "Group created", true);
        this.setState({
          tempGroup: [],
          filteredFullEpisode: [],
          episodes: this.state.episodes
        });
      })
      .catch(error => {
        this.props.renderMessages("invalid", "Couldn't create group", true);
      });
  };

  // Gets selected gender to filter table
  toggleGenderFilter = event => {
    this.setState({ genderFilter: event.target.value });
  };

  // Toggles table columns in view
  toggleTableColumns = event => {
    let columnFilters = this.state.columnFilters;
    let selection = event.target.value;
    columnFilters.includes(selection)
      ? (columnFilters = [
          ...this.state.columnFilters.filter(x => x !== selection)
        ])
      : (columnFilters = [...this.state.columnFilters, selection]);

    this.setState({ columnFilters: columnFilters });
  };

  // Gets the episode and adds to tempGroup for groupMaker
  getTheRow = row => {
    let arr = Array.from(this.state.tempGroup);
    arr.push(row);

    // dedupes episodes
    let tempGroup = [...new Set(arr)];

    this.setState({ tempGroup: tempGroup });
  };

  // Toggle loading overlay screen can be called in query to handling
  // onloading status
  toggleLoadingOverlay = bool => {
    this.setState({ loadingOverlayActive: bool });
  };

  render() {
    return (
      <div className="episodes grid-box">
        <BackLink />
        <div className="episodes-header">
          <h2>Episodes</h2>
        </div>

        <div className="group-table-row">
          <EpisodesGroupTable groupQuery={this.state.results} />
          <EpisodesFilter
            toggleGenderFilter={this.toggleGenderFilter}
            toggleTableColumns={this.toggleTableColumns}
            columnFilters={this.state.columnFilters}
          />
        </div>

        <div className="episodes-table">
          <EpisodesTable
            episodes={this.state.results[0].episodes}
            genderFilter={this.state.genderFilter}
            columnFilters={this.state.columnFilters}
            dataFilters={this.state.dataFilters}
            getEpisodeId={this.props.getEpisodeId}
            getTheRow={this.getTheRow}
          />
          {/* {this.state.results.length > 0 &&
            this.renderEpisodesTable(
              "Episodes Table",
              this.state.initialEpisodes[0][0],
              this.state.episodes[0],
              "add",
              "mainTable"
            )} */}
        </div>

        {/* selected episodes and group maker ... */}
        {/* {this.state.filteredFullEpisode.length > 0 &&
          this.renderEpisodesTable(
            "Selected Item Table",
            this.state.initialEpisodes[0][0],
            this.state.filteredFullEpisode,
            "remove"
          )} */}
        {this.state.tempGroup.length > 0 && (
          <GroupsMaker
            tempGroup={this.state.tempGroup}
            createGroup={this.createGroup}
            groupSubheading="After selecting a number of episodes from the main table,
            a group can be created with the title added below."
          />
        )}
        <Loading loadingOverlayActive={this.state.loadingOverlayActive} />
      </div>
    );
  }
}

Episodes.propTypes = {
  renderMessages: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  getEpisodeId: PropTypes.func.isRequired,
  groupInView: PropTypes.string.isRequired
};

export default Episodes;
