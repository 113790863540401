import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

class AuthRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          this.props.isAuthed ? (
            <Component {...props} {...rest} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    );
  }
}

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthed: PropTypes.bool.isRequired
};

export default AuthRoute;
