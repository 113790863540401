import React, { Component } from "react";
import PropTypes from "prop-types";
// import Episodes from "../../components/Episodes/Episodes";
import { Switch, Route } from "react-router-dom";
import EpisodeDetails from "../EpisodeDetails/EpisodeDetails";
import { withRouter } from "react-router";

import Episodes from "../Episodes/Episodes";

class Patients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      episodeId: ""
    };
  }

  // Gets selected episode id from episodes table
  getEpisodeId = episodeId => {
    this.setState(
      { episodeId: episodeId },
      this.props.history.push(`/dashboard/patients/${episodeId}`)
    );
  };

  render() {
    const { currentUser, episodeId } = this.state;
    return (
      <div className="patients">
        <Switch>
          <Route
            exact
            path={"/dashboard/patients"}
            render={props => (
              <Episodes
                renderMessages={this.props.renderMessages}
                currentUser={currentUser}
                getEpisodeId={this.getEpisodeId}
                groupInView={this.props.groupInView}
              />
            )}
          />
          <Route
            path={`/dashboard/patients/:id`}
            render={props => (
              <EpisodeDetails
                {...props}
                episodeId={episodeId}
                renderMessages={this.props.renderMessages}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

Patients.propTypes = {
  currentUser: PropTypes.object.isRequired,
  renderMessages: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  groupInView: PropTypes.string.isRequired
};

export default withRouter(Patients);
