import React, { Component } from "react";
import PropTypes from "prop-types";
import { GROUP_SCORES } from "../../gql/GroupGQL";
import { client } from "../../store/configureClient";
import { generateId } from "../../lib/utils";
import ScoresLine from "../Charts/LineChart";
import ChartViewSwitcher from "../Charts/ChartViewSwitcher";
// import moment from "moment";

// import ChartSvgIcons from "../Misc/ChartSvgIcons";

class ScoresExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scoresData: [],
      chartData: [],
      chartPeriods: [],
      isMain: true,
      primary: true,
      confirmed: true,
      viewState: true,
      viewChart: true,
      viewTable: false,
      chartWidth: "1600px",
      chartHeight: "800px",
      header: <tr key={generateId()} />,
      body: <tr key={generateId()} />,
      periods: ["0REG", "3MTHS", "6MTHS", "1YRS", "2YRS"],
      periodReference: [
        { ref: "0PREOP", label: "Pre-Op", isActive: false },
        { ref: "0REG", label: "Registration", isActive: true },
        { ref: "5PREOP", label: "5 Weeks Pre-Op", isActive: false },
        { ref: "5REG", label: "5 Weeks Pre-Reg", isActive: false },
        { ref: "3MTHS", label: "3 Months", isActive: true },
        { ref: "6MTHS", label: "6 Months", isActive: true },
        { ref: "1YRS", label: "1 Year", isActive: true },
        { ref: "2YRS", label: "2 Years", isActive: true },
        { ref: "5YRS", label: "5 Years", isActive: false }
      ],
      passedUPValue: "",
      lineOptions: {
        // responsive: true,
        maintainAspectRatio: false,
        // aspectRatio: 4,
        title: {
          display: true,
          text: ""
        },
        legend: {
          labels: {
            fontColor: "white"
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "white"
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "white"
              }
            }
          ]
        }
      }
    };
  }

  componentDidMount() {
    // pre-populate scores section
    if (this.props.results[0]) {
      this.getScores(this.props.results[0].key);
    }
    let container = document.querySelector(".scores-content-inner");
    this.setState({
      chartWidth: Math.ceil(container.clientWidth * 0.7),
      chartHeight: Math.ceil(container.clientHeight * 0.7)
    });
  }

  chartListener = e => {
    this.setState({
      passedUPValue: `- ${e.dataName} ${e.domain} - ${this.makePrecise(
        e.value
      )} mean score.`
    });
  };

  getScores = key => {
    client
      .query({
        query: GROUP_SCORES,
        variables: { id: this.props.groupId, scoreType: key }
      })
      .then(({ data }) => {
        this.setState(
          { scoresData: data.scoreGroups, scoreType: key },
          this.analyseScores
        );
      })
      .catch(error => {
        // console.log(error);
      });
  };

  makePrecise = number => {
    return Number.parseFloat(number).toFixed(1);
  };

  analyseScores = () => {
    let scores = this.state.scoresData;
    // let periods = Array.from(new Set(scores.map(e => e.period)));
    let dimensions = Array.from(new Set(scores.map(e => e.dimension)));
    let header = (
      <tr className="table-headings">
        <td>Dimension</td>
        {this.state.periods.map(e =>
          this.state.periodReference
            .filter(x => x.ref === e)
            .map(y => <td key={generateId()}>{y.label}</td>)
        )}
      </tr>
    );

    let items = [];
    for (let d of dimensions) {
      let cols = [];
      for (let p of this.state.periods) {
        let val = scores.filter(e => e.period === p && e.dimension === d);
        if (val[0]) {
          cols.push(
            val.map(e => (
              <td key={generateId()}>
                {this.makePrecise(e.mean)} stdv({this.makePrecise(e.stdev)})
                <span
                  style={{
                    float: "right",
                    color: "grey",
                    paddingRight: "2em"
                  }}
                  className="count"
                >
                  {e.scoreCount.low}
                </span>
              </td>
            ))
          );
        } else {
          cols.push(<td key={generateId()} />);
        }
      }
      let row = (
        <tr key={generateId()}>
          <td key={generateId()}>{d}</td>
          {cols}
        </tr>
      );
      items.push(row);
    }

    let chartData = [];
    let chartPeriods = [...this.state.periods];

    for (let d of dimensions) {
      let series = { label: d, data: [] };
      for (let p of this.state.periods) {
        if (scores.filter(e => e.period === p).length > 0) {
          let val = scores.filter(e => e.period === p && e.dimension === d);
          val[0]
            ? series.data.push(Number(this.makePrecise(val[0].mean)))
            : series.data.push(0);
        } else {
          chartPeriods = [...chartPeriods.filter(x => x !== p)];
        }
      }
      chartData.push(series);
    }

    let reLabelled = chartPeriods
      .map(e =>
        this.state.periodReference.filter(x => x.ref === e).map(x => x.label)
      )
      .reduce((acc, val) => acc.concat(val), []);
    this.setState(
      {
        header: header,
        body: items,
        chartData: chartData,
        chartPeriods: reLabelled
      },
      this.logit
    );
  };

  logit = () => {
    // console.log(this.state);
  };

  switchView = () => {
    this.setState({
      viewState: !this.state.viewState,
      viewChart: !this.state.viewChart,
      viewTable: !this.state.viewTable
    });
  };

  buildPeriodSelector = () => {
    return this.state.periodReference.map(x => (
      <li key={generateId()}>
        {x.label}{" "}
        <input
          key={generateId()}
          type="checkbox"
          value={x.label}
          checked={x.isActive}
          onChange={() => this.resetPeriods(x.ref)}
        />
      </li>
    ));
  };

  resetPeriods = r => {
    let memPeriods = Object.assign([], this.state.periodReference);
    let selected = memPeriods.filter(x => x.ref === r)[0];
    if (selected) {
      selected.isActive = !selected.isActive;
    }
    this.setState(
      {
        periodReference: memPeriods,
        periods: memPeriods.filter(x => x.isActive).map(x => x.ref)
      },
      this.analyseScores
    );
    // console.log(r)
  };

  tableView = () => {
    return (
      <table className="table-container scores-explorer-table">
        <tbody>
          {this.state.header}
          {this.state.body}
        </tbody>
      </table>
    );
  };

  chartView = () => {
    return (
      <div className="scores-chart-holder">
        {this.state.chartPeriods[0] && (
          <ScoresLine
            dataSet={this.state.chartData}
            labels={this.state.chartPeriods}
            type={"line"}
            refName={"lineChart"}
            defaultStyles={true}
            options={this.state.lineOptions}
            width={this.state.clientWidth}
            height={700}
          />
        )}
      </div>
    );
  };

  render() {
    // const { lineOptions } = this.state;
    return (
      <div className="scores-explorer grid-box">
        <div className="scores-header">
          <h2>
            {this.state.scoreType} Scores {this.state.passedUPValue}
          </h2>
          <ChartViewSwitcher
            switchView={this.switchView}
            viewChart={this.state.viewChart}
            viewTable={this.state.viewTable}
          />
        </div>
        <div className="separator-line-muted" />

        <div className="scores-content">
          <div className="scores-content-inner">
            {this.state.viewState ? this.chartView() : this.tableView()}
            <div className="scores-list-holder">
              <ul className="score-types-list">
                {this.props.results.map(x => (
                  <li key={generateId()} onClick={() => this.getScores(x.key)}>
                    {x.key}
                  </li>
                ))}
              </ul>
              <ul>{this.buildPeriodSelector()}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ScoresExplorer.propTypes = {
  results: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired
};

export default ScoresExplorer;
