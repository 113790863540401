import gql from "graphql-tag";

// Get all episodes
export const ALL_EPISODES_QUERY = gql`
  query Episode($first: Int, $owner: String) {
    Episode(first: $first, owner: $owner) {
      _id
      externalId
      owner
      type
      startDate
      patient {
        gender
        key
        dob
      }
      diagnoses {
        primaryBilateral
        primaryLeft
        primaryRight
        secondaryBilateral
        secondaryLeft
        secondaryRight
        sides
      }
    }
  }
`;

// Get individual episode full data
export const INDIVIDUAL_EPISODE_DATA = gql`
  query Episode($externalId: String) {
    Episode(externalId: $externalId) {
      _id
      externalId
      startDate
      patient {
        key
        gender
        dob
      }
      operations {
        keyWords
        count
        operationItems {
          side
          title
          hospital
          additionalProcedures
          date
          repeat
          primaryOrRevision
        }
      }
      diagnoses {
        sides
        diagnosisItems {
          _id
          isMain
          side
          status
          condition
          diagnosis
          ordinal
          date
        }
      }
      plans {
        sides
        planItems {
          date
          plan
          consultantIntervention
        }
      }
      complications {
        sides
        complicationItems {
          title
          side
          date
        }
      }
      visits {
        followUps
        postOp
        visitItems {
          date
          type
        }
      }
      scores {
        scoreSet {
          scoreType
          side
          scores {
            period
            dimensions {
              dimension
              value
            }
          }
        }
      }
    }
  }
`;

// Create new group from episodes
export const CREATE_NEW_EPISODES_GROUP = gql`
  mutation CreateEpisodesGroup(
    $title: String
    $owner: String
    $episodeGroupIds: [EpisodeGroupIds]
  ) {
    createEpisodesGroup(
      title: $title
      owner: $owner
      episodeGroupIds: $episodeGroupIds
    ) {
      title
      owner
      episodes {
        externalId
      }
    }
  }
`;
