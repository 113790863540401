import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

class ElectronMenu extends Component {
  componentDidMount() {
    this.navMenu();
  }

  navMenu = () => {
    const electron = window.require("electron");

    electron.ipcRenderer.on("goToProfile", (event, message) => {
      this.props.history.push(`/dashboard/profile`);
    });
    electron.ipcRenderer.on("goToDashboard", (event, message) => {
      this.props.history.push(`/dashboard`);
    });
    electron.ipcRenderer.on("generateYearGroups", (event, message) => {
      let text = `Loud and Clear ${this.props.currentUser.title} ${
        this.props.currentUser.surname
      }!`;
      event.sender.send("notifyGroupRebuild", {
        title: text,
        body: "We'll let you know as soon as your year groups are up to date."
      });
      this.props.regenerateYearGroups(event);
    });
    electron.ipcRenderer.on("generateDiagnosisGroups", (event, message) => {
      event.sender.send("notifyGroupRebuild", {
        title: "We are on it kimosabe",
        body:
          "We'll let you know as soon as your diagnosis groups are up to date."
      });
      this.props.regenerateDiagnosisGroups(event);
    });
    electron.ipcRenderer.on("generateOperationGroups", (event, message) => {
      event.sender.send("notifyGroupRebuild", {
        title: "Operations are go!",
        body:
          "We'll let you know as soon as your diagnosis groups are up to date."
      });
      this.props.regenerateOperationGroups(event);
    });
  };

  render() {
    return <div className="electron-nav" />;
  }
}

ElectronMenu.propTypes = {
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  regenerateDiagnosisGroups: PropTypes.func.isRequired,
  regenerateOperationGroups: PropTypes.func.isRequired,
  regenerateYearGroups: PropTypes.func.isRequired
};

export default withRouter(ElectronMenu);
