import React from "react";
import PropTypes from "prop-types";

const ProfileExtraDetails = props => {
  return (
    <div className="profile-extra-details grid-box">
      <h3 className="profile-box-title">Details</h3>
      <ul className="profile-details-list">
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">organisation:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.organisation}
          </span>
        </li>
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">specialism:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.specialism}
          </span>
        </li>
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">scoresname:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.scoresName}
          </span>
        </li>
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">externalId:</span>
          <span className="profile-details-list-item-result">
            {props.currentUser.externalId}
          </span>
        </li>
        <li className="profile-details-list-item">
          <span className="profile-details-list-item-title">roles:</span>

          {props.currentUser.roles.map(x => (
            <span key={x} className="profile-details-list-item-result">
              {x}
            </span>
          ))}
        </li>
      </ul>
    </div>
  );
};

ProfileExtraDetails.propTypes = {
  currentUser: PropTypes.shape({
    organisation: PropTypes.string.isRequired,
    scoresName: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
    roles: PropTypes.array.isRequired,
    specialism: PropTypes.string
  })
};

export default ProfileExtraDetails;
