import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import Groups from "../Groups/Groups";
import { generateId } from "../../lib/utils";
// import Loading from "../../components/Loading/Loading";
import {
  TOTAL_EPISODE_COUNT,
  UPDATE_YEAR_GROUPS,
  UPDATE_DIAGNOSIS_GROUPS,
  UPDATE_OPERATION_GROUPS
} from "../../gql/GroupGQL";
import Patients from "../Patients/Patients";
import { client } from "../../store/configureClient";
import Header from "../../components/Header/Header";
import Profile from "../Profile/Profile";

import ElectronMenu from "../../electronRender/ElectronMenu";
import { AppContext } from "../../contexts/Contexts";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
      modalComponentToLoad: "",
      modalData: {},
      totalEpisodes: 0,
      groupInView: "",
      groupStateIndicator: 0
    };
  }

  componentDidMount() {
    this.getTotalEpisodesCount();
  }

  regenerateYearGroups = event => {
    client
      .query({
        query: UPDATE_YEAR_GROUPS,
        variables: { owner: this.props.currentUser.scoresName }
      })
      .then(({ data }) => {
        // console.log(data);
        // event.sender.send('notifyGroupRebuild', {title: "Updated", body: "Your year groups are now up to date!"});
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        // console.log("done");
        this.setState({ groupStateIndicator: generateId() });
        this.notifyComplete(event, {
          title: "Updated",
          body: "Your year groups are now up to date!"
        });
      });
    // event.sender.send('notifyGroupRebuild', {title: "Updated", body: "Your year groups are now up to date!"});
  };

  regenerateDiagnosisGroups = event => {
    client
      .query({
        query: UPDATE_DIAGNOSIS_GROUPS,
        variables: { owner: this.props.currentUser.scoresName }
      })
      .then(({ data }) => {
        // console.log(data);
        // event.sender.send('notifyGroupRebuild', {title: "Updated", body: "Your year groups are now up to date!"});
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        // console.log("done");
        this.setState({ groupStateIndicator: generateId() });
        this.notifyComplete(event, {
          title: "Updated",
          body: "Your diagnosis groups are now up to date!"
        });
      });
  };

  regenerateOperationGroups = event => {
    client
      .query({
        query: UPDATE_OPERATION_GROUPS,
        variables: { owner: this.props.currentUser.scoresName }
      })
      .then(({ data }) => {
        // console.log(data);
        // event.sender.send('notifyGroupRebuild', {title: "Updated", body: "Your year groups are now up to date!"});
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        // console.log("done");
        this.setState({ groupStateIndicator: generateId() });
        this.notifyComplete(event, {
          title: "Updated",
          body: "Your operation groups are now up to date!"
        });
      });
  };

  notifyComplete = (event, msg) => {
    // console.log(event);
    event.sender.send("notifyGroupRebuild", msg);
  };

  // Gets episode totals for consultant
  getTotalEpisodesCount = () => {
    client
      .query({
        query: TOTAL_EPISODE_COUNT,
        variables: { scoresName: this.props.currentUser.scoresName }
      })
      .then(({ data }) => {
        this.setState({
          totalEpisodes: data.totalEpisodesCount.totalEpisodes
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  // OffCanvasMenu set status
  toggleOffCanvasMenuStatus = () => {
    this.setState({
      isOffCanvasMenuActive: !this.state.isOffCanvasMenuActive
    });
  };

  // togglesModal
  toggleModal = (modalData, modalComponentToLoad) => {
    this.setState({ modalActive: !this.state.modalActive });
  };

  setGroupInView = id => {
    this.setState({ groupInView: id });
  };

  render() {
    const { totalEpisodes } = this.state; //modalActive, modalComponentToLoad,

    let isElectron = this.context.isElectron;
    return (
      <div className={"dashboard"}>
        <div className="dashboard-inner">
          {/* Component that listens for electron processes */}
          {isElectron && (
            <ElectronMenu
              currentUser={this.props.currentUser}
              regenerateDiagnosisGroups={this.regenerateDiagnosisGroups}
              regenerateOperationGroups={this.regenerateOperationGroups}
              regenerateYearGroups={this.regenerateYearGroups}
            />
          )}
          <Header currentUser={this.props.currentUser} />
          <div className="dashboard-content">
            <Switch>
              <Route
                exact
                path={"/dashboard"}
                render={props => (
                  <Groups
                    {...props}
                    currentUser={this.props.currentUser}
                    renderMessages={this.props.renderMessages}
                    toggleLoadingOverlay={this.toggleLoadingOverlay}
                    totalEpisodes={totalEpisodes}
                    setGroupInView={this.setGroupInView}
                    groupStateIndicator={this.state.groupStateIndicator}
                  />
                )}
              />
              <Route
                path={"/dashboard/profile"}
                render={props => (
                  <Profile
                    {...props}
                    currentUser={this.props.currentUser}
                    renderMessages={this.props.renderMessages}
                  />
                )}
              />
              <Route
                path={"/dashboard/patients"}
                render={props => (
                  <Patients
                    {...props}
                    currentUser={this.props.currentUser}
                    renderMessages={this.props.renderMessages}
                    groupInView={this.state.groupInView}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  currentUser: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string
  }),
  renderMessages: PropTypes.func.isRequired
};

Dashboard.contextType = AppContext;

export default Dashboard;
