import gql from "graphql-tag";

export const LOGIN_USER = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password)
      # Using @connection to filter query otherwise password is saved in apollo cache
      # Need to use this technique for preventing sensitive data being stored in local cache
      @connection(key: "loginUser", filter: ["email"]) {
      token
      currentUser {
        id
        email
        username
        title
        forename
        surname
        suffix
        organisation
        specialism
        externalId
        isActive
        roles
        mobile
        savedQueries
        colleagues
      }
    }
  }
`;
