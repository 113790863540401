import React, { Component } from "react";
import PropTypes from "prop-types";
import { INDIVIDUAL_EPISODE_DATA } from "../../gql/EpisodeGQL";
import { client } from "../../store/configureClient";
import Timeline from "../Timeline/Timeline";
import EpisodeChart from "../../components/Episodes/EpisodeChart";
import BackLink from "../../components/BackLink/BackLink";

class EpisodeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      individualEpisodeData: [
        {
          scores: {
            scoreSet: []
          }
        }
      ]
    };
  }

  componentDidMount() {
    this.getIndividualEpisodeData(this.props.match.params.id);
  }

  getIndividualEpisodeData = externalId => {
    client
      .query({
        query: INDIVIDUAL_EPISODE_DATA,
        variables: {
          externalId: externalId
        }
      })
      .then(({ data }) => {
        this.setState({
          individualEpisodeData: [data.Episode[0]]
        });
      })
      .catch(error => {
        console.log(error);
        this.props.renderMessages(
          "invalid",
          "Couldn't fetch episode details",
          true
        );
      });
  };

  // 278641

  render() {
    const { individualEpisodeData } = this.state;
    return (
      <div className="episode-details">
        <BackLink />
        <Timeline individualEpisodeData={individualEpisodeData} />
        {individualEpisodeData[0].scores !== null &&
        individualEpisodeData[0].scores.scoreSet.length > 0 ? (
          <EpisodeChart individualEpisodeData={individualEpisodeData} />
        ) : (
          <div className="no-scores">
            <p className="scores-fail">You have no scores</p>
          </div>
        )}
      </div>
    );
  }
}

EpisodeDetails.propTypes = {
  renderMessages: PropTypes.func,
  episodeId: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  })
};

export default EpisodeDetails;
