import React, { Component } from "react";
import PropTypes from "prop-types";
import SvgIcons from "../Misc/SvgIcons";

class SelectionSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  selectionFunc = () => {
    this.setState({ selected: true });
    this.props.selection();
  };

  deselectionFunc = () => {
    this.setState({ selected: false });
    this.props.deselection();
  };

  render() {
    return (
      <div className="selection-switcher">
        {!this.state.selected ? (
          <div className="selected-holder" onClick={this.selectionFunc}>
            <SvgIcons icon={this.props.selectIcon} />
          </div>
        ) : (
          <div
            className="deselected-holder"
            onClick={this.props.deselection ? this.deselectionFunc : null}
          >
            <SvgIcons icon={this.props.deselectIcon} />
          </div>
        )}
      </div>
    );
  }
}

SelectionSwitcher.propTypes = {
  selectIcon: PropTypes.string.isRequired,
  deselectIcon: PropTypes.string.isRequired,
  selection: PropTypes.func.isRequired,
  deselection: PropTypes.func
};

export default SelectionSwitcher;
