import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SvgIcons from "../Misc/SvgIcons";

class BackLink extends Component {
  render() {
    return (
      <div className="back-link">
        <span onClick={this.props.history.goBack}>
          <SvgIcons icon="arrowLeft" />
          Back
        </span>
      </div>
    );
  }
}

BackLink.propTypes = {
  history: PropTypes.func.isRequired
};

export default withRouter(BackLink);
