import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import BarChart from "../Charts/BarChart";
import PieChart from "../Charts/PieChart";
import LineChart from "../Charts/LineChart";

class Patients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connectors: {
        maleHidden: false,
        femaleHidden: false,
        index: null
      },
      ageRangeLabels: [
        "> - 25",
        "26 - 35",
        "36 - 45",
        "46 - 55",
        "56 - 65",
        "65 - >"
      ],
      mixedAges: [
        {
          label: "",
          data: [0]
        }
      ],
      mergedAges: [
        {
          label: "",
          data: [0]
        }
      ],
      barOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "white",
                beginAtZero: true
              },
              barPercentage: 0.8,
              categoryPercentage: 1
            }
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "white"
              },
              barPercentage: 0.8,
              categoryPercentage: 1
            }
          ]
        },
        legend: {
          display: false,
          labels: {
            fontColor: "white"
          }
        }
      },
      otherOptions: {
        maintainAspectRatio: false,
        aspectRatio: 2,
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "white",
                beginAtZero: false
              },
              type: "time",
              time: {
                displayFormats: {
                  year: "YYYY-MM-DD",
                  quarter: "YYYY-MM-DD"
                }
              },
              distribution: "series"
            }
          ]
        }
      },
      genderPieOptions: {
        title: {
          display: false,
          text: "Genders",
          fontColor: "white"
        },
        cutoutPercentage: 80,
        legend: {
          display: false,
          position: "top",
          labels: {
            fontColor: "white"
          }
        }
      },
      genderLabels: ["Male", "Female"],
      genders: [],
      timeline: [{ key: "2019-01-01", count: 0 }]
    };
  }

  componentDidMount() {
    this.getGenderCount();
    this.getAgesRanges();
    this.getTimeline();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.results !== this.props.results) {
      this.getGenderCount();
      this.getAgesRanges();
      this.getTimeline();
    }
  }

  // Create age range datasets
  getAgesRanges = () => {
    // Base arr to generate from
    let counter = [0, 0, 0, 0, 0, 0];
    // Male colors

    let maleBgColor = counter.map(x => "rgba(51, 219, 198, 1)");
    let maleBorderColor = counter.map(x => "rgba(51, 219, 198, 0.5)");

    // Female Colors
    let femaleBgColor = counter.map(x => "rgba(254, 0, 117, 1)");
    let femaleBorderColor = counter.map(x => "rgba(254, 0, 117, 0.5)");

    // All ages both genders
    let mergedAges = [
      {
        label: "All Ages",
        data: [0, 0, 0, 0, 0, 0]
      }
    ];

    let mixedAges = [
      {
        label: "Male",
        data: [0, 0, 0, 0, 0, 0],
        backgroundColor: maleBgColor,
        borderColor: maleBorderColor,
        borderWidth: 1
      },
      {
        label: "Female",
        data: [0, 0, 0, 0, 0, 0],
        backgroundColor: femaleBgColor,
        borderColor: femaleBorderColor,
        borderWidth: 1
      }
    ];

    this.props.results
      .map(x => x.patient)
      .map((y, i) => {
        let age = parseInt(moment().diff(y.dob, "years", false), 10);
        if (age <= 25) {
          mergedAges[0].data[0] = mergedAges[0].data[0] + 1;
          y.gender === "M"
            ? (mixedAges[0].data[0] = mixedAges[0].data[0] + 1)
            : (mixedAges[1].data[0] = mixedAges[1].data[0] + 1);
        }
        // 26 to 35
        if (age > 25 && age <= 35) {
          mergedAges[0].data[1] = mergedAges[0].data[1] + 1;
          y.gender === "M"
            ? (mixedAges[0].data[1] = mixedAges[0].data[1] + 1)
            : (mixedAges[1].data[1] = mixedAges[1].data[1] + 1);
        }
        // 36 to 45
        if (age > 35 && age <= 45) {
          mergedAges[0].data[2] = mergedAges[0].data[2] + 1;
          y.gender === "M"
            ? (mixedAges[0].data[2] = mixedAges[0].data[2] + 1)
            : (mixedAges[1].data[2] = mixedAges[1].data[2] + 1);
        }
        // 46 to 55
        if (age > 45 && age <= 55) {
          mergedAges[0].data[3] = mergedAges[0].data[3] + 1;
          y.gender === "M"
            ? (mixedAges[0].data[3] = mixedAges[0].data[3] + 1)
            : (mixedAges[1].data[3] = mixedAges[1].data[3] + 1);
        }
        // 56 to 65
        if (age > 55 && age <= 65) {
          mergedAges[0].data[4] = mergedAges[0].data[4] + 1;
          y.gender === "M"
            ? (mixedAges[0].data[4] = mixedAges[0].data[4] + 1)
            : (mixedAges[1].data[4] = mixedAges[1].data[4] + 1);
        }
        // 65 plus
        if (age > 65) {
          mergedAges[0].data[5] = mergedAges[0].data[5] + 1;
          y.gender === "M"
            ? (mixedAges[0].data[5] = mixedAges[0].data[5] + 1)
            : (mixedAges[1].data[5] = mixedAges[1].data[5] + 1);
        }
      });

    this.setState({ mergedAges, mixedAges });
  };

  // Gets gender count
  getGenderCount = () => {
    const genders = [
      {
        label: "Patients",
        data: [0, 0]
      }
    ];

    this.props.results
      .map(x => x.patient)
      .map(x => {
        if (x.gender === "M") {
          genders[0].data[0] = genders[0].data[0] + 1;
        } else {
          genders[0].data[1] = genders[0].data[1] + 1;
        }
      });

    this.setState({ genders: genders });
  };

  getTimeline = () => {
    let result = this.props.results.reduce(
      (h, obj) =>
        Object.assign(h, {
          [moment(obj.startDate).format("YYYY-MM-01")]:
            h[moment(obj.startDate).format("YYYY-MM-01")] + 1 || 1
        }),
      {}
    );
    this.setState(
      {
        timeline: Object.keys(result)
          .map(e => ({ key: e, count: result[e] }))
          .sort((a, b) => new Date(b.key) - new Date(a.key))
          .reverse()
      },
      this.showit
    );
  };
  showit = () => {
    // console.log(this.state.timeline);
  };
  // Connector function that triggers double chart updates
  toggleDataVisibility = (label, labelIndex) => {
    this.setState({
      connectors: {
        ...this.state.connectors,
        [label]: !this.state.connectors[label],
        index: labelIndex
      }
    });
  };

  render() {
    const {
      ageRangeLabels,
      mixedAges,
      genderLabels,
      genders,
      genderPieOptions,
      connectors,
      barOptions,
      otherOptions,
      timeline
    } = this.state;
    // console.log(timeline)
    return (
      <div className="patients grid-box">
        <h2 className="patients-header">Demographics</h2>

        <div className="separator-line-muted" />
        <div className="patient-legends-holder chart-legends">
          <div
            className="legend"
            onClick={() => this.toggleDataVisibility("maleHidden", 0)}
          >
            <span className="box male" />
            <span
              className={
                connectors.maleHidden ? "label male crossed" : "label male"
              }
            >
              Male
            </span>
          </div>
          <div
            className="legend"
            onClick={() => this.toggleDataVisibility("femaleHidden", 1)}
          >
            <span className="box female" />
            <span
              className={
                connectors.femaleHidden
                  ? "label female crossed"
                  : "label female"
              }
            >
              Female
            </span>
          </div>
        </div>
        <div className="charts-holder">
          <div className="top-row">
            <PieChart
              dataSet={genders}
              labels={genderLabels}
              type={"doughnut"}
              options={genderPieOptions} // options to change cutout
              refName={"gendersDoughnut"}
              connectors={connectors}
              defaultStyles={true}
              height={200}
            />
            <BarChart
              dataSet={mixedAges}
              labels={ageRangeLabels}
              type={"bar"}
              refName={"ageRange"}
              options={barOptions}
              defaultStyles={false}
              connectors={connectors}
              height={150}
            />
          </div>
          <div className="bottom-row">
            <LineChart
              dataSet={[
                {
                  data: timeline.map(e => ({ x: moment(e.key), y: e.count }))
                }
              ]}
              labels={timeline.map(e => e.key)}
              type={"line"}
              refName={"rr"}
              height={200}
              options={otherOptions}
              defaultStyles={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

Patients.propTypes = {
  results: PropTypes.array.isRequired
};

export default Patients;
