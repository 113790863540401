import React from "react";
import PropTypes from "prop-types";

const ProfileAvatar = props => {
  return (
    <div className="profile-avatar grid-box">
      <div className="profile-avatar-inner">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-user"
        >
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
          <circle cx="12" cy="7" r="4" />
        </svg>
      </div>
      <div className="profile-name-holder">
        <h4 className="profile-title">{`${props.currentUser.title} ${
          props.currentUser.forename
        } ${props.currentUser.surname}`}</h4>
        <h4 className="profile-specialism">
          <span className="profile-specialism-title">Specialism:</span>
          <span className="profile-specialism-result">
            {props.currentUser.specialism}
          </span>
        </h4>
      </div>
    </div>
  );
};

ProfileAvatar.propTypes = {
  currentUser: PropTypes.shape({
    title: PropTypes.string.isRequired,
    forename: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    specialism: PropTypes.string
  })
};
export default ProfileAvatar;
