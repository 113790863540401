import React from "react";
import PropTypes from "prop-types";

class CheckBoxes extends React.Component {
  // Function to render checkboxes based upon passed in options array
  renderCheckBoxes = () => {
    let checkboxList = [];
    this.props.options.map((checkbox, i) => {
      checkboxList.push(
        <div className="item" key={checkbox + i}>
          <input
            type="checkbox"
            name={this.props.name}
            value={checkbox}
            onChange={this.props.onChangeFunc}
            disabled={this.props.disabled}
            id={`${this.props.name}${checkbox}`}
          />
          <label htmlFor={`${this.props.name}${checkbox}`}>{checkbox}</label>
        </div>
      );
    });
    return checkboxList;
  };

  render() {
    return (
      <div
        className={
          this.props.isValid !== null
            ? this.props.isValid
              ? `input-holder checkbox-holder ${this.props.name}-holder valid`
              : `input-holder checkbox-holder  ${
                  this.props.name
                }-holder invalid`
            : `input-holder checkbox-holder ${this.props.name}-holder`
        }
      >
        <label>{this.props.label}</label>
        <div className="items-container">{this.renderCheckBoxes()}</div>
      </div>
    );
  }
}

CheckBoxes.propTypes = {
  name: PropTypes.string.isRequired,
  typeclass: PropTypes.string,
  onChangeFunc: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default CheckBoxes;
