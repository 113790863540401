import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

class Operation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = this.props.data;
    return (
      <div className="operation-timeline-item">
        <h3 className="timeline-item-title">
          <span className="pre-title">Operation:</span> {data.title}
        </h3>
        {data.hospital ? <span className="lede">{data.hospital}</span> : false}
        {data.additionalProcedures !== "None" && data.additionalProcedures ? (
          <p>Additionally: {data.additionalProcedures}</p>
        ) : (
          false
        )}
        {data.side ? <p>({data.side})</p> : false}
        {data.repeat !== "Not Provided" ? <p>Repeat: {data.repeat}</p> : false}
        {data.primaryOrRevision !== "Not Provided" ? (
          <p>Revision or Primary: {data.primaryOrRevision}</p>
        ) : (
          false
        )}
        {data.date ? (
          <h4 className="content-date">
            {moment(data.date).format("dddd, MMMM Do YYYY")}
          </h4>
        ) : null}
      </div>
    );
  }
}

Operation.propTypes = {
  data: PropTypes.object.isRequired
};

export default Operation;
