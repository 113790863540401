import React, { Component } from "react";
import PropTypes from "prop-types";
import { client } from "../../store/configureClient";
import { SINGLE_GROUP_QUERY } from "../../gql/GroupGQL";
import GroupsSingleView from "../GroupsSingleView/GroupsSingleView";
import Loading from "../../components/Loading/Loading";

class GroupsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: [],
      loadingOverlayActive: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.firstGroup !== this.props.firstGroup) {
      let firstGroupId = this.props.firstGroup[0].groups[0]._id;
      this.props.setGroupInView(firstGroupId);
      this.getSelectedGroup(firstGroupId);
    }
  }

  // Gets selected group and passes value to GroupsSingleView
  getSelectedGroup = id => {
    // toggle overlay loading component
    this.toggleLoadingOverlay(true);
    client
      .query({
        query: SINGLE_GROUP_QUERY,
        variables: { _id: id }
      })
      .then(({ data, networkStatus }) => {
        // Using networkStatus key on response to delay render
        // until all data is in
        if (networkStatus === 7) {
          this.setState({
            selectedGroup: data.Group
          });
          this.toggleLoadingOverlay(false);
        }
      })
      .catch(error => {
        this.toggleLoadingOverlay(false);
      });
  };

  // Toggle loading overlay screen can be called in query to handling
  // onloading status
  toggleLoadingOverlay = bool => {
    this.setState({ loadingOverlayActive: bool });
  };

  render() {
    const { selectedGroup } = this.state;
    return (
      <div className="groups-overview">
        <Loading loadingOverlayActive={this.state.loadingOverlayActive} />
        {this.state.selectedGroup.length > 0 && (
          <GroupsSingleView
            results={selectedGroup}
            addToTempGroup={this.props.addToTempGroup}
            createGroup={this.props.createGroup}
          />
        )}
      </div>
    );
  }
}

GroupsOverview.propTypes = {
  addToTempGroup: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  firstGroup: PropTypes.array.isRequired,
  setGroupInView: PropTypes.func
};

export default GroupsOverview;
