import React, { Component } from "react";
import PropTypes from "prop-types";
import "../assets/scss/styles.scss";
import Dashboard from "./Dashboard/Dashboard";
import Login from "./Login/Login";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import NoRoute from "../components/NoRoute/noRoute";
import AuthRoute from "../components/AuthRoute/AuthRoute";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import Messages from "./Messages/Messages";
import Welcome from "./Welcome/Welcome";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { baseThemeLight, baseThemeDark } from "../themes/MainTheme";
import { withStyles } from "@material-ui/core/styles";
import { AppContext } from "../contexts/Contexts";

// Uncomment this & line 22 for demo user data
// import { currentUser } from "../lib/demoUser"; //TODO remove in prod

const styles = {
  root: {
    color: "#343e48"
  }
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      isAuthed: false,
      currentUser: {
        scoresName: ""
      },
      // currentUser: currentUser, //TODO remove in prod
      messageStatus: "",
      messageContent: "",
      messagesActive: false,
      isElectron: true,
      themeType: baseThemeLight // bool that is passed through context down tree to wrap electron only funcs
    };
  }

  componentDidMount() {
    if (typeof window.require !== "function") {
      this.setState({ isElectron: false });
    }
  }

  // Gets message status and strings passed into childrens context
  renderMessages = (messageStatus, messageContent, messageActive) => {
    this.setState({ messageStatus, messageContent, messageActive });
  };

  // // Hides message container click event
  // resets all state
  hideMessage = () => {
    this.setState({
      messageActive: false,
      messageStatus: "",
      messageContent: ""
    });
  };

  // Retrieves token result from login used to open up authed route
  checkAuthStatusAndGetCurrentUser = data => {
    if (data !== "") {
      this.props.retreiveToken(data.token);
      this.setState(
        // TODO remove ...this.state.currentUser only used to keep neoUser around
        {
          isAuthed: true,
          currentUser: {
            ...this.state.currentUser,
            ...data.currentUser,
            ...{ scoresName: data.currentUser.username }
          }
        },
        () => this.props.history.push(`/dashboard`)
      );
    }
  };

  toggleTheme = () => {
    if (this.state.themeType === baseThemeLight) {
      this.setState({ themeType: baseThemeDark });
    } else {
      this.setState({ themeType: baseThemeLight });
    }
  };

  render() {
    const {
      isAuthed,
      currentUser,
      messageStatus,
      messageContent,
      messageActive
    } = this.state;

    const { classes } = this.props;
    return (
      <AppContext.Provider
        value={{
          isElectron: this.state.isElectron,
          toggleTheme: this.toggleTheme
        }}
      >
        <MuiThemeProvider theme={createMuiTheme(this.state.themeType)}>
          <React.Fragment>
            <CssBaseline />
            <div className={classes.root}>
              {messageActive && (
                <Messages
                  messageStatus={messageStatus}
                  messageContent={messageContent}
                  hideMessage={this.hideMessage}
                />
              )}

              <Switch>
                {this.state.isElectron ? (
                  <Route
                    exact
                    path="/"
                    render={props => (
                      <Login
                        {...props}
                        checkAuthStatusAndGetCurrentUser={
                          this.checkAuthStatusAndGetCurrentUser
                        }
                        renderMessages={this.renderMessages}
                      />
                    )}
                  />
                ) : (
                  <Route
                    exact
                    path="/"
                    render={props => (
                      <Welcome
                        {...props}
                        checkAuthStatusAndGetCurrentUser={
                          this.checkAuthStatusAndGetCurrentUser
                        }
                        renderMessages={this.renderMessages}
                      />
                    )}
                  />
                )}

                <AuthRoute
                  path="/dashboard"
                  isAuthed={isAuthed}
                  currentUser={currentUser}
                  component={Dashboard}
                  renderMessages={this.renderMessages}
                />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route component={NoRoute} />
              </Switch>
            </div>
          </React.Fragment>
        </MuiThemeProvider>
        {/* <div className="app"> */}
        {/* Messages component receives messages from below triggered by renderMessages()*/}

        {/* </div> */}
      </AppContext.Provider>
    );
  }
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  retreiveToken: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(App));
