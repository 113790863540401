import React from "react";
import PropTypes from "prop-types";

class ChartsRadioOption extends React.Component {

  render() {
    return (
      <label>
        <input
            type="radio"
            name={this.props.name}
            value={this.props.value}
            onChange={() =>this.props.onChangeFunc()}
            checked={this.props.isSelected}
          />
        {this.props.value}
      </label>
    )
  }
}

ChartsRadioOption.propTypes = {
    name: PropTypes.string.isRequired,
    onChangeFunc: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isSelected: PropTypes.bool
  };

  export default ChartsRadioOption;