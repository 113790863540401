import React from "react";
import PropTypes from "prop-types";

const ProfileColleagues = props => {
  return (
    <div className="profile-colleagues grid-box">
      <h3 className="profile-box-title">colleagues</h3>
      <ul className="profile-details-list">
        {props.currentUser.colleagues.map(x => (
          <li className="profile-details-list-item" key={x}>
            <span className="profile-details-list-item-title">{x}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

ProfileColleagues.propTypes = {
  currentUser: PropTypes.shape({
    colleagues: PropTypes.array.isRequired
  })
};

export default ProfileColleagues;
