import React, { Component } from "react";
import ChartSvgIcons from "../Misc/ChartSvgIcons";
import PropTypes from "prop-types";

class ChartViewSwitcher extends Component {
  render() {
    return (
      <div className="view-switcher">
        <h4 className="view-switcher-title">Switch view: </h4>
        <ul>
          <li
            className={this.props.viewChart ? "visible" : ""}
            onClick={this.props.switchView}
          >
            <ChartSvgIcons icon="tableSwitch" />
          </li>
          <li
            className={this.props.viewTable ? "visible" : ""}
            onClick={this.props.switchView}
          >
            <ChartSvgIcons icon="chartSwitch" />
          </li>
        </ul>
      </div>
    );
  }
}

ChartViewSwitcher.propTypes = {
  switchView: PropTypes.func.isRequired,
  viewChart: PropTypes.bool,
  viewTable: PropTypes.bool
};

export default ChartViewSwitcher;
