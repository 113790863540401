import React from "react";
import PropTypes from "prop-types";

class SelectBox extends React.Component {
  // Renders options passed down from parent
  renderOptions = () => {
    let options = this.props.options.map((option, i) => (
      <option key={option + i} value={option}>
        {option}
      </option>
    ));
    return options;
  };

  render() {
    return (
      <div
        className={
          this.props.isValid !== null
            ? this.props.isValid
              ? `input-holder select-holder ${this.props.name}-holder valid`
              : `input-holder select-holder ${this.props.name}-holder invalid`
            : `input-holder select-holder ${this.props.name}-holder`
        }
      >
        <label htmlFor={`${this.props.name}`}>{this.props.label}</label>
        <select
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChangeFunc}
          disabled={this.props.disabled}
        >
          <option value="">
            {this.props.placeholder.length > 0 ? this.props.placeholder : ""}
          </option>
          {this.renderOptions()}
        </select>
      </div>
    );
  }
}

SelectBox.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeFunc: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default SelectBox;
