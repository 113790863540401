import React from "react";

const ChartSvgIcons = props => {
  /*eslint-disable */
  switch (props.icon) {
    /*eslint-enable */

    case "chartSwitch":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="chart-switch"
            width="100%"
            height="100%"
            viewBox="0 0 512 512"
          >
            <g id="g1122" transform="matrix(1 0 0 1.2211 249.463 -104.947)">
              <g id="g1120">
                <path
                  className="st1"
                  d="m 237.5,290.8 h -43.4 c -4,0 -7.2,2.5 -7.2,5.6 v 169.2 c 0,3.1 3.2,5.6 7.2,5.6 h 43.4 c 4,0 7.2,-2.5 7.2,-5.6 V 296.4 c 0,-3.1 -3.2,-5.6 -7.2,-5.6 z m -7.2,169.1 h -28.9 z"
                  id="path1118"
                  fill="#33dbc6"
                />
              </g>
            </g>
            <g id="g1128" transform="matrix(1 0 0 -.70988 15.613 591.738)">
              <g id="g1126">
                <path
                  className="st2"
                  d="M 126.4,169.8 H 83.1 c -4,0 -7.2,4.2 -7.2,9.4 v 282.6 c 0,5.2 3.2,9.4 7.2,9.4 h 43.4 c 4,0 7.2,-4.2 7.2,-9.4 V 179.2 c 0,-5.2 -3.3,-9.4 -7.3,-9.4 z m -7.2,282.6 H 90.3 Z"
                  id="path1124"
                  fill="#fde101"
                />
              </g>
            </g>
            <g id="g1134" transform="matrix(1 0 0 -1.54744 -48.378 815.919)">
              <g id="g1132">
                <path
                  className="st3"
                  d="m 477.6,221.8 h -43.4 c -4,0 -7.2,3.5 -7.2,7.8 v 233.8 c 0,4.3 3.2,7.8 7.2,7.8 h 43.4 c 4,0 7.2,-3.5 7.2,-7.8 V 229.5 c 0,-4.2 -3.2,-7.7 -7.2,-7.7 z m -7.2,233.8 h -28.9 z"
                  id="path1130"
                  fill="#8142fd"
                />
              </g>
            </g>
            <g id="g1140">
              <g id="g1138">
                <path
                  d="M490.8,481.2h-470c-5.7,0-10.2,4.8-10.2,10.7c0,5.9,4.6,10.7,10.2,10.7h470c5.7,0,10.2-4.8,10.2-10.7 S496.5,481.2,490.8,481.2z"
                  id="path1136"
                />
              </g>
            </g>
            <g id="g1146">
              <g id="g1144">
                <path
                  d="M32,492V22c0-5.7-4.8-10.2-10.7-10.2S10.6,16.3,10.6,22v470c0,5.7,4.8,10.2,10.7,10.2C27.2,502.1,32,497.6,32,492z"
                  id="path1142"
                />
              </g>
            </g>
            <g id="g1152" transform="matrix(1 0 0 1.01276 -38 -6.01)">
              <g id="g1150">
                <path
                  className="st4"
                  d="m 351.9,290.8 h -43.4 c -4,0 -7.2,2.5 -7.2,5.6 v 169.2 c 0,3.1 3.2,5.6 7.2,5.6 h 43.4 c 4,0 7.2,-2.5 7.2,-5.6 V 296.4 c 0,-3.1 -3.2,-5.6 -7.2,-5.6 z m -7.2,169.1 h -28.9 z"
                  id="path1148"
                  fill="#fe0075"
                />
              </g>
            </g>
            <g id="g1134-7" transform="matrix(1 0 0 -.59096 -220.586 602.096)">
              <g id="g1132-9">
                <path
                  className="st3"
                  d="m 477.6,221.8 h -43.4 c -4,0 -7.2,3.5 -7.2,7.8 v 233.8 c 0,4.3 3.2,7.8 7.2,7.8 h 43.4 c 4,0 7.2,-3.5 7.2,-7.8 V 229.5 c 0,-4.2 -3.2,-7.7 -7.2,-7.7 z m -7.2,233.8 h -28.9 z"
                  id="path1130-3"
                  fill="#8142fd"
                />
              </g>
            </g>
            <g transform="matrix(1 0 0 1.75683 -151.482 -356.78)" id="g1152-8">
              <g id="g1150-0">
                <path
                  className="st4"
                  d="m 351.9,290.8 h -43.4 c -4,0 -7.2,2.5 -7.2,5.6 v 169.2 c 0,3.1 3.2,5.6 7.2,5.6 h 43.4 c 4,0 7.2,-2.5 7.2,-5.6 V 296.4 c 0,-3.1 -3.2,-5.6 -7.2,-5.6 z m -7.2,169.1 h -28.9 z"
                  id="path1148-2"
                  fill="#fe0075"
                />
              </g>
            </g>
            <g transform="matrix(1 0 0 .56206 -152.136 205.428)" id="g1122-4">
              <g id="g1120-8">
                <path
                  className="st1"
                  d="m 237.5,290.8 h -43.4 c -4,0 -7.2,2.5 -7.2,5.6 v 169.2 c 0,3.1 3.2,5.6 7.2,5.6 h 43.4 c 4,0 7.2,-2.5 7.2,-5.6 V 296.4 c 0,-3.1 -3.2,-5.6 -7.2,-5.6 z m -7.2,169.1 h -28.9 z"
                  id="path1118-3"
                  fill="#33dbc6"
                />
              </g>
            </g>
            <g id="g1128-9" transform="matrix(1 0 0 -.98728 244.966 640.417)">
              <g id="g1126-0">
                <path
                  className="st2"
                  d="M 126.4,169.8 H 83.1 c -4,0 -7.2,4.2 -7.2,9.4 v 282.6 c 0,5.2 3.2,9.4 7.2,9.4 h 43.4 c 4,0 7.2,-4.2 7.2,-9.4 V 179.2 c 0,-5.2 -3.3,-9.4 -7.3,-9.4 z m -7.2,282.6 H 90.3 Z"
                  id="path1124-5"
                  fill="#fde101"
                />
              </g>
            </g>
          </svg>
        </div>
      );

    case "tableSwitch":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="table-switch"
            viewBox="0 0 512 512"
            width="100%"
            height="100%"
          >
            <path d="M32,492V22c0-5.7-4.8-10.2-10.7-10.2S10.6,16.3,10.6,22v470c0,5.7,4.8,10.2,10.7,10.2C27.2,502.1,32,497.6,32,492z" />
            <path
              d="m490.8 481.2h-470c-5.7 0-10.2 4.8-10.2 10.7s4.6 10.7 10.2 10.7h470c5.7 0 10.2-4.8 10.2-10.7s-4.5-10.7-10.2-10.7z"
              transform="translate(1.81 -471.02)"
            />
            <path
              d="m32 492v-470c0-5.7-4.8-10.2-10.7-10.2s-10.7 4.5-10.7 10.2v470c0 5.7 4.8 10.2 10.7 10.2 5.9-0.1 10.7-4.6 10.7-10.2z"
              transform="translate(471.13 -.586)"
            />
            <path
              d="m490.8 481.2h-470c-5.7 0-10.2 4.8-10.2 10.7s4.6 10.7 10.2 10.7h470c5.7 0 10.2-4.8 10.2-10.7s-4.5-10.7-10.2-10.7z"
              transform="translate(1.32 1.09)"
            />
            <g fillOpacity="0">
              <rect x="35.283" y="157.63" width="440.28" height="9.204" />
              <rect x="38.352" y="245.07" width="440.28" height="9.204" />
              <rect
                x="-389.65"
                y="148.43"
                width="276.13"
                height="144.2"
                opacity="0.1"
              />
            </g>
            <rect x="26.535" y="126.63" width="463.29" height="24.545" />
            <rect x="26.535" y="244.66" width="463.29" height="24.545" />
            <rect x="24.357" y="362.69" width="463.29" height="24.545" />
            <path
              d="m46.103 492v-470c0-5.7-4.8-10.2-10.7-10.2s-10.7 4.5-10.7 10.2v470c0 5.7 4.8 10.2 10.7 10.2 5.9-0.1 10.7-4.6 10.7-10.2z"
              transform="matrix(1 0 0 .26563 142.94 7.131)"
            />
            <path
              d="m23.384 492v-470c0-5.7-4.8-10.2-10.7-10.2-5.9 0-10.7 4.5-10.7 10.2v470c0 5.7 4.8 10.2 10.7 10.2 5.9-0.1 10.7-4.6 10.7-10.2z"
              transform="matrix(1 0 0 .25236 322.7 10.136)"
            />
            <g fill="#33dbc6">
              <rect x="57.517" y="61.831" width="84.61" height="39.051" />
              <rect x="371.6" y="60.746" width="84.61" height="39.051" />
              <rect x="214.56" y="62.915" width="84.61" height="39.051" />
              <rect x="55.333" y="178.4" width="405.69" height="39.051" />
            </g>
            <rect
              x="55.333"
              y="298.4"
              width="405.69"
              height="39.051"
              fill="#fe0075"
              fillOpacity="0.984"
            />
            <rect
              x="55.333"
              y="418.4"
              width="405.69"
              height="39.051"
              fill="#33dbc6"
            />
          </svg>
        </div>
      );

    case "lineChart":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="line-chart"
            viewBox="0 0 512 512"
            width="100%"
            height="100%"
          >
            <switch>
              <g>
                <line
                  className="st0"
                  x1="44.4"
                  y1="104.3"
                  x2="493.4"
                  y2="104.2"
                />
                <line
                  className="st0"
                  x1="44.4"
                  y1="305.8"
                  x2="493.4"
                  y2="305.7"
                />
                <line
                  className="st0"
                  x1="44.4"
                  y1="204.3"
                  x2="493.4"
                  y2="204.3"
                />
                <line
                  className="st0"
                  x1="44.4"
                  y1="405.2"
                  x2="493.4"
                  y2="405.2"
                />
                <line
                  className="st0"
                  x1="111.3"
                  y1="16.8"
                  x2="111.4"
                  y2="465.8"
                />
                <line
                  className="st0"
                  x1="225.3"
                  y1="21.8"
                  x2="225.4"
                  y2="470.8"
                />
                <line
                  className="st0"
                  x1="340.3"
                  y1="21.8"
                  x2="340.4"
                  y2="470.8"
                />
                <line
                  className="st0"
                  x1="463.3"
                  y1="21.8"
                  x2="463.4"
                  y2="470.8"
                />
                <path d="M31.5,490.2v-470C31.4,14.5,26.6,9.9,20.9,10c0,0-0.1,0-0.1,0c-5.9,0-10.7,4.5-10.7,10.1v470c0.1,5.8,4.9,10.3,10.6,10.2 c0,0,0,0,0.1,0C26.7,500.3,31.5,495.8,31.5,490.2z" />
                <path d="M21.7,500.4h470c5.9-0.3,10.5-5.3,10.2-11.2c-0.2-5.6-4.7-10-10.2-10.2h-470c-5.9,0.3-10.5,5.3-10.2,11.2 C11.7,495.7,16.1,500.2,21.7,500.4z" />
                <rect
                  x="296"
                  y="205.8"
                  transform="rotate(-45.057 385.772 214.389)"
                  className="st1"
                  width="179.4"
                  height="17.2"
                />
                <rect
                  x="260.9"
                  y="235.2"
                  transform="rotate(-45.114 269.455 260.234)"
                  className="st2"
                  width="17.2"
                  height="50.1"
                />
                <rect
                  x="69.2"
                  y="293.6"
                  transform="rotate(-45.057 158.906 302.2)"
                  className="st1"
                  width="179.4"
                  height="17.2"
                />
                <path d="M466.9,100c-18.5,0-33.5,15-33.5,33.5s15,33.5,33.5,33.5s33.5-15,33.5-33.5c0-18.5-14.9-33.5-33.4-33.5 C467,100,466.9,100,466.9,100z M466.9,149.8c-9,0-16.3-7.3-16.3-16.3s7.3-16.3,16.3-16.3c9,0,16.3,7.3,16.3,16.3l0,0 C483.2,142.5,475.9,149.8,466.9,149.8C466.9,149.8,466.9,149.8,466.9,149.8L466.9,149.8z" />
                <path
                  className="st3"
                  d="M79.6,347.1c-18.5,0-33.5,15-33.5,33.5s15,33.5,33.5,33.5s33.5-15,33.5-33.5l0,0c0-18.5-14.9-33.5-33.4-33.5 C79.7,347.1,79.6,347.1,79.6,347.1z M79.6,397c-9,0-16.3-7.3-16.3-16.3c0-9,7.3-16.3,16.3-16.3s16.3,7.3,16.3,16.3l0,0 C95.9,389.7,88.6,397,79.6,397L79.6,397z"
                />
                <path d="M234.1,191.4c-18.5,0-33.5,15-33.5,33.5s15,33.5,33.5,33.5s33.5-15,33.5-33.5l0,0c0-18.5-14.9-33.5-33.4-33.5 C234.2,191.4,234.1,191.4,234.1,191.4z M234.1,241.3c-9,0-16.3-7.3-16.3-16.3s7.3-16.3,16.3-16.3c9,0,16.3,7.3,16.3,16.3 C250.4,234,243.1,241.3,234.1,241.3C234.1,241.3,234.1,241.3,234.1,241.3z" />
                <path
                  className="st2"
                  d="M304.7,262.1c-18.5,0-33.5,15-33.5,33.5s15,33.5,33.5,33.5s33.5-15,33.5-33.5l0,0c0-18.5-14.9-33.5-33.4-33.5 C304.8,262.1,304.7,262.1,304.7,262.1z M304.7,311.9c-9,0-16.3-7.3-16.3-16.3s7.3-16.3,16.3-16.3c9,0,16.3,7.3,16.3,16.3 c0,0,0,0,0,0C321,304.6,313.7,311.9,304.7,311.9L304.7,311.9L304.7,311.9z"
                />
                <circle className="st4" cx="234.1" cy="225" r="16.3" />
                <circle className="st4" cx="304.7" cy="295.6" r="16.3" />
                <circle className="st4" cx="466.9" cy="133.5" r="16.3" />
                <circle className="st4" cx="79.6" cy="380.6" r="16.3" />
              </g>
            </switch>
          </svg>
        </div>
      );

    case "pieChart":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="pie-chart"
            width="100%"
            height="100%"
            viewBox="0 0 512 512"
          >
            <switch>
              <g>
                <line
                  className="st0"
                  x1="31.5"
                  y1="114.4"
                  x2="480.5"
                  y2="114.3"
                />
                <line
                  className="st0"
                  x1="31.5"
                  y1="315.9"
                  x2="480.5"
                  y2="315.8"
                />
                <line
                  className="st0"
                  x1="31.5"
                  y1="214.4"
                  x2="480.5"
                  y2="214.4"
                />
                <line
                  className="st0"
                  x1="31.5"
                  y1="415.3"
                  x2="480.5"
                  y2="415.3"
                />
                <line
                  className="st0"
                  x1="98.4"
                  y1="26.9"
                  x2="98.5"
                  y2="475.9"
                />
                <line
                  className="st0"
                  x1="212.4"
                  y1="31.9"
                  x2="212.5"
                  y2="480.9"
                />
                <line
                  className="st0"
                  x1="327.4"
                  y1="31.9"
                  x2="327.5"
                  y2="480.9"
                />
                <line
                  className="st0"
                  x1="450.4"
                  y1="31.9"
                  x2="450.5"
                  y2="480.9"
                />
                <circle className="st1" cx="260" cy="257.1" r="215" />
                <circle className="st2" cx="260" cy="257.1" r="215" />
                <circle className="st3" cx="260" cy="257.1" r="116.3" />
                <path
                  className="st1"
                  d="M239.3,142.7l-26.1-95.4c-55.2,12.4-103.4,46-134,93.6l87.2,47.3C184.1,164.5,210.1,148.2,239.3,142.7 L239.3,142.7z"
                />
              </g>
            </switch>
          </svg>
        </div>
      );

    case "scatterChart":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="scatter-chart"
            data-name="Layer 1"
            height="100%"
            width="100%"
            viewBox="0 0 512 512"
          >
            <defs />
            <circle className="cls-1" cx="398.01" cy="130.54" r="58.25" />
            <circle className="cls-1" cx="212.45" cy="374.2" r="70.9" />
            <circle className="cls-2" cx="397.09" cy="318.63" r="25.34" />
            <circle className="cls-2" cx="141.55" cy="119.53" r="23.74" />
            <circle className="cls-3" cx="333.23" cy="387.22" r="32.58" />
            <circle className="cls-3" cx="242.42" cy="245.22" r="32.58" />
            <circle className="cls-2" cx="333.23" cy="264.79" r="47.5" />
            <circle className="cls-4" cx="300.65" cy="184.71" r="32.58" />
            <circle className="cls-5" cx="148.01" cy="235.92" r="58.25" />
            <circle className="cls-1" cx="242.42" cy="109.52" r="42.62" />
          </svg>
        </div>
      );

    case "radarChart":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="radar-chart"
            data-name="Layer 1"
            height="100%"
            width="100%"
            viewBox="0 0 512 512"
          >
            <defs>
              <linearGradient
                className="linear-gradient"
                x1="144.8"
                y1="278.95"
                x2="503.5"
                y2="278.95"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#fff" />
                <stop offset="0" stopColor="#fffffb" />
                <stop offset="0" stopColor="#fffdef" stopOpacity="0.98" />
                <stop offset="0" stopColor="#fffbdb" stopOpacity="0.96" />
                <stop offset="0" stopColor="#fef7bf" stopOpacity="0.92" />
                <stop offset="0" stopColor="#fef39a" stopOpacity="0.88" />
                <stop offset="0.01" stopColor="#feee6e" stopOpacity="0.83" />
                <stop offset="0.01" stopColor="#fde83a" stopOpacity="0.77" />
                <stop offset="0.01" stopColor="#fde101" stopOpacity="0.7" />
              </linearGradient>
              <linearGradient
                className="linear-gradient-2"
                x1="73.97"
                y1="266.2"
                x2="330.92"
                y2="266.2"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#fff" />
                <stop offset="0" stopColor="#fcfeff" stopOpacity="0.99" />
                <stop offset="0" stopColor="#f1fafe" stopOpacity="0.97" />
                <stop offset="0" stopColor="#e0f4fd" stopOpacity="0.93" />
                <stop offset="0" stopColor="#c7ebfc" stopOpacity="0.88" />
                <stop offset="0" stopColor="#a8dffa" stopOpacity="0.81" />
                <stop offset="0" stopColor="#82d1f8" stopOpacity="0.72" />
                <stop offset="0" stopColor="#55c0f6" stopOpacity="0.63" />
                <stop offset="0" stopColor="#49bcf5" stopOpacity="0.6" />
              </linearGradient>
              <linearGradient
                className="linear-gradient-3"
                x1="220.19"
                y1="296.69"
                x2="400.73"
                y2="296.69"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#fe0275" stopOpacity="0.7" />
                <stop offset="0.83" stopColor="#fe0075" />
              </linearGradient>
            </defs>
            <polygon
              className="cls-1"
              points="195.96 257.57 259.39 80.67 489.66 202.57 401.7 477.23 144.8 438.52 195.96 257.57"
            />
            <polygon
              className="cls-2"
              points="184.87 382.56 73.97 217.36 259.39 149.84 322.82 257.57 333.91 382.56 184.87 382.56"
            />
            <polygon
              className="cls-3"
              points="259.39 210.81 444.81 217.36 333.91 382.56 220.19 333.23 259.39 210.81"
            />
            <line
              className="cls-4"
              x1="259.39"
              y1="80.67"
              x2="259.39"
              y2="34.77"
            />
            <polyline
              className="cls-4"
              points="259.39 149.84 259.39 210.81 259.39 278.48"
            />
            <line
              className="cls-4"
              x1="259.39"
              y1="278.48"
              x2="489.66"
              y2="202.57"
            />
            <line
              className="cls-4"
              x1="259.39"
              y1="278.48"
              x2="401.7"
              y2="477.23"
            />
            <line
              className="cls-4"
              x1="144.79"
              y1="438.51"
              x2="117.07"
              y2="477.23"
            />
            <polyline
              className="cls-4"
              points="184.87 382.55 220.19 333.23 259.39 278.48"
            />
            <line
              className="cls-4"
              x1="73.97"
              y1="217.36"
              x2="29.11"
              y2="202.57"
            />
            <polyline
              className="cls-4"
              points="138.82 238.73 195.96 257.57 259.39 278.48"
            />
            <line
              className="cls-4"
              x1="259.39"
              y1="34.77"
              x2="489.66"
              y2="202.57"
            />
            <line
              className="cls-4"
              x1="401.7"
              y1="477.23"
              x2="117.07"
              y2="477.23"
            />
            <line
              className="cls-4"
              x1="117.07"
              y1="477.23"
              x2="29.11"
              y2="202.57"
            />
            <line
              className="cls-4"
              x1="259.39"
              y1="34.77"
              x2="29.11"
              y2="202.57"
            />
            <polygon
              className="cls-4"
              points="184.87 382.55 144.81 438.51 144.79 438.51 73.97 217.36 138.82 238.73 184.87 382.55"
            />
            <polygon
              className="cls-4"
              points="259.39 80.67 259.39 149.84 138.82 238.73 73.97 217.36 259.39 80.67"
            />
            <polygon
              className="cls-4"
              points="444.81 217.36 373.99 438.51 144.81 438.51 184.87 382.55 138.82 238.73 259.39 149.84 259.39 80.67 444.81 217.36"
            />
            <polygon
              className="cls-4"
              points="379.96 238.73 333.91 382.55 184.87 382.55 138.82 238.73 259.39 149.84 379.96 238.73"
            />
            <polygon
              className="cls-4"
              points="259.39 210.81 322.82 257.57 298.59 333.23 220.19 333.23 195.96 257.57 259.39 210.81"
            />
          </svg>
        </div>
      );

    case "horizontalBarChart":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="horizontal-bar-chart"
            data-name="Layer 1"
            height="100%"
            width="100%"
            viewBox="0 0 512 512"
          >
            <defs />
            <line
              className="cls-1"
              x1="36.2"
              y1="106.25"
              x2="485.2"
              y2="106.15"
            />
            <line
              className="cls-1"
              x1="36.2"
              y1="307.75"
              x2="485.2"
              y2="307.65"
            />
            <line
              className="cls-1"
              x1="36.2"
              y1="206.25"
              x2="485.2"
              y2="206.25"
            />
            <line
              className="cls-1"
              x1="36.2"
              y1="407.15"
              x2="485.2"
              y2="407.15"
            />
            <line
              className="cls-1"
              x1="103.1"
              y1="18.75"
              x2="103.2"
              y2="467.75"
            />
            <line
              className="cls-1"
              x1="217.1"
              y1="23.75"
              x2="217.2"
              y2="472.75"
            />
            <line
              className="cls-1"
              x1="332.1"
              y1="23.75"
              x2="332.2"
              y2="472.75"
            />
            <line
              className="cls-1"
              x1="455.1"
              y1="23.75"
              x2="455.2"
              y2="472.75"
            />
            <path
              className="cls-2"
              d="M224.47,228.1V184.7c0-4-2.5-7.2-5.6-7.2H49.67c-3.1,0-5.6,3.2-5.6,7.2v43.4c0,4,2.5,7.2,5.6,7.2h169.2C222,235.3,224.47,232.1,224.47,228.1ZM213.27,192v0Z"
            />
            <path
              className="cls-3"
              d="M345.47,128V84.7c0-4-4.2-7.2-9.4-7.2H53.47c-5.2,0-9.4,3.2-9.4,7.2v43.4c0,4,4.2,7.2,9.4,7.2h282.6C341.27,135.3,345.47,132,345.47,128Zm-18.8-36.1v0Z"
            />
            <path
              className="cls-4"
              d="M293.47,428.2V384.8c0-4-3.5-7.2-7.8-7.2H51.87c-4.3,0-7.8,3.2-7.8,7.2v43.4c0,4,3.5,7.2,7.8,7.2h233.9A7.48,7.48,0,0,0,293.47,428.2ZM278,392.1v0Z"
            />
            <path d="M34.07,491.5V21.5a10.42,10.42,0,0,0-10.7-10.2,10.49,10.49,0,0,0-10.7,10.2v470a10.42,10.42,0,0,0,10.7,10.2C29.27,501.6,34.07,497.1,34.07,491.5Z" />
            <path d="M24.27,501.7h470a10.71,10.71,0,0,0,0-21.4h-470a10.71,10.71,0,0,0,0,21.4Z" />
            <path
              className="cls-5"
              d="M224.47,329.5V286.1c0-4-2.5-7.2-5.6-7.2H49.67c-3.1,0-5.6,3.2-5.6,7.2v43.4c0,4,2.5,7.2,5.6,7.2h169.2C222,336.7,224.47,333.5,224.47,329.5Zm-169.1-7.2h0Z"
            />
          </svg>
        </div>
      );

    case "verticalBarChart":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="vertical-bar-chart"
            data-name="Layer 1"
            height="100%"
            width="100%"
            viewBox="0 0 512 512"
          >
            <defs />
            <line
              className="cls-1"
              x1="37.2"
              y1="112.6"
              x2="486.2"
              y2="112.5"
            />
            <line className="cls-1" x1="37.2" y1="314.1" x2="486.2" y2="314" />
            <line
              className="cls-1"
              x1="37.2"
              y1="212.6"
              x2="486.2"
              y2="212.6"
            />
            <line
              className="cls-1"
              x1="37.2"
              y1="413.5"
              x2="486.2"
              y2="413.5"
            />
            <line
              className="cls-1"
              x1="104.1"
              y1="25.1"
              x2="104.2"
              y2="474.1"
            />
            <line
              className="cls-1"
              x1="218.1"
              y1="30.1"
              x2="218.2"
              y2="479.1"
            />
            <line
              className="cls-1"
              x1="333.1"
              y1="30.1"
              x2="333.2"
              y2="479.1"
            />
            <line
              className="cls-1"
              x1="456.1"
              y1="30.1"
              x2="456.2"
              y2="479.1"
            />
            <path
              className="cls-2"
              d="M237.75,294.41h-43.4c-4,0-7.2,2.5-7.2,5.6v169.2c0,3.1,3.2,5.6,7.2,5.6h43.4c4,0,7.2-2.5,7.2-5.6V300C245,296.91,241.75,294.41,237.75,294.41Zm-7.2,169.1h0Z"
            />
            <path
              className="cls-3"
              d="M126.65,173.41H83.35c-4,0-7.2,4.2-7.2,9.4v282.6c0,5.2,3.2,9.4,7.2,9.4h43.4c4,0,7.2-4.2,7.2-9.4V182.81C134,177.61,130.65,173.41,126.65,173.41ZM119.45,456h0Z"
            />
            <path
              className="cls-4"
              d="M477.85,225.41h-43.4c-4,0-7.2,3.5-7.2,7.8V467c0,4.3,3.2,7.8,7.2,7.8h43.4c4,0,7.2-3.5,7.2-7.8V233.11A7.48,7.48,0,0,0,477.85,225.41Zm-7.2,233.8h0Z"
            />
            <path d="M491.05,484.81h-470a10.71,10.71,0,0,0,0,21.4h470a10.71,10.71,0,0,0,0-21.4Z" />
            <path d="M32.25,495.61v-470a10.42,10.42,0,0,0-10.7-10.2,10.42,10.42,0,0,0-10.7,10.2v470a10.42,10.42,0,0,0,10.7,10.2C27.45,505.71,32.25,501.21,32.25,495.61Z" />
            <path
              className="cls-5"
              d="M352.15,294.41h-43.4c-4,0-7.2,2.5-7.2,5.6v169.2c0,3.1,3.2,5.6,7.2,5.6h43.4c4,0,7.2-2.5,7.2-5.6V300C359.35,296.91,356.15,294.41,352.15,294.41ZM345,463.51h0Z"
            />
          </svg>
        </div>
      );

    case "polarChart":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="polar-chart"
            data-name="Layer 1"
            height="100%"
            width="100%"
            viewBox="0 0 512 512"
          >
            <defs />

            <path d="M254.5,70.06a187,187,0,1,1-73,14.74,186.25,186.25,0,0,1,73-14.74m0-1C150.39,69.06,66,153.46,66,257.56s84.39,188.5,188.5,188.5S443,361.67,443,257.56,358.61,69.06,254.5,69.06Z" />
            <circle className="cls-2" cx="255" cy="257.56" r="244.5" />
            <circle className="cls-1" cx="254.5" cy="257.56" r="188" />
            <circle className="cls-3" cx="254.5" cy="258.06" r="133.5" />
            <circle className="cls-4" cx="254.5" cy="258.06" r="91.5" />
            <path
              className="cls-5"
              d="M254.5,258.06,91.33,161.49A186.92,186.92,0,0,1,254.5,69.06Z"
            />
            <path
              className="cls-6"
              d="M254.5,258.06V124.56c11.2-.09,47.76,1,82,27.5A132.94,132.94,0,0,1,381.56,217Z"
            />
            <path
              className="cls-7"
              d="M254.5,258.06l233.43-75c6.6,21.12,21.34,78.17.57,146a248.27,248.27,0,0,1-72.4,112.43Z"
            />
            <path
              className="cls-8"
              d="M254.5,259.06,315,327.71c-5.88,5-27.81,22.31-60.48,22.35a94.59,94.59,0,0,1-54.66-17.61Z"
            />
            <path
              className="cls-9"
              d="M254.5,258.06,175,365.28A127,127,0,0,1,120.5,258.06Z"
            />
            <path
              className="cls-10"
              d="M254.5,258.06H163a92.06,92.06,0,0,1,12.75-46.6Z"
            />
          </svg>
        </div>
      );

    case "bubbleChart":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="bubble-chart"
            data-name="Layer 1"
            height="100%"
            width="100%"
            viewBox="0 0 512 512"
          >
            <defs />
            <line
              className="cls-1"
              x1="40.44"
              y1="103.75"
              x2="489.44"
              y2="103.65"
            />
            <line
              className="cls-1"
              x1="40.44"
              y1="305.25"
              x2="489.44"
              y2="305.15"
            />
            <line
              className="cls-1"
              x1="40.44"
              y1="203.75"
              x2="489.44"
              y2="203.75"
            />
            <line
              className="cls-1"
              x1="40.44"
              y1="404.65"
              x2="489.44"
              y2="404.65"
            />
            <line
              className="cls-1"
              x1="107.34"
              y1="16.25"
              x2="107.44"
              y2="465.25"
            />
            <line
              className="cls-1"
              x1="221.34"
              y1="21.25"
              x2="221.44"
              y2="470.25"
            />
            <line
              className="cls-1"
              x1="336.34"
              y1="21.25"
              x2="336.44"
              y2="470.25"
            />
            <line
              className="cls-1"
              x1="459.34"
              y1="21.25"
              x2="459.44"
              y2="470.25"
            />
            <path d="M28.79,12.68h-16v472a8,8,0,0,0,8,8h472v-16h-464Z" />
            <path d="M487.59,476.88h-470a10.71,10.71,0,0,0,0,21.4h470a10.71,10.71,0,0,0,0-21.4Z" />
            <path d="M28.79,487.68v-470a10.42,10.42,0,0,0-10.7-10.2,10.42,10.42,0,0,0-10.7,10.2v470a10.42,10.42,0,0,0,10.7,10.2C24,497.78,28.79,493.28,28.79,487.68Z" />
            <circle className="cls-2" cx="328.79" cy="409.69" r="58.25" />
            <circle className="cls-2" cx="99.79" cy="108.77" r="58.25" />
            <circle className="cls-3" cx="328.79" cy="208.77" r="47.5" />
            <circle className="cls-3" cx="99.79" cy="310.25" r="63" />
            <circle className="cls-4" cx="328.77" cy="108.75" r="32.58" />
            <circle className="cls-4" cx="213.81" cy="310.25" r="32.58" />
          </svg>
        </div>
      );

    case "areaChart":
      return (
        <div className="svg-chart-icon-holder">
          <svg
            className="area-chart"
            data-name="Layer 1"
            height="100%"
            width="100%"
            viewBox="0 0 512 512"
          >
            <defs />
            <line
              className="cls-1"
              x1="47.41"
              y1="111.56"
              x2="496.41"
              y2="111.46"
            />
            <line
              className="cls-1"
              x1="47.41"
              y1="313.06"
              x2="496.41"
              y2="312.96"
            />
            <line
              className="cls-1"
              x1="47.41"
              y1="211.56"
              x2="496.41"
              y2="211.56"
            />
            <line
              className="cls-1"
              x1="47.41"
              y1="412.46"
              x2="496.41"
              y2="412.46"
            />
            <line
              className="cls-1"
              x1="114.31"
              y1="24.06"
              x2="114.41"
              y2="473.06"
            />
            <line
              className="cls-1"
              x1="228.31"
              y1="29.06"
              x2="228.41"
              y2="478.06"
            />
            <line
              className="cls-1"
              x1="343.31"
              y1="29.06"
              x2="343.41"
              y2="478.06"
            />
            <line
              className="cls-1"
              x1="466.31"
              y1="29.06"
              x2="466.41"
              y2="478.06"
            />
            <path d="M33.35,490V20A10.42,10.42,0,0,0,22.65,9.8C16.75,9.8,12,14.3,12,19.9v470a10.42,10.42,0,0,0,10.7,10.2C28.55,500.1,33.35,495.6,33.35,490Z" />
            <path
              className="cls-2"
              d="M465.25,158.89V472.6H81.45l.06-57.4A38.6,38.6,0,0,0,100.76,407c2.63-2.16,9.25-7.56,11.52-16.69a33,33,0,0,0,.63-11.34c-.84-7.33-3.51-9-2.86-13.05.62-3.87,3.4-4.72,11-11.27a150,150,0,0,0,18-18.35l-.11.09c4.22-3.63,10.93-10.7,24.36-24.84,20-21.05,30-31.58,35.88-39.27,3.65-4.75,8.39-8.48,12.36-12.94,3.28-3.68,6.29-7.28,10-6.91,1.85.19,2.17,1.22,5,2.41a19.41,19.41,0,0,0,10,1.21c6.79-.91,8.2-5.1,12.68-4.06,1.74.41,2.91,1.41,5.24,3.4,3.45,3,4.94,5.57,6.81,8.12a28.8,28.8,0,0,0,5,5.43c2.08,1.7,2.67,1.92,5.17,4a39.39,39.39,0,0,1,5.39,5.3c2.12,2.93.38,5.83-.13,13.76-.3,4.52-.53,8.1.42,11.92,1.87,7.5,7.35,12.12,10.15,14.47,3.28,2.76,9.22,7.76,17.7,8,6.45.15,11.49-2.55,16.76-5.35a27.88,27.88,0,0,0,6.5-4.5,22.48,22.48,0,0,0,6.08-9c1.25-3.67-.18-3.15.62-13.91.21-2.69.42-4.36-.42-6.42-.91-2.28-2.24-2.92-2.51-4.92-.23-1.74.48-3.32,1.68-5.14,4.08-6.22,8.36-7,13.75-12.93.71-.77,4.28-4.74,4.82-5.35l2.31-2.57c3.76-4.5,9.85-11.59,17.81-19.9,18.24-19.05,19.88-16.95,31.5-30.54,4.38-5.12,21-25.08,25.88-30.22,7.38-7.74,7.4-6.53,11-11.08s5.25-7.9,8.49-8.06c2.43-.12,3.31,1.75,6.86,2.8A15.48,15.48,0,0,0,465.25,158.89Z"
            />
            <path d="M23.55,502.2h470a10.71,10.71,0,0,0,0-21.4h-470a10.71,10.71,0,0,0,0,21.4Z" />
            <path
              className="cls-3"
              d="M318.14,273.61l126.73-127L457,158.78l-87.32,87.49-39.41,39.49Z"
            />
            <rect
              className="cls-4"
              x="262.74"
              y="237.01"
              width="17.2"
              height="50.1"
              transform="rotate(-45.11 271.295 262.045)"
            />
            <rect
              className="cls-3"
              x="71.02"
              y="295.38"
              width="179.4"
              height="17.2"
              transform="rotate(-45.06 160.707 303.963)"
            />
            <path d="M468.75,101.8a33.5,33.5,0,1,0,33.5,33.5A33.44,33.44,0,0,0,468.75,101.8Zm0,49.8a16.3,16.3,0,1,1,16.3-16.3A16.3,16.3,0,0,1,468.75,151.6Z" />
            <path
              className="cls-5"
              d="M81.45,348.9A33.5,33.5,0,1,0,115,382.4,33.44,33.44,0,0,0,81.45,348.9Zm0,49.9a16.3,16.3,0,1,1,16.3-16.3A16.3,16.3,0,0,1,81.45,398.8Z"
            />
            <path d="M236,193.2a33.5,33.5,0,1,0,33.5,33.5A33.44,33.44,0,0,0,236,193.2Zm0,49.9a16.3,16.3,0,1,1,16.3-16.3A16.3,16.3,0,0,1,236,243.1Z" />
            <path
              className="cls-4"
              d="M306.55,263.9a33.5,33.5,0,1,0,33.5,33.5A33.44,33.44,0,0,0,306.55,263.9Zm0,49.8a16.3,16.3,0,1,1,16.3-16.3A16.3,16.3,0,0,1,306.55,313.7Z"
            />
            <circle className="cls-6" cx="235.95" cy="226.7" r="16.3" />
            <circle className="cls-7" cx="306.55" cy="297.4" r="16.3" />
            <circle className="cls-6" cx="468.75" cy="135.3" r="16.3" />
            <circle className="cls-7" cx="81.45" cy="382.4" r="16.3" />
          </svg>
        </div>
      );

      case "timeline":
      return (
        <div className="svg-chart-icon-holder">
          <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"  height="100%" width="100%" viewBox="0 0 512 512">
            <g id="g843" transform="translate(29.555)">
                <path d="M 88.252008,273.04559 H 377.02723 a 6.4022084,8.3072802 0 0 0 6.26705,-8.53051 6.4022084,8.3072802 0 0 0 -6.26705,-8.53051 H 88.252008 a 6.4022084,8.3072802 0 0 0 -6.267039,8.53051 c 0.06144,4.71171 2.82631,8.53051 6.267039,8.53051 z"
                id="path827" strokeWidth="0.7" />
                <path id="path829" d="M 96.082944,126.06254 H 377.07103 a 6.2295655,7.7791002 0 0 0 6.09803,-7.98814 6.2295655,7.7791002 0 0 0 -6.09803,-7.98813 H 96.082944 a 6.2295655,7.7791002 0 0 0 -6.098039,7.98813 c 0.05979,4.41214 2.750096,7.98814 6.098039,7.98814 z"
                strokeWidth="0.668" />
                <path id="path831" d="M 94.133799,420.02866 H 377.46416 a 6.2814944,8.3072802 0 0 0 6.14886,-8.53051 6.2814944,8.3072802 0 0 0 -6.14886,-8.53051 H 94.133799 a 6.2814944,8.3072802 0 0 0 -6.148871,8.53051 c 0.06029,4.71171 2.77302,8.53051 6.148871,8.53051 z"
                strokeWidth="0.693" />
                <path id="path14" d="M 138.22542,491.37949 V 21.379492 a 10.42,10.42 0 0 0 -10.7,-10.2 10.42,10.42 0 0 0 -10.7,10.2 V 491.37949 a 10.42,10.42 0 0 0 10.7,10.2 c 5.91,-0.1 10.7,-4.6 10.7,-10.2 z"
                />
                <g id="g22" transform="translate(-139.585)">
                    <circle id="circle20" r="58.25" cy="115.49" cx="267.11" className="cls-2"
                    fill="#fe0075" />
                </g>
                <g id="g26" transform="translate(-139.585 140)">
                    <circle id="circle24" r="58.25" cy="115.49" cx="267.11" className="cls-2"
                    fill="#33dbc6" />
                </g>
                <g id="g38" transform="translate(-139.585 291.8)">
                    <circle id="circle28" r="58.25" cy="115.49" cx="267.11" className="cls-2"
                    fill="#fde101" />
                    <g id="g36" fill="#fff" strokeWidth="0.524">
                        <ellipse id="ellipse30" ry="30.695" rx="30.37" cy="115.49" cx="267.11"
                        className="cls-2" />
                        <ellipse id="ellipse32" ry="30.695" rx="30.37" cy="-36.304" cx="267.11"
                        className="cls-2" />
                        <ellipse id="ellipse34" ry="30.695" rx="30.37" cy="-176.3" cx="267.11"
                        className="cls-2" />
                    </g>
                </g>
            </g>
        </svg>
        </div>
      );

      case "noscores":
      return (
        <div className="svg-chart-icon-holder">
          <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="100%" height="100%" viewBox="0 0 512 512"
id="svg40">
    <defs id="defs8" />
    <path d="M 82.398859,435.11676 448.77414,97.680424 a 13.789538,11.042772 47.354532 0 0 -1.6418,-17.738667 13.789538,11.042772 47.354532 0 0 -17.54398,-3.092495 L 63.213073,414.28558 a 13.789538,11.042772 47.354532 0 0 1.641767,17.73869 c 5.376467,5.68107 13.178693,7.11299 17.544019,3.09249 z"
    id="path829" strokeWidth="1.184" />
    <g transform="matrix(1.77264 0 0 1.77264 -217.49 51.278)" id="g22">
        <circle className="cls-2" cx="267.11" cy="115.49" r="58.25" id="circle20"
        fill="#fe0075" />
    </g>
    <g id="g896" transform="matrix(1.77264 0 0 1.77264 -217.49 51.278)">
        <circle id="circle894" r="30.713" cy="115.49" cx="267.11" className="cls-2"
        fill="#fff" strokeWidth="0.527" />
    </g>
</svg>
        </div>
      );
  }
};
 
export default ChartSvgIcons;
