import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { generateId } from "../../lib/utils";
import TimelineItem from "../../components/Timeline/TimelineItem";

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timelineItems: []
    };
  }

  componentDidMount() {
    this.setTimeline();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.individualEpisodeData !== this.props.individualEpisodeData) {
      this.setTimeline();
    }
  }

  setTimeline = () => {
    if (this.props.individualEpisodeData[0]) {
      let data = this.props.individualEpisodeData[0];
      let ops = data.operations ? data.operations.operationItems : [];
      let diag = data.diagnoses
        ? data.diagnoses.diagnosisItems
            .filter(x => x.isMain)
            .map(e =>
              Object.assign({}, e, {
                date: moment(e.date)
                  .add({ hours: 1 })
                  .format()
              })
            )
        : [];
      // mod the date to make sure plans don't precede visits on the timeline
      // e.g. visit & plan on the same day...plan is always result of a visit
      let plans = data.plans
        ? data.plans.planItems.map(e =>
            Object.assign({}, e, {
              date: moment(e.date)
                .add({ hours: 2 })
                .format()
            })
          )
        : [];
      // mod the date to make complications come after x
      let comps = data.complications
        ? data.complications.complicationItems.map(e =>
            Object.assign({}, e, {
              date: moment(e.date)
                .add({ hours: 3 })
                .format()
            })
          )
        : [];
      let visits = data.visits ? data.visits.visitItems : [];

      let timeline = [...ops, ...diag, ...plans, ...comps, ...visits].sort(
        (a, b) => moment(a.date).unix() - moment(b.date).unix()
      );
      this.setState({
        timelineItems: timeline.map(e => (
          <TimelineItem key={generateId()} data={e} />
        ))
      });
    } else {
      this.setState({
        timelineItems: []
      });
    }
  };

  render() {
    return (
      <div className="timeline">
        {this.state.timelineItems.length > 0 ? (
          <React.Fragment>
            <div className="empty-line-holder">
              <div className="timeline-spacer-holder">
                <span className="spacer" />
                <span className="terminator" />
              </div>
            </div>
            {this.state.timelineItems}
            <div className="empty-line-holder">
              <div className="timeline-spacer-holder">
                <span className="spacer" />
                <span className="terminator" />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div>No information to build a patient timeline</div>
        )}
      </div>
    );
  }
}

Timeline.propTypes = {
  individualEpisodeData: PropTypes.array.isRequired
};

export default Timeline;
