import React from "react";
import PropTypes from "prop-types";
import SvgIcons from "../../Misc/SvgIcons";

class DropdownList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownToggled: false,
      result: this.props.placeholder.length > 0 ? this.props.placeholder : ""
    };
  }

  selection = (e, option) => {
    e.preventDefault();
    this.setState({ result: option, dropdownToggled: false }, () =>
      this.props.onChangeFunc(this.props.name, this.state.result)
    );
  };

  // Renders options passed down from parent / passes selected option
  renderOptions = () => {
    let options = this.props.options.map((option, i) => (
      <li
        key={option + i}
        onClick={e => {
          this.selection(e, option);
        }}
      >
        {option}
        <SvgIcons icon="arrowDown" />
      </li>
    ));
    return options;
  };

  // Show/Hide Dropdown
  showDropdown = event => {
    event.preventDefault();
    this.setState({
      dropdownToggled: !this.state.dropdownToggled
    });
  };

  render() {
    return (
      <div
        className={
          this.props.isValid !== null
            ? this.props.isValid
              ? `input-holder dropdown-holder ${this.props.name}-holder valid`
              : `input-holder dropdown-holder ${this.props.name}-holder invalid`
            : `input-holder dropdown-holder ${this.props.name}-holder`
        }
      >
        <label htmlFor={`${this.props.name}`}>{this.props.label}</label>
        <div className="dropdown-element">
          <a
            href="./"
            onClick={this.showDropdown}
            className="dropdown-toggle"
            name={this.props.name}
          >
            {this.state.result}
            <SvgIcons icon="arrowDown" />
          </a>
          <ul
            className={
              this.state.dropdownToggled
                ? "dropdown-options-container"
                : "dropdown-options-container hidden"
            }
          >
            {this.renderOptions()}
          </ul>
        </div>
      </div>
    );
  }
}

DropdownList.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeFunc: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired
};

export default DropdownList;
