import React, { Component } from "react";
import PropTypes from "prop-types";
import { ALL_GROUPS_QUERY, SINGLE_GROUP_QUERY } from "../../gql/GroupGQL";
import { CREATE_NEW_EPISODES_GROUP } from "../../gql/EpisodeGQL";
import { client } from "../../store/configureClient";

import GroupsListMenu from "../GroupsListMenu/GroupsListMenu.js";
import GroupsCompareView from "../GroupsCompareView/GroupsCompareView";
import GroupsOverview from "../GroupsOverview/GroupsOverview";
import GroupsSingleView from "../GroupsSingleView/GroupsSingleView";
import GroupsCompareList from "../GroupsCompareList/GroupsCompareList";
import Loading from "../../components/Loading/Loading";
import GroupsMaker from "../GroupsMaker/GroupsMaker";
import SvgIcons from "../../components/Misc/SvgIcons";

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroupId: "",
      selectedGroupName: "",
      groups: [{ groups: [] }],
      loading: "",
      selectedGroup: [],
      tempGroup: [],
      groupMakerActive: false,
      compareGroupsNames: [],
      compareGroupsIds: [],
      groupsListMenuActive: false,
      groupsCompareListActive: false,
      groupsMakerActive: false,
      loadingOverlayActive: false,
      sectionInView: "overview",
      resetToOverviewAvailable: false
    };
  }

  componentDidMount() {
    // Gets all consultant groups on mount
    this.getAllGroups();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.groupStateIndicator !== 0 &&
      prevProps.groupStateIndicator !== this.props.groupStateIndicator
    ) {
      console.log("done the group gets")
      this.getAllGroups();
    }
  }

  /*************************************************
   * GQL QUERIES
   **************************************************/

  // Gets all groups names & id's used in GroupsListMenu
  getAllGroups = () => {
    client
      .query({
        query: ALL_GROUPS_QUERY,
        variables: { scoresName: this.props.currentUser.scoresName },
        fetchPolicy: "network-only"
      })
      .then(({ data }) => {
        this.setState({ groups: data.Consultant });
      })
      .catch(error => {
        this.props.renderMessages("invalid", "Couldn't get groups", true);
      });
  };

  // Gets selected group and passes value to GroupsSingleView
  getSelectedGroup = () => {
    this.setState({ selectedGroup: [] });
    // toggle overlay loading component
    this.toggleLoadingOverlay(true);
    client
      .query({
        query: SINGLE_GROUP_QUERY,
        variables: { _id: this.state.selectedGroupId }
      })
      .then(({ data, networkStatus }) => {
        // Using networkStatus key on response to delay render
        // until all data is in
        if (networkStatus === 7) {
          this.setState({
            selectedGroup: data.Group,
            sectionInView: "groupsSingleView"
          });
          this.toggleLoadingOverlay(false);
        }
      })
      .catch(error => {
        this.props.renderMessages("invalid", "Couldn't get group", true);
        this.toggleLoadingOverlay(false);
      });
  };

  // Creates a new group based upon passed in episode ids
  //  Fired from GroupMaker
  createGroup = submission => {
    // toggle overlay loading component
    this.toggleLoadingOverlay(true);
    client
      .mutate({
        mutation: CREATE_NEW_EPISODES_GROUP,
        variables: {
          title: submission.newGroupMakerName,
          owner: this.props.currentUser.scoresName,
          episodeGroupIds: [
            ...this.state.tempGroup.map(x => {
              return {
                externalId: x,
                owner: this.props.currentUser.scoresName
              };
            })
          ]
        },
        refetchQueries: [
          {
            query: ALL_GROUPS_QUERY,
            variables: { scoresName: this.props.currentUser.scoresName }
          }
        ],
        awaitRefetchQueries: true
      })
      .then(({ data }) => {
        this.props.renderMessages("valid", "Group created", true);
        this.updateGroups();
        this.toggleLoadingOverlay(false);
        this.setState({ groupsListMenuActive: true, tempGroup: [] });
      })
      .catch(error => {
        console.log(error);
        this.props.renderMessages("invalid", "Couldn't create group", true);
        this.toggleLoadingOverlay(false);
      });
  };

  // Local cache query to update ui after new group made
  updateGroups = () => {
    const updated = client.readQuery({
      query: ALL_GROUPS_QUERY,
      variables: { scoresName: this.props.currentUser.scoresName }
    });

    this.setState({ groups: updated.Consultant });
  };

  // Toggle loading overlay screen can be called in query to handling
  // onloading status
  toggleLoadingOverlay = bool => {
    this.setState({ loadingOverlayActive: bool });
  };

  /*************************************************
   * COMPARE GROUP FUNCS
   **************************************************/

  // Creates a compare group based upon group ids
  selectCompareGroup = (selectedCompareGroupName, selectedCompareGroupId) => {
    let compareGroupsNames = this.state.compareGroupsNames;
    let compareGroupsIds = this.state.compareGroupsIds;

    this.setState({
      compareGroupsNames: [
        ...new Set([...compareGroupsNames, selectedCompareGroupName])
      ],
      compareGroupsIds: [
        ...new Set([...compareGroupsIds, selectedCompareGroupId])
      ],
      groupsCompareListActive: true
    });
  };

  // Removes group from compare group list
  removeCompareGroup = (selectedCompareGroupName, selectedCompareGroupId) => {
    let compareGroupNames = this.state.compareGroupsNames.filter(
      x => x !== selectedCompareGroupName
    );

    let compareGroupIds = this.state.compareGroupsIds.filter(
      x => x !== selectedCompareGroupId
    );

    this.setState({
      compareGroupsNames: compareGroupNames,
      compareGroupsIds: compareGroupIds
    });
  };

  // Puts compare group component in view,
  // and passes compare group names down
  // compare groups loaded from GroupsCompareView
  showCompareGroups = () => {
    this.setState({
      groupsListMenuActive: false,
      groupsCompareListActive: false,
      groupsMakerActive: false,
      sectionInView: "groupsCompareView"
    });
  };

  /*************************************************
   * GROUPS SINGLE VIEW FUNCS
   **************************************************/

  // Adds selected group to state and passes to GroupsSingleView
  selectGroup = (selectedGroupId, selectedGroupName) => {
    this.setState(
      {
        selectedGroupId: selectedGroupId,
        selectedGroupName: selectedGroupName,
        groupsListMenuActive: false,
        groupsCompareListActive: false,
        groupsMakerActive: false
      },
      this.getSelectedGroup
    );
    this.props.setGroupInView(selectedGroupId);
  };

  /*************************************************
   * GROUPS MAKER FUNCS
   **************************************************/

  // Adds episodeIds to temp group,
  // used to create groups from other groups
  addToTempGroup = episodeIds => {
    let arr = Array.from(this.state.tempGroup);
    arr.push(...episodeIds);

    // dedupes externalIds
    let tempGroup = [...new Set(arr)];

    this.setState({ tempGroup: tempGroup, groupsMakerActive: true });
  };

  clearTempGroup = () => {
    this.setState({ tempGroup: [], groupsMakerActive: true });
  };

  /*************************************************
   * UI FUNCS
   **************************************************/
  // Toggles tab menu in and out
  toggleTabMenu = selection => {
    this.setState({
      [selection]: !this.state[selection],
      resetToOverviewAvailable: true
    });
  };

  // Resets back to overview container
  resetToOverview = () => {
    this.setState({
      groupsListMenuActive: false,
      groupsCompareListActive: false,
      groupsMakerActive: false,
      sectionInView: "overview",
      resetToOverviewAvailable: false
    });
  };

  render() {
    const {
      compareGroupsNames,
      groups,
      tempGroup,
      selectedGroup,
      compareGroupsIds
    } = this.state;
    return (
      <div className="groups">
        <div className="groups-content">
          <div className="groups-sidebar">
            <div className="groups-sidebar-content">
              <div
                className={
                  this.state.groupsListMenuActive
                    ? "groups-sidebar-content-inner active"
                    : "groups-sidebar-content-inner"
                }
              >
                <GroupsListMenu
                  results={groups}
                  selectGroup={this.selectGroup}
                  totalEpisodes={this.props.totalEpisodes}
                  selectCompareGroup={this.selectCompareGroup}
                  removeCompareGroup={this.removeCompareGroup}
                />
              </div>
              <div
                className={
                  this.state.groupsCompareListActive
                    ? "groups-sidebar-content-inner active"
                    : "groups-sidebar-content-inner"
                }
              >
                <GroupsCompareList
                  compareGroupsNames={compareGroupsNames}
                  showCompareGroups={this.showCompareGroups}
                  removeCompareGroup={this.removeCompareGroup}
                />
              </div>
              <div
                className={
                  this.state.groupsMakerActive
                    ? "groups-sidebar-content-inner active"
                    : "groups-sidebar-content-inner"
                }
              >
                <GroupsMaker
                  tempGroup={tempGroup}
                  clearTempGroup={this.clearTempGroup}
                  createGroup={this.createGroup}
                  groupSubheading="When viewing a group, you can select episodes from the Diagnosis and
                    Operations grids. They'll be added in here and then you can
                    give your new group a name."
                />
              </div>
            </div>
            <div className="groups-sidebar-tabs">
              {/* <div
                className={
                  this.state.resetToOverviewAvailable
                    ? "groups-sidebar-tab groups-sidebar-tab-overview reset"
                    : "groups-sidebar-tab groups-sidebar-tab-overview"
                }
                onClick={this.resetToOverview}
              >
                <SvgIcons icon={"group"} />
              </div> */}
              <div
                className={
                  this.state.groupsListMenuActive
                    ? "groups-sidebar-tab groups-sidebar-tab-list-menu active"
                    : "groups-sidebar-tab groups-sidebar-tab-list-menu"
                }
                onClick={() => {
                  this.toggleTabMenu("groupsListMenuActive");
                }}
              >
                <SvgIcons icon={"list"} />
                <span className="groups-sidebar-tab-text">Groups list</span>
              </div>
              <div
                className={
                  this.state.groupsCompareListActive
                    ? "groups-sidebar-tab groups-sidebar-tab-compare active"
                    : "groups-sidebar-tab groups-sidebar-tab-compare"
                }
                onClick={() => {
                  this.toggleTabMenu("groupsCompareListActive");
                }}
              >
                <SvgIcons icon={"compare"} />
                <span className="groups-sidebar-tab-text">Groups Compare</span>
              </div>
              <div
                className={
                  this.state.groupsMakerActive
                    ? "groups-sidebar-tab groups-sidebar-tab-group-maker active"
                    : "groups-sidebar-tab groups-sidebar-tab-group-maker"
                }
                onClick={() => {
                  this.toggleTabMenu("groupsMakerActive");
                }}
              >
                <SvgIcons icon={"group"} />
                <span className="groups-sidebar-tab-text">Groups Maker</span>
              </div>
            </div>
          </div>

          <div className="groups-inner-content">
            <Loading loadingOverlayActive={this.state.loadingOverlayActive} />
            {this.state.sectionInView === "overview" && (
              <GroupsOverview
                addToTempGroup={this.addToTempGroup}
                createGroup={this.createGroup}
                firstGroup={this.state.groups}
                setGroupInView={this.props.setGroupInView}
              />
            )}
            {this.state.sectionInView === "groupsSingleView" &&
              selectedGroup.length > 0 && (
                <GroupsSingleView
                  results={selectedGroup}
                  addToTempGroup={this.addToTempGroup}
                  createGroup={this.createGroup}
                />
              )}
            {this.state.sectionInView === "groupsCompareView" && (
              <GroupsCompareView
                compareGroupsIds={compareGroupsIds}
                addToTempGroup={this.addToTempGroup}
                createGroup={this.createGroup}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Groups.propTypes = {
  currentUser: PropTypes.shape({
    scoresName: PropTypes.string.isRequired
  }),
  renderMessages: PropTypes.func,
  toggleLoadingOverlay: PropTypes.func,
  setGroupInView: PropTypes.func,
  totalEpisodes: PropTypes.number,
  groupStateIndicator: PropTypes.number,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func
  })
};

export default Groups;
