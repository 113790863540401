/****************************************
 * DEFAULTS FOR CHARTS
 *****************************************/
// Chart sizes
export const chartWidth = 400;
export const chartHeight = 400;

// Default colors used
export const bgColors = [
  "rgb(0, 169, 255, 1)",
  "rgb(107, 70, 175, 1)",
  "rgb(58, 44, 84, 1)",
  "rgb(0, 228, 200, 1)",
  "rgb(162, 173, 172, 1)",
  "rgb(246, 80, 88,1)",
  "rgb(52, 62, 72,1)",
  "rgb(187, 187, 187,1)"
];

export const borderColors = [
  "rgb(0, 169, 255, 0.5)",
  "rgb(107, 70, 175, 0.5)",
  "rgb(58, 44, 84, 0.5)",
  "rgb(0, 228, 200, 0.5)",
  "rgb(162, 173, 172, 0.5)",
  "rgb(246, 80, 88,0.5)",
  "rgb(52, 62, 72,0.5)",
  "rgb(187, 187, 187,0.5)"
];

// // Points specifics
export const pointBackgroundColors = [
  "rgba(51, 219, 198,1)",
  "rgba(254, 0, 117,1)",
  "rgba(253, 225, 1,1)",
  "rgba(152, 139, 200,1)",
  "rgba(254, 135, 77,1)",
  "rgba(129, 66, 253,1)",
  "rgba(231, 207, 205,1)",
  "rgba(26,140,255, 1)"
];

export const pointBorderColor = "#ffffff";

// // Legends
// export const legendDefaults = {
//   display: true,
//   position: "bottom",
//   labels: {
//     boxWidth: 40,
//     fontSize: 12,
//     fontColor: "#666",
//     fontFamily: "Rubik"
//   }
// };
