import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

class Complication extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = this.props.data;
    return (
      <div className="complication-timeline-item">
        <h3 className="timeline-item-title">
          <span className="pre-title">Complication:</span> {data.type}
        </h3>
        {data.date ? (
          <h4 className="content-date">
            {moment(data.date).format("dddd, MMMM Do YYYY")}
          </h4>
        ) : null}
      </div>
    );
  }
}

Complication.propTypes = {
  data: PropTypes.object.isRequired
};

export default Complication;
