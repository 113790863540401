import React from "react";
import PropTypes from "prop-types";

class Radios extends React.Component {
  // Function to render checkboxes based upon passed in options array
  renderRadios = () => {
    let checkboxList = [];
    this.props.options.map((radio, i) => {
      checkboxList.push(
        <div className="radio-holder item" key={radio + i}>
          <input
            type="radio"
            name={this.props.name}
            value={radio}
            onChange={this.props.onChangeFunc}
            disabled={this.props.disabled}
            id={`${this.props.name}${radio}`}
          />
          <label htmlFor={`${this.props.name}${radio}`}>{radio}</label>
        </div>
      );
    });
    return checkboxList;
  };

  render() {
    return (
      <div
        className={
          this.props.isValid !== null
            ? this.props.isValid
              ? `input-holder radio-holder ${this.props.name}-holder valid`
              : `input-holder radio-holder ${this.props.name}-holder invalid`
            : `input-holder radio-holder ${this.props.name}-holder`
        }
      >
        <label>{this.props.label}</label>
        <div className="items-container">{this.renderRadios()}</div>
      </div>
    );
  }
}

Radios.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeFunc: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default Radios;
