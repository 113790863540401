import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ThemeToggle from "../ThemeToggle/ThemeToggle";

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.header.main
  },
  grow: {
    flexGrow: 1
  },
  headerLogo: {
    color: theme.palette.header.logo,
    fontSize: "32px",
    textTransform: "uppercase",
    fontWeight: "300",
    letterSpacing: "2px",
    flexGrow: 1
  },
  headerUserName: {
    color: theme.palette.primary.contrastText,
    fontSize: "20px",
    fontWeight: "300"
  },
  styledButton: {
    fontWeight: 600,
    textTransform: "capitalize",
    fontSize: 16,
    color: theme.palette.primary.main,
    lineHeight: 2,
    padding: 10,
    "&:hover": {
      backgroundColor: theme.palette.header.main,
      color: theme.palette.header.contrastText
    }
  }
});

class Header extends Component {
  state = {
    right: false,
    availableItems: [
      {
        linkUrl: "/profile",
        linkText: "Profile"
      },
      {
        linkUrl: "",
        linkText: "Groups"
      },
      {
        linkUrl: "/patients",
        linkText: "Patients"
      }
    ]
  };

  toggleDrawer = (side, isOpen, item) => () => {
    this.setState({
      [side]: isOpen,
      clickedItem: item
    });
  };

  sideList = () => {
    let output = [];
    this.state.availableItems.map((item, index) => {
      output.push(
        <React.Fragment key={index + item}>
          <ListItem>
            <Button
              className={this.props.classes.styledButton}
              component={Link}
              to={`${this.props.match.url}${item.linkUrl}`}
              onClick={this.toggleDrawer("right", false, "")}
            >
              {item.linkText}
            </Button>
          </ListItem>
          <Divider />
        </React.Fragment>
      );
    });
    return output;
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography
              className={classes.headerLogo}
              onClick={() => this.props.history.push(`/dashboard`)}
            >
              SUMOCO
            </Typography>

            <Typography
              component="span"
              className={classes.headerUserName}
              onClick={() => this.props.history.push(`/dashboard/profile`)}
            >
              Welcome {this.props.currentUser.scoresName}
              <IconButton color="inherit" aria-label="Menu">
                <AccountCircle />
              </IconButton>
            </Typography>

            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={this.toggleDrawer("right", true)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <SwipeableDrawer
            anchor="right"
            open={this.state.right}
            onClose={this.toggleDrawer("right", false, "")}
            onOpen={this.toggleDrawer("right", true, "")}
          >
            {this.sideList()}
            <ThemeToggle />
          </SwipeableDrawer>
        </AppBar>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  })
};

export default withStyles(styles)(withRouter(Header));
