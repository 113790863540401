import React, { Component } from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
// Import the apollo client
import { client } from "../../store/configureClient";
import * as maker from "./maker";

/******************************************************************************************
 ***  I've put this in a component for ease...
 ***  But the query and the client func could be pulled out and added to a vanilla JS file
 *** then it's just a case of importing and using as the func on the click event
 *******************************************************************************************/

// GQL query to pass in
const ALL_GROUPS_QUERY = gql`
  query Group($id: String) {
    Group(_id: $id, first: 1) {
      title
      _id
      episodeCount
      operationCount
      pathwayTypes
      scoreTypes {
        key
        value
      }
      episodes {
        _id
        externalId
        startDate
        type
        patient {
          key
          gender
          dob
        }
        operations {
          keyWords
          count
          operationItems {
            side
            title
            hospital
            additionalProcedures
            repeat
            primaryOrRevision
            date
          }
        }
        diagnoses {
          sides
          primaryLeft
          primaryRight
          primaryBilateral
          diagnosisItems {
            isMain
            side
            diagnosis
            condition
            location
            degree
            ordinal
            status
            date
          }
        }
        plans {
          sides
          leftPlans
          rightPlans
          planItems {
            date
            side
            plan
            consultantIntervention
          }
        }
        complications {
          complicationItems {
            description
            title
            date
          }
        }
        visits {
          followUps
          newConsultations
          visitItems {
            date
            type
            side
          }
        }
        scores {
          scoreSet {
            scoreType
            scores {
              completedDate
              period
              side
              dimensions {
                dimension
                value
              }
            }
          }
        }
      }
    }
  }
`;

class ExcelMaker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  makeExcel = e => {
    e.preventDefault();
    client
      .query({
        query: ALL_GROUPS_QUERY,
        variables: { id: this.props.groupId }
      })
      .then(({ data }) => {
        maker.excel(data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        <p>
          Click <span onClick={e => this.makeExcel(e)}>here</span> to download
          to excel
        </p>
      </div>
    );
  }
}

ExcelMaker.propTypes = {
  groupId: PropTypes.string.isRequired
};

export default ExcelMaker;
