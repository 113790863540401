import React from "react";
import PropTypes from "prop-types";

const SubmitButton = props => {
  return (
    <input
      type="submit"
      value={props.title !== undefined ? props.title : "submit"}
      className={
        props.buttonActive ? "submit-button active" : "submit-button disabled"
      }
    />
  );
};

SubmitButton.propTypes = {
  title: PropTypes.string,
  class: PropTypes.string,
  buttonActive: PropTypes.bool
};

export default SubmitButton;
