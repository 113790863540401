import React, { Component } from "react";
import PropTypes from "prop-types";
import FormContainer from "../../components/Forms/FormContainer";
import { validateNewGroupMakerName } from "../../lib/validation";

class GroupsMaker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          domEl: "input",
          name: "newGroupMakerName",
          type: "text",
          placeholder: "",
          label: "New Group Name"
        }
      ]
    };
  }

  // componentDidMount() {
  //   this.setNewGroupValues(this.props.tempGroup);
  // }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.tempGroup !== this.props.tempGroup) {
  //     this.setNewGroupValues(this.props.tempGroup);
  //   }
  // }

  // // Restructures externalIds array in to objs fro submission
  // setNewGroupValues = tempGroup => {
  //   let newGroup = tempGroup.map(x => ({ externalId: x }));
  //   this.setState({ newGroup: newGroup });
  // };

  render() {
    return (
      <div className="groups-maker">
        <div className="groups-maker-header">
          <h3 className="groups-maker-header-title">Create new group</h3>
          <p className="group-maker-sub-heading">
            {this.props.groupSubheading}
          </p>
        </div>
        <div className="groups-maker-content">
          <div className="groups-maker-details">
            <p>you have {this.props.tempGroup.length} episodes selected</p>
            <hr/>
            <p><span onClick={e => this.props.clearTempGroup()}>CLEAR</span></p>
          </div>
          <FormContainer
            fields={this.state.fields} // form fields
            formName={"newGroupMakerName"} //form name
            formClass={"new-group-maker-name-form"} // form class used for styling
            validation={validateNewGroupMakerName} //TODO change to proper validation
            withButton={true}
            submissionFunc={this.props.createGroup}
            clearState={true}
          />
        </div>
      </div>
    );
  }
}

GroupsMaker.propTypes = {
  tempGroup: PropTypes.array.isRequired,
  createGroup: PropTypes.func.isRequired,
  clearTempGroup: PropTypes.func.isRequired,
  groupSubheading: PropTypes.string
};

export default GroupsMaker;
