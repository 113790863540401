import validator from "validator";

// TODO set up proper validation checks
// TODO fix password validation to regex query
function validateLogin(self, name, value) {
  // const passwordValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!|£@#\$%\^\&*\)\(+=._-]{8,}$/;
  switch (name) {
    case "email":
      self.setState({
        validations: {
          ...self.state.validations,
          emailIsValid: validator.isEmail(value)
        }
      });
      break;
    case "password":
      self.setState({
        validations: {
          ...self.state.validations,
          // passwordIsValid: passwordValid.test(value)
          passwordIsValid: validator.isLength(value, { min: 5, max: 30 })
        }
      });
      break;
    default:
      return;
  }
}

function validatePassword(self, name, value) {
  // const passwordValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!|£@#\$%\^\&*\)\(+=._-]{8,}$/;
  switch (name) {
    case "password":
      self.setState({
        validations: {
          ...self.state.validations,
          // passwordIsValid: passwordValid.test(value)
          passwordIsValid: validator.isLength(value, { min: 5, max: 30 })
        }
      });
      break;
    default:
      return;
  }
}

function validateUpdatedPassword(self, name, value) {
  // const passwordValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!|£@#\$%\^\&*\)\(+=._-]{8,}$/;
  switch (name) {
    case "newPassword":
      self.setState({
        validations: {
          ...self.state.validations,
          // newPasswordIsValid: newPasswordValid.test(value)
          newPasswordIsValid: validator.isLength(value, { min: 5, max: 30 })
        }
      });
      break;
    case "newPasswordConfirm":
      self.setState({
        validations: {
          ...self.state.validations,
          // newPasswordConfirmIsValid: newPasswordConfirmValid.test(value)
          newPasswordConfirmIsValid:
            value === self.state.submission.newPassword ? true : false
        }
      });
      break;
    default:
      return;
  }
}

function registerStepOneValidation(self, name, value) {
  // const passwordValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!|£@#\$%\^\&*\)\(+=._-]{8,}$/;
  switch (name) {
    case "email":
      self.setState({
        validations: {
          ...self.state.validations,
          emailIsValid: validator.isEmail(value)
        }
      });
      break;
    case "username":
      self.setState({
        validations: {
          ...self.state.validations,
          usernameIsValid: validator.isLength(value, { min: 2, max: 30 })
        }
      });
      break;
    case "password":
      self.setState({
        validations: {
          ...self.state.validations,
          // passwordIsValid: passwordValid.test(value)
          passwordIsValid: validator.isLength(value, { min: 5, max: 30 })
        }
      });
      break;
    case "passwordCheck":
      self.setState({
        validations: {
          ...self.state.validations,
          passwordCheckIsValid:
            value === self.state.submission.password ? true : false
        }
      });
      break;
    case "specialCheck":
      self.setState({
        validations: {
          ...self.state.validations,
          specialCheckIsValid:
            self.state.submission.specialCheck.length !== 0 ? true : null
        }
      });
      break;
    case "radioExample":
      self.setState({
        validations: {
          ...self.state.validations,
          radioExampleIsValid:
            self.state.submission.radioExample.length !== "" ? true : null
        }
      });
      break;
    case "dropdownExample":
      self.setState({
        validations: {
          ...self.state.validations,
          dropdownExampleIsValid:
            self.state.submission.dropdownExample.length !== "" ? true : null
        }
      });
      break;
    default:
      return;
  }
}

function registerStepTwoValidation(self, name, value) {
  // const passwordValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!|£@#\$%\^\&*\)\(+=._-]{8,}$/;
  switch (name) {
    case "title":
      self.setState({
        validations: {
          ...self.state.validations,
          titleIsValid: validator.isLength(value, { min: 2, max: 30 })
        }
      });
      break;
    case "forename":
      self.setState({
        validations: {
          ...self.state.validations,
          forenameIsValid: validator.isLength(value, { min: 2, max: 30 })
        }
      });
      break;
    case "surname":
      self.setState({
        validations: {
          ...self.state.validations,
          surnameIsValid: validator.isLength(value, { min: 2, max: 30 })
        }
      });
      break;
    case "suffix":
      self.setState({
        validations: {
          ...self.state.validations,
          suffixIsValid: validator.isLength(value, { min: 2, max: 30 })
        }
      });
      break;
    case "mobile":
      self.setState({
        validations: {
          ...self.state.validations,
          mobileIsValid: validator.isLength(value, { min: 2, max: 30 })
        }
      });
      break;

    default:
      return;
  }
}

function registerStepThreeValidation(self, name, value) {
  // const passwordValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!|£@#\$%\^\&*\)\(+=._-]{8,}$/;
  switch (name) {
    case "organisation":
      self.setState({
        validations: {
          ...self.state.validations,
          organisationIsValid: validator.isLength(value, { min: 2, max: 30 })
        }
      });
      break;
    case "specialism":
      self.setState({
        validations: {
          ...self.state.validations,
          specialismIsValid: validator.isLength(value, { min: 2, max: 30 })
        }
      });
      break;
    case "colleagues":
      self.setState({
        validations: {
          ...self.state.validations,
          colleaguesIsValid: validator.isLength(value, { min: 2, max: 30 })
        }
      });
      break;
    case "externalId":
      self.setState({
        validations: {
          ...self.state.validations,
          externalIdIsValid: validator.isLength(value, { min: 2, max: 30 })
        }
      });
      break;
    case "roles":
      self.setState({
        validations: {
          ...self.state.validations,
          rolesIsValid: self.state.submission.roles.length !== "" ? true : null
        }
      });
      break;
    case "isActive":
      self.setState({
        validations: {
          ...self.state.validations,
          isActiveIsValid:
            self.state.submission.isActive.length !== "" ? true : null
        }
      });
      break;

    default:
      return;
  }
}

function validateNewGroupMakerName(self, name, value) {
  switch (name) {
    case "newGroupMakerName":
      self.setState({
        validations: {
          ...self.state.validations,
          newGroupMakerNameIsValid: validator.isLength(value, {
            min: 2,
            max: 30
          })
        }
      });
      break;
    default:
      return;
  }
}

export {
  validateLogin,
  registerStepOneValidation,
  registerStepTwoValidation,
  registerStepThreeValidation,
  validateNewGroupMakerName,
  validatePassword,
  validateUpdatedPassword
};
