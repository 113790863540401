import React, { Component } from "react";
import PropTypes from "prop-types";
import Complication from "./complication";
import Operation from "./operation";
import Visit from "./visit";
import Diagnosis from "./diagnosis";
import Plan from "./plan";
import dateFns from "date-fns";

class TimelineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  selectItem = object => {
    switch (object.__typename) {
      case "Plan":
        return <Plan data={object} />;
      case "Diagnosis":
        return <Diagnosis data={object} />;
      case "Operation":
        return <Operation data={object} />;
      case "Complication":
        return <Complication data={object} />;
      case "Visit":
        return <Visit data={object} />;
      default:
        return <div>Unknown timeline item type</div>;
    }
  };

  render() {
    return (
      <div
        className={`timeline-item timeline-item-${this.props.data.__typename.toLowerCase()}`}
      >
        <div className="timeline-date-holder">
          <div className="timeline-date-lollipop">
            <div className="timeline-date-lollipop-inner">
              <span className="date-text day">
                {dateFns.format(this.props.data.date, "MMM")}
                <span className="date-text month">
                  {dateFns.format(this.props.data.date, "Do")}
                </span>
              </span>

              <span className="date-text year">
                {dateFns.format(this.props.data.date, "YYYY")}
              </span>
            </div>
          </div>
          <div className="timeline-spacer-holder">
            <span className="spacer" />
          </div>
        </div>
        <div className="timeline-item-content">
          {this.selectItem(this.props.data)}
        </div>
      </div>
    );
  }
}

TimelineItem.propTypes = {
  data: PropTypes.object.isRequired
};

export default TimelineItem;
