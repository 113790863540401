import React, { Component } from "react";
import PropTypes from "prop-types";
// import AnimatedNumber from "react-animated-number";
import ExcelMaker from "../../containers/ExcelMaker/ExcelMaker";
import DoughnutKpi from "./DoughnutKpi";
import { withRouter } from "react-router";

class GroupInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bigValue: 0
    };
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.episodeCount !== this.props.episodeCount) {
      this.update();
    }
  }

  navigateToEpisodes = () => {
    this.props.history.push(`/dashboard/patients`)
  }

  update = () => {
    setTimeout(() => {
      this.setState({
        bigValue: this.props.episodeCount
      });
    }, 1000);
  };

  render() {
    return (
      <div className="grid-box general">
        {/* <h4>Group</h4> */}
        <div className="title">
          <h2>{this.props.episodeTitle}</h2>
        </div>
        <div className="separator-line-muted" />
        <div onClick={this.navigateToEpisodes}>
          <DoughnutKpi
            value={this.props.episodeCount}
            compareValue={0}
            label="Episodes"
            colour="rgba(30, 220, 198, 1)"
          />
        </div>
        <DoughnutKpi
          value={this.props.operationCount}
          compareValue={0}
          label=" with Operations"
          colour="rgba(30, 220, 198, 1)"
        />
        <DoughnutKpi
          value={this.props.complicationCount}
          compareValue={0}
          label="with Complications"
          colour="rgba(30, 220, 198, 1)"
        />
        <div className="separator-line-muted" />
        <div className="download">
          <ExcelMaker groupId={this.props.groupId} />
        </div>
        <div className="separator-line-muted" />
        <div className="date">
          <p>
            Created: <span>{this.props.episodeCreatedAt}</span>
          </p>
        </div>
      </div>
    );
  }
}

GroupInfo.propTypes = {
  episodeTitle: PropTypes.string,
  episodeCreatedAt: PropTypes.string,
  episodeCount: PropTypes.number,
  operationCount: PropTypes.number,
  complicationCount: PropTypes.number,
  groupId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

export default withRouter(GroupInfo);
