import React, { Component } from "react";
import PropTypes from "prop-types";
import LineChart from "../Charts/LineChart";
import BarChart from "../Charts/BarChart";
import { bgColors } from "../Charts/ChartColorsSettings";
import ChartsRadioOption from "../Forms/components/ChartsRadioOption";

class EpisodeChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableResults: [],
      data: [],
      chartData: [],
      scoreTypes: [],
      chartWidth: "1600px",
      chartHeight: "800px",
      periods: [],
      initialSelected: "",
      periodReference: [
        { ref: "0PREOP", label: "Pre-Op", isActive: false, index: 0 },
        { ref: "0REG", label: "Registration", isActive: true, index: 1 },
        { ref: "5PREOP", label: "5 Weeks Pre-Op", isActive: false, index: 2 },
        { ref: "5REG", label: "5 Weeks Pre-Reg", isActive: false, index: 3 },
        { ref: "3MTHS", label: "3 Months", isActive: true, index: 4 },
        { ref: "6MTHS", label: "6 Months", isActive: true, index: 5 },
        { ref: "1YRS", label: "1 Year", isActive: true, index: 6 },
        { ref: "2YRS", label: "2 Years", isActive: true, index: 7 },
        { ref: "5YRS", label: "5 Years", isActive: false, index: 8 }
      ],
      barOptions: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: ""
        },
        legend: {
          labels: {
            fontColor: "white"
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "white"
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "white"
              }
            },
            {
              stacked: true
            }
          ]
        }
      },
      lineOptions: {
        maintainAspectRatio: false,
        title: {
          display: true,
          text: ""
        },
        legend: {
          labels: {
            fontColor: "white"
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "white"
              }
            }
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "white"
              }
            }
          ]
        }
      }
    };
  }

  componentDidMount() {
    this.setState(
      { data: this.props.individualEpisodeData },
      this.getChartData
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.individualEpisodeData !== this.props.individualEpisodeData) {
      this.setState(
        { data: this.props.individualEpisodeData },
        this.getChartData
      );
    }
  }

  getChartData = () => {
    let firstItemLength = this.state.data[0].scores.scoreSet[0].scores.length;

    let result = [];
    this.state.data[0].scores.scoreSet.map(x => {
      let periods = x.scores.map(x => x.period);
      let dimensions = Array.from(
        new Set(
          [].concat(...x.scores.map(x => x.dimensions)).map(e => e.dimension)
        )
      );
      let unOrderedPeriods = [];
      for (let p of periods) {
        let referenceItem = this.state.periodReference.filter(x => x.ref === p);
        if (referenceItem[0]) {
          unOrderedPeriods.push(referenceItem[0]);
        }
      }

      let orderedPeriods = unOrderedPeriods.sort((a, b) => a.index - b.index);

      let dims = x.scores.map(x =>
        x.dimensions.map(e =>
          Object.assign(
            {},
            { dimension: e.dimension, value: e.value },
            { period: x.period }
          )
        )
      );
      let dimensionValues = [].concat(...dims);

      result[`${x.scoreType}`] = [];
      for (let d of dimensions) {
        let obj = { data: [], label: d, periodLabels: [] };
        for (let p of orderedPeriods) {
          let candidate = dimensionValues.filter(
            x => x.dimension === d && x.period === p.ref
          );
          candidate[0] ? obj.data.push(candidate[0].value) : obj.data.push(0);
          obj.periodLabels.push(p.label);
        }
        result[`${x.scoreType}`].push(obj);
      }
    });

    // Get the scoreTypes to make the selectable buttons
    let scoreTypes = [];
    Object.keys(result).map(s => {
      scoreTypes.push(s);
    });

    // Get FIRST value of the result
    let firstResult = [];
    Object.keys(result).map(x => {
      firstResult.push(x);
    });

    // Get ALL the values
    let resultValues = [];
    Object.values(result).map(x => {
      resultValues.push(x);
    });

    // Decide what chart type to display on MOUNT
    if (firstItemLength > 1) {
      this.setState({
        availableResults: resultValues, // add all results
        chartData: result[firstResult[0]], // populate with first result
        scoreTypes: scoreTypes,
        periods: result[firstResult[0]][0].periodLabels,
        selected: scoreTypes[0],
        showLineChart: true // show line chart
      });
    } else {
      this.setState({
        availableResults: resultValues,
        chartData: result[firstResult[0]],
        scoreTypes: scoreTypes,
        periods: result[firstResult[0]][0].periodLabels,
        selected: scoreTypes[0],
        showLineChart: false //show bar chart
      });
    }
  };

  // changing the score types
  getScores = x => {
    let firstItemLength = this.state.data[0].scores.scoreSet[0].scores.length;

    if (firstItemLength > 1) {
      this.setState({
        chartData: this.state.availableResults[x],
        periods: this.state.availableResults[x][0].periodLabels,
        selected: this.state.scoreTypes[x],
        showLineChart: true
      });
    } else {
      this.setState({
        chartData: this.state.availableResults[x],
        periods: this.state.availableResults[x][0].periodLabels,
        selected: this.state.scoreTypes[x],
        showLineChart: false //show bar chart
      });
    }
  };

  render() {
    return (
      <div className="episodes-chart">
        <div className="score-types-list chart-page">
          <div className="score-types-items">
            {this.state.scoreTypes.map((x, i) => (
              <ChartsRadioOption
                key={x + i}
                name="radioSelector"
                value={x}
                isSelected={this.state.selected === this.state.scoreTypes[i]}
                onChangeFunc={() => this.getScores(i)}
              />
            ))}
          </div>
        </div>
        {this.state.showLineChart ? (
          <LineChart
            dataSet={this.state.chartData}
            labels={this.state.periods}
            type={"line"}
            refName={"episodeLineChart"}
            defaultStyles={true}
            options={this.state.lineOptions}
            width={this.state.clientWidth}
            height={700}
          />
        ) : (
          <BarChart
            dataSet={[
              ...this.state.chartData.map((x, i) => {
                return { ...x, backgroundColor: bgColors[i] };
              })
            ]}
            labels={this.state.periods}
            type={"bar"}
            refName={"episodeBarChart"}
            defaultStyles={false}
            options={this.state.barOptions}
            width={this.state.clientWidth}
            height={700}
          />
        )}
      </div>
    );
  }
}

EpisodeChart.propTypes = {
  individualEpisodeData: PropTypes.array.isRequired
};

export default EpisodeChart;
